<template>
  <Form v-slot="{ meta }" ref="validator">
    <div
      class="mx-auto text-center px-4 my-6"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
      <div v-if="addressInstruction">
        <component
          :is="childBlok.component"
          v-for="childBlok in addressInstruction.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="text-gray-400"
        />
      </div>
    </div>
    <div
      class="r-fade--delay--1 max-w-sm mx-auto px-4"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <address-search-molecule
        :address-apartment-disclaimer="addressApartmentDisclaimer"
        allow-hide-house-number
        :external-address="addressModel"
        immediate
        :override-failed="validationExtraAttention"
        required
        :source="source"
      />
      <div class="h-14 mt-4 mb-4">
        <div
          class="flex justify-center items-center"
          :class="{ 'r-fade': initialLoad }"
        >
          <r-button
            :id="submitButtonId"
            color="orange"
            :disabled="!meta.valid"
            :is-loading="requestInProgress"
            @click="submit"
          >
            <r-icon class="mr-1 text-lg" icon-name="check-circle" />
            <span class="font-medium">{{ nextButtonLabel }}</span>
          </r-button>
          <div class="flex items-center text-sm mx-2">
            <RichText v-if="goToNextStepCta" :blok="goToNextStepCta" />
            <r-icon class="ml-1" icon-name="arrow-turn-down-left" />
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { workflow } from '~/utils/constants';
import AddressSearchMolecule from '~/components/snippets/AddressSearchMolecule.vue';
import { Form } from 'vee-validate';

export default {
  name: 'StepSelectAddress',
  components: { Form, AddressSearchMolecule },
  props: {
    introText: {
      type: Array,
      required: true,
    },
    addressInstruction: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: false,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    addressApartmentDisclaimer: {
      type: String,
      required: true,
    },
    submitButtonId: {
      type: String,
      required: true,
    },
    nextButtonId: {
      type: String,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    previousButtonId: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
    /**
     * Used only for tracking events.
     */
    source: {
      type: String,
      required: true,
    },
    isAgreementAddressSearch: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['goToNextStep', 'update:modelValue'],
  async setup() {
    const { setupKeyListener } = useKeyboardListener();

    return {
      setupKeyListener,
    };
  },

  data() {
    return {
      showManualAddressContent: false,
      validationExtraAttention: false,
      workflow: workflow,
    };
  },
  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
    }),
    addressModel: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        if (newVal) {
          const finalValue = { ...this.modelValue, ...newVal };
          this.$emit('update:modelValue', finalValue);
        }
      },
    },
    addressSearchRules() {
      const houseNumberRequired = this.getCountry === 'pl';

      return {
        isValidAddressInput: true,
        isValidAddressInputStreet: true,
        isValidAddressInputCity: true,
        isValidAddressInputCountry: true,
        isValidAddressInputIndex: true,
        isValidAddressInputHouseNumber: houseNumberRequired,
      };
    },
  },
  mounted() {
    this.setupKeyListener('Enter', this.submit);
  },
  methods: {
    ...mapActions({
      trackManualAddressAdded: 'tracker/trackManualAddressAdded',
    }),
    async submit() {
      const validationResult = await this.validate();
      if (validationResult && !this.requestInProgress) {
        if (this.addressModel.internalUserEditedAddressManually) {
          await this.trackManualAddressAdded({
            addressCity: this.addressModel.addressCity,
            addressStreet: this.addressModel.addressStreet,
            addressPostalCode: this.addressModel.addressIndex,
            addressHouseNumber: this.addressModel.addressHouseNumber,
            addressCountry: this.addressModel.addressCountry,
            addressHouseNumberHidden: this.addressModel.addressHouseNumberHidden,
            addressApartmentNumber: this.addressModel.addressApartmentNumber,
            addressFirstLine: this.addressModel.addressFirstLine,
            source: this.source,
          });
        }
        this.$emit('goToNextStep');
      } else {
        this.validationExtraAttention = true;
      }
    },
    async validate() {
      const validationResult = await this.$refs.validator.validate();
      return validationResult.valid;
    },
  },
};
</script>
