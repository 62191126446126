<template>
  <section v-editable="blok" class="bg-gray-50 pb-20 flex-grow relative">
    <!--        Loader-->
    <template
      v-if="getAdvertisementsInProgress && savedAdvertisementsData.length === 0"
    >
      <div class="min-h-1/2-screen flex justify-center items-center text-center">
        <r-loader type="animatedLogo" />
      </div>
    </template>

    <!--        Failed -->
    <template v-else-if="getAdvertisementsFailed">
      <div class="min-h-1/2-screen flex justify-center text-error">
        {{ $t('errors.something_wrong') }}
      </div>
    </template>

    <!--                Listing               -->
    <template v-else-if="getAdvertisementsResponse">
      <div v-if="getAdvertisementsInProgress" class="relative z-10">
        <r-loader class="fixed w-full" type="animatedBar" />
      </div>

      <!--  Background -->
      <r-banner :images="blok.headerImage" />
      <div class="relative px-4 py-10 md:py-20">
        <!-- Header -->
        <div
          v-if="blok.headerTitle"
          class="mx-auto max-w-3xl w-full bg-white bg-opacity-75 p-4 mb-8 rounded-lg text-center"
        >
          <h1 v-if="blok.headerTitle" class="text-4xl md:text-5xl font-bold">
            {{ blok.headerTitle }}
          </h1>
          <RichText
            v-if="blok.headerContent"
            :blok="blok.headerContent"
            class="mt-4 md:text-lg font-light -mb-4"
          />
        </div>

        <!--       Advertisements  list-->
        <div v-if="savedAdvertisementsData.length > 0" class="max-w-5xl m-auto">
          <div class="bg-white rounded-lg shadow-lg p-2 mb-2">
            <div class="flex justify-between">
              <r-button
                :color="searchAndFilterOpen ? 'orange' : 'black'"
                icon="filter-list"
                icon-class="px-3"
                :label="$t('sort_filter.sort_and_filter')"
                label-class="font-normal hidden sm:block"
                no-background
                no-padding
                @click="toggleSearchAndFilter()"
              />
              <component
                :is="theBlok.component"
                v-for="theBlok in blok.addNewButton.filter((item) =>
                  ['ButtonBlok'].includes(item.component),
                )"
                :key="theBlok._uid"
                :blok="theBlok"
              />
            </div>

            <div v-if="searchAndFilterOpen" class="sm:flex border-t pt-1 mt-1">
              <div class="w-full">
                <r-input
                  v-model="advertisementSearchString"
                  icon="search"
                  name="search"
                  no-border
                  :placeholder="$t('sort_filter.search_by_address')"
                />
              </div>

              <div class="w-full sm:max-w-xs sm:pl-8">
                <r-select
                  v-model="selectedSorting"
                  hide-keyboard
                  name="advertisementSorting"
                  no-border
                  option-label="text"
                  :options="selectedSortingOptions"
                  @change="
                    trackSortAndFilterEvent({
                      eventName: searchAndFilterTrackingEventNames.SELECT_FILTER,
                      processName: trackingProcessNames.ADVERTISEMENT,
                      sorting: selectedSorting.trackingValue,
                    })
                  "
                />
              </div>
            </div>
          </div>

          <div
            v-for="advertisement in filteredAdvertisementsForSearch(
              savedAdvertisementsData,
            )"
            :key="advertisement.id"
            class="my-4"
          >
            <!-- getAdvertisementsResponse returns deleted timestamp only for admin users -->
            <span v-if="advertisement.deleted">
              Advertisement deleted - {{ advertisement.deleted }} (Deleted
              advertisements visible only for admin accounts)
            </span>
            <r-property-item
              additional-button-icon="trash"
              :additional-button-text="$t('buttons.remove_object')"
              additional-button2-icon="copy"
              :additional-button2-text="$t('buttons.duplicate')"
              :address="parseFullAddressFromAddressParameters(advertisement, true)"
              :image="advertisement.images[0]"
              :invitation-url="
                linkToInvite(advertisement.invitationShortCode, advertisement.workflow)
              "
              :invitation-url-copied-label="$t('buttons.link_copied')"
              :invitation-url-copy-label="
                advertisement.status === advertisementStatuses.ACTIVE
                  ? $t('buttons.share')
                  : ''
              "
              :link-button-inverted="
                advertisement.status === advertisementStatuses.DRAFT
              "
              :link-button-inverted-black="
                advertisementIsDisabled(advertisement?.statusAllowed)
              "
              :link-text="
                advertisement.status === advertisementStatuses.DRAFT
                  ? $t('buttons.change_advertisement')
                  : $t('buttons.view')
              "
              :link-url="
                $localizedPath(blok.advertismentObjectViewPath.cached_url) +
                '/' +
                advertisement.id
              "
              :list="propertyListData(advertisement)"
              :status="
                advertisementIsDisabled(advertisement?.statusAllowed)
                  ? 'DISABLED'
                  : advertisement.status
              "
              :view-button="true"
              @additional-button2-click="openDuplicateModal(advertisement.id)"
              @additional-button-click="
                openDeleteModal(advertisement.id, advertisement.initialSource)
              "
              @copy-link-button-click="
                openSharingModal(
                  'Agreements list',
                  linkToInvite(
                    advertisement.invitationShortCode,
                    advertisement.workflow,
                  ),
                  parseFullAddressFromAddressParameters(advertisement),
                )
              "
            />
          </div>
        </div>

        <!--        no advertisements -->
        <div
          v-else-if="savedAdvertisementsData.length === 0"
          class="bg-white rounded-lg shadow-lg text-center mx-auto max-w-3xl p-4 md:px-8 text-center"
        >
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.noAdvertisementsContent.filter((item) =>
              ['RichTextBlok', 'ButtonBlok'].includes(item.component),
            )"
            :key="theBlok._uid"
            :blok="theBlok"
            class="r-fade my-6 md:my-12 text-lg font-light"
          />

          <div class="bg-gray-50 rounded-lg px-4 py-1 max-w-xl mx-auto">
            <RichText
              v-if="blok.noAdvertisementsSupport"
              :blok="blok.noAdvertisementsSupport"
              class="md:text-lg font-light"
            />
          </div>
        </div>
      </div>
    </template>
    <!--        Delete ad modal-->
    <r-overlay
      size="small"
      :visible="showDeleteObjectOverlay"
      @close="[(showDeleteObjectOverlay = false), (advertisementDeleted = false)]"
    >
      <div
        v-if="advertisementDeleted"
        class="p-6 pb-16 md:px-10 flex flex-col justify-center items-center"
      >
        <r-icon class="text-5xl text-rendin-500 my-6" icon-name="trash" />
        <div v-if="blok.objectRemoved" class="md:text-lg font-bold">
          {{ blok.objectRemoved }}
        </div>
      </div>
      <div v-else class="p-6 md:px-10 flex flex-col justify-center items-center">
        <div v-if="blok.removeObjectViewTittle" class="pt-6 md:text-lg font-bold">
          {{ blok.removeObjectViewTittle }}
        </div>
        <div v-if="blok.removeObjectViewText" class="pb-6">
          <RichText :blok="blok.removeObjectViewText" class="md:text-lg text-center" />
        </div>
        <div class="w-full grid gap-3 sm:hidden mt-12">
          <r-button
            v-if="!putAdvertisementInProgress"
            border-color="light-gray"
            color="black"
            inverted
            :label="$t('buttons.cancel')"
            size="small"
            width="full"
            @click="showDeleteObjectOverlay = false"
          />
          <r-button
            icon="xmark"
            :is-loading="putAdvertisementInProgress"
            :label="blok.confirmButtonText"
            size="small"
            width="full"
            @click="deleteAdvertisement(advertisementToDelete)"
          />
        </div>
        <div
          class="hidden sm:grid justify-center items-center"
          :class="{ 'grid-cols-2': !putAdvertisementInProgress }"
        >
          <r-button
            v-if="!putAdvertisementInProgress"
            display="block"
            :label="$t('buttons.cancel')"
            no-background
            @click="showDeleteObjectOverlay = false"
          />
          <r-button
            class="block"
            :is-loading="putAdvertisementInProgress"
            :label="blok.confirmButtonText"
            @click="deleteAdvertisement(advertisementToDelete)"
          />
        </div>
      </div>
    </r-overlay>

    <!--        Duplicate ad modal-->
    <r-overlay
      :prevent-close="postAdvertisementCreateCopyInProgress"
      size="small"
      :visible="showDuplicateObjectOverlay"
      @close="showDuplicateObjectOverlay = false"
    >
      <div class="p-6 md:px-10 flex flex-col justify-center items-center">
        <div v-if="blok.duplicateModalTitle" class="pt-6 md:text-lg font-bold">
          {{ blok.duplicateModalTitle }}
        </div>
        <div v-if="blok.duplicateModalText" class="pb-6">
          <RichText :blok="blok.duplicateModalText" class="md:text-lg text-center" />
        </div>
        <div class="w-full grid gap-3 sm:hidden mt-12">
          <r-button
            v-if="!postAdvertisementCreateCopyInProgress"
            border-color="light-gray"
            color="black"
            inverted
            :label="$t('buttons.cancel')"
            size="small"
            width="full"
            @click="showDuplicateObjectOverlay = false"
          />
          <r-button
            :is-loading="postAdvertisementCreateCopyInProgress"
            :label="$t('buttons.duplicate')"
            size="small"
            width="full"
            @click="duplicateAdvertisement(advertisementToDuplicate)"
          />
        </div>

        <div
          class="hidden sm:grid justify-center items-center"
          :class="{ 'grid-cols-2': !postAdvertisementCreateCopyInProgress }"
        >
          <r-button
            v-if="!postAdvertisementCreateCopyInProgress"
            display="block"
            :label="$t('buttons.cancel')"
            no-background
            @click="showDuplicateObjectOverlay = false"
          />
          <r-button
            class="block"
            :is-loading="postAdvertisementCreateCopyInProgress"
            :label="$t('buttons.duplicate')"
            @click="duplicateAdvertisement(advertisementToDuplicate)"
          />
        </div>
      </div>
    </r-overlay>

    <!-- Sharing modal for Invite -->
    <r-sharing-modal
      :description="shareModalAddress"
      :modal-button-text="$t('web_sharing_modal.copy_link')"
      :modal-description="$t('web_sharing_modal.or_share_in_social_media')"
      :modal-title="$t('web_sharing_modal.share_this_link')"
      :show-modal="sharingModalOpen"
      :title="shareModalAddress"
      :url="shareModalLink"
      @close-overlay="sharingModalOpen = false"
      @copy-link-click="
        trackInviteLinkCopyEvent({
          objectType: trackingProcessNames.ADVERTISEMENT,
          openingLocation: sharingModalLocation,
          channel: 'Link',
        })
      "
      @custom-web-sharing-modal-open="
        trackInviteLinkOpenSharingEvent({
          objectType: trackingProcessNames.ADVERTISEMENT,
          openingLocation: sharingModalLocation,
          modalType: sharingModalTypes.DESKTOP_SHARE_MODAL,
        })
      "
      @native-web-sharing-modal-open="
        trackInviteLinkOpenSharingEvent({
          objectType: trackingProcessNames.ADVERTISEMENT,
          openingLocation: sharingModalLocation,
          modalType: sharingModalTypes.MOBILE_NATIVE_SHARE,
        })
      "
      @social-network-popup-open="
        (network) =>
          trackInviteLinkCopyEvent({
            objectType: trackingProcessNames.ADVERTISEMENT,
            openingLocation: sharingModalLocation,
            channel: network,
          })
      "
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  AdvertisementSourceType,
  webSourceType,
  sharingModalTypes,
  DigibrokerSuccessModalType,
  advertisementStatuses,
  sortingOptions,
} from '~/utils/constants';
import {
  trackingProcessNames,
  sortingOptionsForTracking,
  searchAndFilterTrackingEventNames,
} from '~/utils/trackerConstants';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector';

export default {
  name: 'ProfileAdvertisement',
  scrollToTop: true,
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      parseFullAddressFromAddressParameters,
    };
  },
  data: (vm) => {
    return {
      rendingFee: 2.5,
      savedAdvertisementsData: [],
      showDeleteObjectOverlay: false,
      showDuplicateObjectOverlay: false,
      advertisementToDelete: null,
      advertisementToDeleteSource: null,
      advertisementDeleted: false,
      advertisementToDuplicate: null,
      sharingModalOpen: false,
      sharingModalLocation: null,
      shareModalAddress: '',
      shareModalLink: '',
      trackingProcessNames: trackingProcessNames,
      sharingModalTypes: sharingModalTypes,
      advertisementStatuses,
      advertisementSearchString: null,
      searchAndFilterOpen: false,
      selectedSorting: {
        text: vm.$t('sort_filter.newest_first'),
        value: sortingOptions.NEWEST_FIRST,
        trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
      },
      selectedSortingOptions: [
        {
          text: vm.$t('sort_filter.newest'),
          value: sortingOptions.NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.active'),
          value: sortingOptions.ACTIVE_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.ACTIVE_NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.draft'),
          value: sortingOptions.DRAFT_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.DRAFT_NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.not_active'),
          value: sortingOptions.NOT_ACTIVE_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.NOT_ACTIVE_NEWEST_FIRST,
        },
        {
          text: vm.$t('sort_filter.rented_out'),
          value: sortingOptions.DISABLED_NEWEST_FIRST,
          trackingValue: sortingOptionsForTracking.DISABLED_NEWEST_FIRST,
        },
      ],
      searchAndFilterTrackingEventNames,
    };
  },

  computed: {
    ...mapGetters({
      getAdvertisementsResponse: 'advertisements/getAdvertisementsResponse',
      getAdvertisementsInProgress: 'advertisements/getAdvertisementsInProgress',
      getAdvertisementsFailed: 'advertisements/getAdvertisementsFailed',
      putAdvertisementInProgress: 'advertisements/putAdvertisementInProgress',
      postAdvertisementCreateCopyInProgress:
        'advertisements/postAdvertisementCreateCopyInProgress',
      hasSessionUser: 'users/hasSessionUser',
      getLocale: 'getLocale',
      getCountry: 'getCountry',
      isENVIsProduction: 'isENVIsProduction',
    }),
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to login page and open SignInModal
      this.$router.push({ path: this.$localizedPath('/?login=open') });
    }
  },
  mounted() {
    if (!this.getAdvertisementsInProgress) this.getAdvertisements();

    if (this.getAdvertisementsResponse?.length) {
      this.savedAdvertisementsData = this.getAdvertisementsResponse;
    }

    if (this.$router.currentRoute?.query?.success) {
      this.actionSetDigibrokerSuccessModal({
        visible: true,
        parent: webSourceType.WEB_DIGIBROKER,
        type: DigibrokerSuccessModalType.LISTING,
      });

      // Remove success param from url
      const query = Object.assign({}, this.$route.query);
      delete query.success;
      this.$router.replace({ query });
    }
  },
  methods: {
    ...mapActions({
      actionGetAdvertisements: 'advertisements/getAdvertisements',
      actionDeleteAdvertisement: 'advertisements/deleteAdvertisement',
      actionSetDigibrokerSuccessModal: 'adds/setDigibrokerSuccessModal',
      actionPostAdvertisementCreateCopy: 'advertisements/postAdvertisementCreateCopy',
      trackInviteLinkOpenSharingEvent: 'tracker/trackInviteLinkOpenSharingEvent',
      trackInviteLinkCopyEvent: 'tracker/trackInviteLinkCopyEvent',
      trackSortAndFilterEvent: 'tracker/trackSortAndFilterEvent',
    }),

    filteredAdvertisementsForSearch(advertisement) {
      let filteredAgreements = advertisement;
      if (this.advertisementSearchString?.length >= 2) {
        filteredAgreements = advertisement.filter((obj) => {
          return (
            obj.addressFirstLine
              ?.toLowerCase()
              .includes(this.advertisementSearchString.toLowerCase()) ||
            obj.addressCity
              ?.toLowerCase()
              .includes(this.advertisementSearchString.toLowerCase()) ||
            obj.addressStreet
              ?.toLowerCase()
              .includes(this.advertisementSearchString.toLowerCase()) ||
            obj.addressHouseNumber
              ?.toLowerCase()
              .includes(this.advertisementSearchString.toLowerCase())
          );
        });

        this.trackSortAndFilterEvent({
          eventName: searchAndFilterTrackingEventNames.SEARCH,
          processName: trackingProcessNames.ADVERTISEMENT,
          searchString: this.advertisementSearchString,
        });
      }
      if (this.selectedSorting?.value) {
        const sortedAgreements = cloneDeep(filteredAgreements);

        if (this.selectedSorting.value === sortingOptions.NEWEST_FIRST) {
          return sortedAgreements.sort(
            (a, b) => new Date(b.created) - new Date(a.created),
          );
        } else if (this.selectedSorting.value === sortingOptions.OLDEST_FIRST) {
          return sortedAgreements.sort(
            (a, b) => new Date(a.created) - new Date(b.created),
          );
        } else if (this.selectedSorting.value === sortingOptions.ACTIVE_NEWEST_FIRST) {
          const activeSortedAgreements = sortedAgreements.filter(
            (ad) =>
              ad.status === advertisementStatuses.ACTIVE &&
              !this.advertisementIsDisabled(ad.statusAllowed),
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.created) - new Date(a.created),
          );
        } else if (this.selectedSorting.value === sortingOptions.DRAFT_NEWEST_FIRST) {
          const activeSortedAgreements = sortedAgreements.filter(
            (ad) => ad.status === advertisementStatuses.DRAFT,
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.created) - new Date(a.created),
          );
        } else if (
          this.selectedSorting.value === sortingOptions.NOT_ACTIVE_NEWEST_FIRST
        ) {
          const activeSortedAgreements = sortedAgreements.filter(
            (ad) =>
              ad.status === advertisementStatuses.NOT_ACTIVE &&
              !this.advertisementIsDisabled(ad.statusAllowed),
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.created) - new Date(a.created),
          );
        } else if (
          this.selectedSorting.value === sortingOptions.DISABLED_NEWEST_FIRST
        ) {
          const activeSortedAgreements = sortedAgreements.filter((ad) =>
            this.advertisementIsDisabled(ad.statusAllowed),
          );
          return activeSortedAgreements.sort(
            (a, b) => new Date(b.created) - new Date(a.created),
          );
        }
      }

      return filteredAgreements;
    },

    toggleSearchAndFilter() {
      this.searchAndFilterOpen = !this.searchAndFilterOpen;

      if (this.searchAndFilterOpen) {
        this.trackSortAndFilterEvent({
          eventName: searchAndFilterTrackingEventNames.OPEN_SORT_AND_FILTER,
          processName: trackingProcessNames.ADVERTISEMENT,
        });
      } else {
        this.trackSortAndFilterEvent({
          eventName: searchAndFilterTrackingEventNames.CLOSE_SORT_AND_FILTER,
          processName: trackingProcessNames.ADVERTISEMENT,
        });
      }
    },

    openDeleteModal(id, initialSource) {
      this.advertisementToDelete = id;
      this.advertisementToDeleteSource = initialSource;
      this.showDeleteObjectOverlay = true;
    },

    openDuplicateModal(advertisementId) {
      this.showDuplicateObjectOverlay = true;
      this.advertisementToDuplicate = advertisementId;
    },

    duplicateAdvertisement(advertisementId) {
      this.actionPostAdvertisementCreateCopy({
        id: advertisementId,
        source: AdvertisementSourceType.WEB_COPY,
      })
        .then((response) => {
          if (response.response) {
            this.$router.push({
              path: this.$localizedPath(`/dashboard/object/${response.response}`),
            });
          }
        })
        .finally(() => {
          this.advertisementToDuplicate = null;
        });
    },

    deleteAdvertisement(advertisementId) {
      // remove deleted advertisement from lists & silently update advertisements later
      this.savedAdvertisementsData = this.savedAdvertisementsData.filter(
        (advertisement) => advertisement.id !== advertisementId,
      );

      this.actionDeleteAdvertisement({
        id: advertisementId,
        deleteStatus: true,
        source: AdvertisementSourceType.WEB_ADVERTISEMENT_EDIT,
      })
        .then(() => {
          this.advertisementToDelete = null;
          this.advertisementDeleted = true;
        })
        .then(() => this.getAdvertisements());
    },

    propertyListData(property) {
      const data = [
        {
          label: `${this.$t('offer.price')}`,
          value: property.rentAmount
            ? property.rentAmount.toLocaleString(this.getLocale)
            : null,
          unit: property.currency ? property.currency : null,
        },

        {
          label: `${this.$t('offer.rooms')}`,
          value: property.numberOfRooms
            ? property.numberOfRooms.toLocaleString(this.getLocale)
            : null,
        },
        {
          label: `${this.$t('offer.size')}`,
          value: property.objectArea
            ? property.objectArea.toLocaleString(this.getLocale)
            : null,
          unit: `m<sup>2</sup>`,
        },
        {
          label: `${this.$t('offer.floor')}`,
          value: this.structuredFloors(property.floor, property.floorsTotal),
        },
        {
          label: `${this.$t('offer.damage_protection')}`,
          value: this.calculateDamageProtection(
            property.rentAmount,
            property.workflow,
          ).toLocaleString(this.getLocale),
          unit: property.currency ? property.currency : null,
        },
        {
          label: `${this.$t('offer.listing_source_label')}`,
          value: this.showInitialSource(property.initialSource),
        },
      ];

      return data;
    },

    calculateDamageProtection(rent, country) {
      if (country === 'PL' && rent) {
        return rent * 10;
      } else if (country === 'EE' && rent) {
        return rent * 10;
      }
      return '';
    },

    structuredFloors(floor, floorsTotal) {
      if (typeof floor === 'number') {
        if (typeof floorsTotal === 'number') {
          return `${floor}/${floorsTotal}`;
        }
        return floor;
      }
      return '-';
    },

    showInitialSource(src) {
      if (src === 'KV24') {
        return `${this.$t('offer.listing_source_kv24')}`;
      } else {
        return `${this.$t('offer.listing_source_digibroker')}`;
      }
    },

    getAdvertisements() {
      this.actionGetAdvertisements().then((result) => {
        this.savedAdvertisementsData = result.response;
      });
    },

    advertisementIsDisabled(statusAllowed) {
      // Disabled advertisement is when advertisement.statusAllowed  is empty [] or [ “NOT_ACTIVE” ]
      if (Array.isArray(statusAllowed)) {
        return (
          !statusAllowed?.length ||
          (statusAllowed.length === 1 &&
            statusAllowed[0] === advertisementStatuses.NOT_ACTIVE)
        );
      } else {
        return false;
      }
    },

    linkToInvite(invitationShortCode, workflow) {
      if (this.isENVIsProduction) {
        if (workflow === 'EE') {
          return `https://rendin.ee${this.$localizedPath(`/invite/form/${invitationShortCode}`)}`;
        } else {
          return `https://rendin.pl${this.$localizedPath(`/invite/form/${invitationShortCode}`)}`;
        }
      } else {
        return (
          window.location.origin +
          this.$localizedPath(`/invite/form/${invitationShortCode}`)
        );
      }
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    openSharingModal(location, link, address) {
      this.sharingModalLocation = location;
      this.sharingModalOpen = true;
      this.shareModalAddress = address;
      this.shareModalLink = link;
    },
  },
};
</script>

<style>
.text_content p {
  @apply my-4;
}

.text_content a {
  @apply text-rendin-500 hover:text-rendin-600 hover:underline focus:text-rendin-600 focus:underline;
}
</style>
