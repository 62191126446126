<template>
  <section
    v-editable="blok"
    class="profile-agreement bg-gray-50 print:bg-transparent relative flex-grow flex flex-col"
    :class="[isHandoverPreview || isAnnexView ? 'min-h-screen' : 'pb-20']"
  >
    <client-only>
      <!--    Loading-->
      <template v-if="currentView === profileAgreementViews.LOADING">
        <r-loader
          class="min-h-1/2-screen flex justify-center items-center"
          :phrases="getLoaderArrayString"
          type="animatedLogo"
        />
      </template>

      <!--  Error, getAgreementFailed -->
      <template v-else-if="currentView === profileAgreementViews.ERROR">
        <div
          class="bg-white rounded-lg shadow-lg text-center mx-auto max-w-3xl p-4 md:px-8 my-44"
        >
          <img
            alt="error"
            class="mx-auto w-52"
            src="/illustrations/rendin-characters/empty-table-woman-standing.png"
          />

          <h3 class="md:text-lg font-medium my-4">
            {{ blok.errorTitle }}
          </h3>
          <RichText
            v-if="blok.errorText"
            :blok="blok.errorText"
            class="mb-6 md:text-lg font-light"
          />
        </div>
      </template>

      <template v-else>
        <!--  Background -->
        <RBanner
          v-if="currentViewMode === viewMode.IS_VIEW_MODE"
          :images="blok.headerImage"
        />
        <div
          class="relative flex-grow flex flex-col w-full"
          :class="[
            currentViewMode === viewMode.IS_VIEW_MODE
              ? 'md:py-20 mx-auto max-w-5xl px-4 py-10'
              : '',
          ]"
        >
          <!--  Agreement back button & title  -->
          <template v-if="currentViewMode === viewMode.IS_VIEW_MODE">
            <div>
              <r-button
                border-color="light-gray"
                class="mb-6"
                color="black"
                hover="hover:text-rendin-500 hover:shadow-md"
                icon="arrow-left-long"
                icon-class="pr-3"
                inverted
                :label="$t('buttons.back')"
                :to="
                  isTenantView
                    ? $localizedPath('/dashboard/tenant-agreements')
                    : $localizedPath('/dashboard/landlord-agreements')
                "
              />
            </div>
          </template>

          <!--  Agreement tenant view or landlord/creator active view -->
          <template
            v-if="
              currentView === profileAgreementViews.IS_TENANT_VIEW ||
              currentView === profileAgreementViews.IS_LANDLORD_OR_CREATOR_SIGNED_VIEW
            "
          >
            <AgreementTenantDraftView v-if="isTenantViewInDraft" />

            <AgreementActiveView
              v-else
              :agreement-annexes="savedAnnexData"
              :agreement-from-firebase="
                savedAgreementData[0] ? savedAgreementData[0] : null
              "
              :agreement-handover="savedAgreementHandoverData"
              :is-agreement-type-pl="isAgreementTypePl"
              :is-tenant-view="isTenantView"
              @click-open-annex="openAnnex"
              @open-agreement-preview-click="isAgreementPreview = true"
              @open-handover-act-click="
                openHandoverView(handoverViews.HANDOVER_PROTOCOL_VIEW)
              "
            />
          </template>

          <!--  Edit view  -->
          <template
            v-else-if="
              currentView === profileAgreementViews.IS_LANDLORD_OR_CREATOR_EDIT_VIEW
            "
          >
            <AgreementEditView
              :agreement-from-firebase="
                savedAgreementData[0] ? savedAgreementData[0] : null
              "
              :agreement-handover="savedAgreementHandoverData"
              :agreement-id="agreementId"
              :agreement-services-and-utilities-from-firebase="
                savedAgreementServicesAndUtilities
              "
              :is-tenant-view="isTenantView"
              @open-handover-edit="openHandoverView(handoverViews.HANDOVER_EDIT_VIEW)"
              @open-handover-protocol="
                openHandoverView(handoverViews.HANDOVER_PROTOCOL_VIEW)
              "
            />
          </template>

          <!--  Preview -->
          <template v-else-if="currentView === profileAgreementViews.IS_AGREEMENT_VIEW">
            <div class="relative pt-20">
              <div
                class="fixed bg-white max-w-1920 mx-auto shadow-lg w-full top-0 z-10 print:hidden"
              >
                <div class="px-4 py-2 mx-auto max-w-5xl">
                  <r-button
                    border-color="light-gray"
                    color="black"
                    hover="hover:text-rendin-500 hover:shadow-md"
                    icon="arrow-left-long"
                    icon-class="pr-3"
                    inverted
                    :label="$t('buttons.back_to_agreement')"
                    @click="isAgreementPreview = false"
                  />
                </div>
              </div>

              <div class="p-4 mx-auto max-w-5xl">
                <AgreementPreview
                  :agreement-from-firebase="
                    savedAgreementData[0] ? savedAgreementData[0] : null
                  "
                  :agreement-id="agreementId"
                  :agreement-services-and-utilities-from-firebase="
                    savedAgreementServicesAndUtilities
                  "
                />
              </div>
            </div>
          </template>

          <!--  Handover -->
          <template v-else-if="currentView === profileAgreementViews.IS_HANDOVER_VIEW">
            <div class="relative pt-20 print:pt-4 flex-grow flex">
              <div
                class="fixed bg-white w-full top-0 right-0 z-10 max-w-1920 mx-auto left-0 print:hidden"
              >
                <div class="px-4 py-2 mx-auto max-w-5xl flex w-full justify-between">
                  <r-button
                    border-color="light-gray"
                    color="black"
                    hover="hover:text-rendin-500 hover:shadow-md"
                    icon="arrow-left-long"
                    icon-class="pr-3"
                    inverted
                    :label="handoverViewBackLabels"
                    @click="handoverViewBackActions"
                  />
                  <r-button
                    class="md:hidden"
                    color="black"
                    icon="circle-question"
                    icon-class="text-xl"
                    no-background
                    width="small"
                    @click="openHandoverProtocolInfoModal()"
                  />

                  <r-button
                    border-color="light-gray"
                    class="hidden md:block"
                    color="black"
                    hover="hover:text-rendin-500 hover:shadow-md"
                    icon="circle-question"
                    icon-class="pr-3"
                    inverted
                    :label="$t('agreement.handover_v2.protocol_info_modal.button')"
                    @click="openHandoverProtocolInfoModal()"
                  />
                </div>

                <div v-if="getHandoverQueueLocked" class="relative z-10">
                  <r-loader class="fixed w-full" type="animatedBar" />
                </div>
              </div>

              <HandoverStatusView
                v-if="showHandoverStatusView"
                :agreement-handover="savedAgreementHandoverData"
                :app-handover-link="appHandoverLink"
                class="px-4 mx-auto max-w-5xl pb-20"
                :is-tenant-view="isTenantView"
                @open-handover-edit="openHandoverView(handoverViews.HANDOVER_EDIT_VIEW)"
                @open-handover-protocol="
                  openHandoverView(handoverViews.HANDOVER_PROTOCOL_VIEW)
                "
              />

              <HandoverEditView
                v-if="showHandoverEditView"
                :agreement-from-firebase="
                  savedAgreementData[0] ? savedAgreementData[0] : null
                "
                :agreement-handover="savedAgreementHandoverData"
                :agreement-id="agreementId"
                :is-tenant-view="isTenantView"
                :move-to-step-index="moveToHandoverEditViewStep"
                @active-handover-edit-view-step="setHandoverEditActiveStep"
                @change-handover-status="setHandoverStatusReadyToSend()"
                @close-handover-edit="openHandoverView()"
                @open-handover-protocol="openHandoverProtocolFocused"
                @open-handover-tenant-notes-info-modal="
                  openHandoverTenantNotesInfoModal()
                "
              />

              <HandoverProtocolView
                v-if="showHandoverProtocolView"
                :agreement-from-firebase="
                  savedAgreementData[0] ? savedAgreementData[0] : null
                "
                :agreement-handover="savedAgreementHandoverData"
                :agreement-id="agreementId"
                class="pb-20"
                :hide-action-buttons="focusedProtocolView"
                :is-tenant-view="isTenantView"
                @open-handover-edit-view-click="
                  openHandoverView(handoverViews.HANDOVER_EDIT_VIEW)
                "
                @open-tenant-confirmation-modal="openTenantHandoverConformationModal()"
                @open-tenant-feedback-view-click="
                  openHandoverView(handoverViews.HANDOVER_FEEDBACK_VIEW)
                "
                @sent-to-tenant-click="openLandlordHandoverConformationModal()"
              />

              <handover-tenant-feedback-view
                v-if="showHandoverFeedbackView"
                :agreement-from-firebase="
                  savedAgreementData[0] ? savedAgreementData[0] : null
                "
                :agreement-handover="savedAgreementHandoverData"
                :agreement-id="agreementId"
                class="pb-20"
                :is-tenant-view="isTenantView"
                @save-click="openHandoverView(handoverViews.HANDOVER_PROTOCOL_VIEW)"
              />
            </div>
          </template>

          <!--  Annex-->
          <template v-else-if="currentView === profileAgreementViews.IS_ANNEX_VIEW">
            <div class="relative pt-20 print:pt-4 flex-grow flex">
              <div
                class="fixed bg-white w-full top-0 right-0 z-10 max-w-1920 mx-auto left-0 print:hidden"
              >
                <div class="px-4 py-2 mx-auto max-w-5xl flex w-full justify-between">
                  <r-button
                    border-color="light-gray"
                    color="black"
                    hover="hover:text-rendin-500 hover:shadow-md"
                    icon="arrow-left-long"
                    icon-class="pr-3"
                    inverted
                    :label="$t('buttons.back_to_agreement')"
                    @click="isAnnexView = false"
                  />
                </div>

                <div v-if="getAnnexQueueLocked" class="relative z-10">
                  <r-loader class="fixed w-full" type="animatedBar" />
                </div>
              </div>

              <AgreementAnnexView
                :agreement-from-firebase="
                  savedAgreementData[0] ? savedAgreementData[0] : null
                "
                :annex="editAnnexData"
                @close-annex-view="isAnnexView = false"
              />
            </div>
          </template>

          <LandlordHandoverConfirmationModal
            :agreement-from-firebase="
              savedAgreementData[0] ? savedAgreementData[0] : null
            "
            :agreement-handover="savedAgreementHandoverData"
            :agreement-id="agreementId"
            :show-modal="showLandlordHandoverConformationModal"
            @close-overlay="showLandlordHandoverConformationModal = false"
            @open-handover-edit="openHandoverView(handoverViews.HANDOVER_EDIT_VIEW)"
            @open-handover-status-view="
              openHandoverView(handoverViews.HANDOVER_STATUS_VIEW)
            "
            @open-handover-tenant-notes-info-modal="openHandoverTenantNotesInfoModal()"
          />

          <TenantHandoverConfirmationModal
            :agreement-from-firebase="
              savedAgreementData[0] ? savedAgreementData[0] : null
            "
            :agreement-handover="savedAgreementHandoverData"
            :agreement-id="agreementId"
            :show-modal="showTenantHandoverConformationModal"
            @close-overlay="showTenantHandoverConformationModal = false"
            @handover-status-change-finished="openHandoverView()"
          />

          <HandoverProtocolInfoModal
            :is-tenant-view="isTenantView"
            :show-modal="showHandoverProtocolInfoModal"
            @close-overlay="showHandoverProtocolInfoModal = false"
          />

          <HandoverTenantNotesInfoModal
            :show-modal="showHandoverTenantNotesInfoModal"
            @close-overlay="showHandoverTenantNotesInfoModal = false"
          />
        </div>
      </template>
    </client-only>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';
import { constructDateFromIsoString } from '~/utils/dateFromIsoStringConstructor';
import AgreementEditView from '~/components/section/agreement-components/AgreementEditView';
import AgreementPreview from '~/components/section/agreement-components/AgreementPreview';
import {
  oldHandoverStatuses,
  handoverStatuses,
  agreementSignedStatuses,
  handoverCompletedStatuses,
  handoverEditViewActiveSteps,
  agreementPLTypes,
} from '~/utils/constants';

import HandoverStatusView from '~/components/section/handover/HandoverStatusView';
import HandoverProtocolView from '~/components/section/handover/HandoverProtocolView';
import TenantHandoverConfirmationModal from '~/components/section/handover/TenantHandoverConfirmationModal';
import HandoverTenantFeedbackView from '~/components/section/handover/HandoverTenantFeedbackView';
import HandoverEditView from '~/components/section/handover/HandoverEditView';
import HandoverProtocolInfoModal from '~/components/section/handover/HandoverProtocolInfoModal';
import HandoverTenantNotesInfoModal from '~/components/section/handover/HandoverTenantNotesInfoModal';
import LandlordHandoverConfirmationModal from '~/components/section/handover/LandlordHandoverConfirmationModal';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { defineComponent } from 'vue';
import AgreementTenantDraftView from '~/components/section/agreement-components/AgreementTenantDraftView.vue';
import AgreementActiveView from '~/components/section/agreement-components/AgreementActiveView.vue';
import AgreementAnnexView from '~/components/section/agreement-components/AgreementAnnexView';

/**
 * Agreement Status options `NEW`, `DRAFT`, `READY_TO_SIGN`, `PRE_SIGNING`, `SIGNED`, `ACTIVE`, `FINISHED`, `ARCHIVED`
 */

const profileAgreementViews = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  IS_TENANT_VIEW: 'IS_TENANT_VIEW',
  IS_LANDLORD_OR_CREATOR_SIGNED_VIEW: 'IS_LANDLORD_OR_CREATOR_SIGNED_VIEW',
  IS_LANDLORD_OR_CREATOR_EDIT_VIEW: 'IS_LANDLORD_OR_CREATOR_EDIT_VIEW',
  IS_AGREEMENT_VIEW: 'IS_AGREEMENT_VIEW',
  IS_HANDOVER_VIEW: 'IS_HANDOVER_VIEW',
  IS_ANNEX_VIEW: 'IS_ANNEX_VIEW',
};

/**
 * IS_VIEW_MODE is with main navigation, image background and address bar, for IS_TENANT_VIEW, IS_LANDLORD_OR_CREATOR_SIGNED_VIEW
 * IS_FOCUS_MODE is without main navigation, gray background, for IS_LANDLORD_OR_CREATOR_EDIT_VIEW, IS_AGREEMENT_VIEW, IS_HANDOVER_VIEW
 */
const viewMode = {
  IS_FOCUS_MODE: 'IS_FOCUS_MODE',
  IS_VIEW_MODE: 'IS_VIEW_MODE',
};

const handoverViews = {
  HANDOVER_STATUS_VIEW: 'HANDOVER_STATUS_VIEW',
  HANDOVER_PROTOCOL_VIEW: 'HANDOVER_PROTOCOL_VIEW',
  HANDOVER_EDIT_VIEW: 'HANDOVER_EDIT_VIEW',
  HANDOVER_FEEDBACK_VIEW: 'HANDOVER_FEEDBACK_VIEW',
};

export default defineComponent({
  name: 'ProfileAgreement',
  components: {
    HandoverEditView,
    HandoverProtocolView,
    AgreementActiveView,
    AgreementTenantDraftView,
    HandoverTenantFeedbackView,
    AgreementEditView,
    AgreementPreview,
    HandoverStatusView,
    LandlordHandoverConfirmationModal,
    TenantHandoverConfirmationModal,
    HandoverProtocolInfoModal,
    HandoverTenantNotesInfoModal,
    AgreementAnnexView,
  },

  scrollToTop: true,

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      constructPhoneNr,
      constructDateFromIsoString,
      parseFullAddressFromAddressParameters,
    };
  },

  data() {
    return {
      // Component level saved getAgreementResponse data to avoid flickering
      savedAgreementData: [],
      savedAgreementServicesAndUtilities: [],
      savedAgreementHandoverData: null,
      savedAnnexData: null,
      editAnnexData: null,
      agreementId: null,
      isAgreementPreview: false,
      isHandoverPreview: false,
      showHandoverStatusView: false,
      showHandoverProtocolView: false,
      showHandoverFeedbackView: false,
      showHandoverEditView: false,
      showTenantHandoverConformationModal: false,
      showLandlordHandoverConformationModal: false,
      showHandoverProtocolInfoModal: false,
      showHandoverTenantNotesInfoModal: false,
      handoverStatuses,
      oldHandoverStatuses,
      profileAgreementViews,
      viewMode,
      handoverViews,
      focusedProtocolView: false,
      moveToHandoverEditViewStep: null,
      handoverEditViewActiveSteps,
      handoverEditViewActiveStep: null,
      isAnnexView: false,
    };
  },

  computed: {
    ...mapGetters({
      getAgreementResponse: 'agreements/getAgreementResponse',
      getAgreementInProgress: 'agreements/getAgreementInProgress',
      getAgreementFailed: 'agreements/getAgreementFailed',
      putAgreementInProgress: 'agreements/putAgreementInProgress',
      getAgreementServicesAndUtilitiesResponse:
        'agreements/getAgreementServicesAndUtilitiesResponse',
      getHandoverLatestResponse: 'handover/getHandoverLatestResponse',
      getHandoverQueueLocked: 'handover/getHandoverQueueLocked',

      getAnnexLatestResponse: 'annex/getAnnexLatestResponse',
      getAnnexQueueLocked: 'annex/getAnnexQueueLocked',

      profileId: 'users/profileId',
      profile: 'users/profile',
      hasSessionUser: 'users/hasSessionUser',

      getLoaderArrayString: 'storyBlokGlobals/getLoaderArrayString',
      getGlobalAppButtonLinksAndImages:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImages',
    }),

    currentView() {
      if (this.getAgreementInProgress && this.savedAgreementData.length === 0) {
        return profileAgreementViews.LOADING;
      } else if (!this.getAgreementInProgress && this.getAgreementFailed) {
        return profileAgreementViews.ERROR;
      } else if (this.isAgreementPreview) {
        return profileAgreementViews.IS_AGREEMENT_VIEW;
      } else if (this.isHandoverPreview) {
        return profileAgreementViews.IS_HANDOVER_VIEW;
      } else if (this.isAnnexView) {
        return profileAgreementViews.IS_ANNEX_VIEW;
      } else if (this.isTenantView) {
        return profileAgreementViews.IS_TENANT_VIEW;
      } else if (this.isLandlordOrCreatorSigned) {
        return profileAgreementViews.IS_LANDLORD_OR_CREATOR_SIGNED_VIEW;
      } else if (this.isLandlordOrCreatorView && this.isEditMode) {
        return profileAgreementViews.IS_LANDLORD_OR_CREATOR_EDIT_VIEW;
      }

      return null;
    },

    currentViewMode() {
      if (
        this.currentView === profileAgreementViews.IS_TENANT_VIEW ||
        this.currentView === profileAgreementViews.IS_LANDLORD_OR_CREATOR_SIGNED_VIEW
      ) {
        return viewMode.IS_VIEW_MODE;
      } else if (
        this.currentView === profileAgreementViews.IS_LANDLORD_OR_CREATOR_EDIT_VIEW ||
        this.currentView === profileAgreementViews.IS_AGREEMENT_VIEW ||
        this.currentView === profileAgreementViews.IS_HANDOVER_VIEW ||
        this.currentView === profileAgreementViews.IS_ANNEX_VIEW
      ) {
        return viewMode.IS_FOCUS_MODE;
      }
      return null;
    },

    isTenantView() {
      if (
        this.agreementId &&
        this.savedAgreementData &&
        this.savedAgreementData[0] &&
        this.profileId
      ) {
        if (
          this.savedAgreementData[0].tenantProfileId === this.profileId &&
          this.savedAgreementData[0].creatorProfileId !== this.profileId &&
          this.savedAgreementData[0].landlordProfileId !== this.profileId
        ) {
          return true;
        }
      }
      return false;
    },

    isTenantViewInDraft() {
      return this.isTenantView && this.savedAgreementData?.[0].status === 'DRAFT';
    },

    isLandlordOrCreatorView() {
      if (this.agreementId && this.savedAgreementData?.[0] && this.profileId) {
        if (
          this.savedAgreementData?.[0].creatorProfileId === this.profileId ||
          this.savedAgreementData?.[0].landlordProfileId === this.profileId
        ) {
          // profile is agreement creator or landlord
          return true;
        }
      } else if (
        !this.agreementId &&
        !this.getAgreementFailed &&
        !this.getAgreementInProgress
      ) {
        // new agreement
        return true;
      }
      return false;
    },

    isEditMode() {
      if (!this.agreementId) {
        // new agreement
        return true;
      } else if (this.agreementId && !this.isLandlordOrCreatorSigned) {
        // agreement is DRAFT and profile is creator or landlord
        return true;
      }
      return false;
    },

    isAgreementSigned() {
      return agreementSignedStatuses.includes(this.savedAgreementData?.[0]?.status);
    },

    handoverStatus() {
      let status;
      // old handover structure
      if (this.savedAgreementData?.[0]?.handover?.status) {
        status = this.savedAgreementData[0].handover?.status;
        // new handover structure
      } else if (this.savedAgreementHandoverData?.handover?.status) {
        status = this.savedAgreementHandoverData?.handover?.status;
      } else {
        status = null;
      }

      return status;
    },

    // Handover landlord/creator part is done
    isHandoverCompleted() {
      return handoverCompletedStatuses.includes(this.handoverStatus);
    },

    isLandlordOrCreatorSigned() {
      return !!(
        this.savedAgreementData?.[0] &&
        !this.getAgreementInProgress &&
        this.isLandlordOrCreatorView &&
        this.isAgreementSigned &&
        this.isHandoverCompleted
      );
    },

    appHandoverLink() {
      const appLink = `handover?agreementId=${this.agreementId}`;
      const encodedRedirect = encodeURIComponent(appLink);

      return `/nstbc/login?mode=alta&redirect=${encodedRedirect}`;
    },

    handoverViewBackLabels() {
      if (this.showHandoverFeedbackView || this.focusedProtocolView) {
        return this.$t('buttons.back');
      } else if (this.showHandoverEditView) {
        return this.$t('buttons.save_and_close');
      }
      return this.$t('buttons.back_to_agreement');
    },

    isAgreementTypePl() {
      return Object.values(agreementPLTypes).includes(
        this.savedAgreementData?.[0]?.agreementType,
      );
    },
  },

  watch: {
    hasSessionUser(value) {
      if (value) this.getAgreement();
    },

    getAgreementResponse: {
      deep: true,
      handler() {
        this.savedAgreementData = this.getAgreementResponse;
      },
    },
    getAgreementServicesAndUtilitiesResponse: {
      deep: true,
      handler() {
        this.savedAgreementServicesAndUtilities =
          this.getAgreementServicesAndUtilitiesResponse?.data?.servicesAndUtilities?.asArray;
      },
    },

    getHandoverLatestResponse: {
      handler() {
        this.savedAgreementHandoverData = this.getHandoverLatestResponse?.data;
      },
    },

    getAnnexLatestResponse: {
      handler() {
        this.savedAnnexData = this.getAnnexLatestResponse?.data;
      },
    },

    currentViewMode(value) {
      if (value === viewMode.IS_FOCUS_MODE) {
        this.$store.dispatch('hideNav', true);
        this.scrollPageUp();
      } else {
        this.$store.dispatch('hideNav', false);
        this.scrollPageUp();
      }
    },
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to home page and open SignInModal
      this.setOverridenSuccessfulRedirect(
        this.$localizedPath(this.$router.currentRoute.path),
      );
      this.$router.push({ path: this.$localizedPath('/?login=open') });
    }

    if (this.$route.params.agreementid) {
      this.agreementId = this.$route.params.agreementid;
      this.getAgreement().then(() => {
        this.getAnnexes();
      });

      this.getHandover();
    }

    this.actionSetLoaderArrayStringFromStoryblok();
  },

  mounted() {
    this.actionGetGlobalAppButtonLinksAndImagesFromStoryblok();

    if (this.currentViewMode === viewMode.IS_FOCUS_MODE) {
      this.$store.dispatch('hideNav', true);
      this.scrollPageUp();
    }
  },

  beforeUnmount() {
    this.mutationClearGetAgreement();
    this.$store.dispatch('hideNav', false);
  },

  methods: {
    ...mapMutations({
      mutationClearGetAgreement: 'agreements/CLEAR_GET_AGREEMENT',
      setOverridenSuccessfulRedirect: 'session/SET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
    }),

    ...mapActions({
      actionGetAgreement: 'agreements/getAgreement',
      actionGetHandover: 'handover/getHandover',
      actionPutHandover: 'handover/putHandover',
      actionSetLoaderArrayStringFromStoryblok:
        'storyBlokGlobals/setLoaderArrayStringFromStoryblok',
      actionGetGlobalAppButtonLinksAndImagesFromStoryblok:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImagesFromStoryblok',
      actionSetDigibrokerSuccessModalInvite: 'adds/setDigibrokerSuccessModalInvite',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',

      actionGetAnnex: 'annex/getAnnex',
    }),

    getAgreement() {
      if (this.hasSessionUser) {
        return (
          this.actionGetAgreement({ id: this.agreementId })
            .then(() => {
              this.savedAgreementData = this.getAgreementResponse;
            })
            // Store listing link and address for DigibrokerSuccessModal
            .then(() => {
              // TODO review this
              // this.actionSetDigibrokerSuccessModalInvite({
              //   link: this.linkToInvite(
              //     this.savedAgreementData[0]?.invitationShortCodes[0],
              //     this.savedAgreementData[0].workflow,
              //   ),
              //   address: this.createAddress(this.savedAgreementData[0]),
              // });
            })
        );
      } else {
        return Promise.resolve();
      }
    },

    getHandover() {
      const includeData = {
        handover: true,
        feedback: true,
      };
      if (this.hasSessionUser)
        this.actionGetHandover({
          agreementId: this.agreementId,
          includeData: includeData,
        }).then((response) => {
          if (response?.response?.success)
            this.savedAgreementHandoverData = response.response.data;
        });
    },

    // Annexes used only for PL agreements
    getAnnexes() {
      if (this.hasSessionUser && this.isAgreementTypePl)
        this.actionGetAnnex({ agreementId: this.agreementId }).then((response) => {
          if (response?.response?.success) this.savedAnnexData = response.response.data;
        });
    },

    createAddress(agreement) {
      let fullAddress = '';

      if (agreement.addressStreet && agreement.addressHouseNumber) {
        fullAddress = `${agreement.addressStreet} ${agreement.addressHouseNumber}`;
      }

      if (
        agreement.addressFirstLine &&
        (!agreement.addressStreet || !agreement.addressHouseNumber)
      ) {
        fullAddress = agreement.addressFirstLine;
      }
      if (agreement.addressCity) {
        fullAddress = `${fullAddress}, ${agreement.addressCity}`;
      }
      if (agreement.addressCountry) {
        fullAddress = `${fullAddress}, ${agreement.addressCountry}`;
      }
      return fullAddress;
    },

    linkToInvite(invitationShortCode, workflow) {
      if (this.isENVIsProduction) {
        if (workflow === 'EE') {
          return `https://rendin.ee${this.$localizedPath(`/invite/form/${invitationShortCode}`)}`;
        } else {
          return `https://rendin.pl${this.$localizedPath(`/invite/form/${invitationShortCode}`)}`;
        }
      } else {
        return (
          window.location.origin +
          this.$localizedPath(`/invite/form/${invitationShortCode}`)
        );
      }
    },

    openHandoverView(activeView) {
      this.isHandoverPreview = handoverViews[activeView];
      this.showHandoverStatusView = activeView === handoverViews.HANDOVER_STATUS_VIEW;
      this.showHandoverProtocolView =
        activeView === handoverViews.HANDOVER_PROTOCOL_VIEW;
      this.showHandoverFeedbackView =
        activeView === handoverViews.HANDOVER_FEEDBACK_VIEW;
      this.showHandoverEditView = activeView === handoverViews.HANDOVER_EDIT_VIEW;

      this.scrollPageUp();
    },

    openHandoverTenantNotesInfoModal() {
      this.showHandoverTenantNotesInfoModal = true;
    },

    openLandlordHandoverConformationModal() {
      this.showLandlordHandoverConformationModal = true;
    },

    openTenantHandoverConformationModal() {
      this.showTenantHandoverConformationModal = true;
    },

    openHandoverProtocolInfoModal() {
      this.showHandoverProtocolInfoModal = true;
    },

    handoverViewBackActions() {
      this.moveToHandoverEditViewStep = null;

      if (this.showHandoverFeedbackView) {
        this.openHandoverView(handoverViews.HANDOVER_PROTOCOL_VIEW);
      } else if (this.focusedProtocolView) {
        this.focusedProtocolView = false;
        this.moveToHandoverEditViewStep = handoverEditViewActiveSteps.CLOSURE;
        this.openHandoverView(handoverViews.HANDOVER_EDIT_VIEW);
      } else if (
        this.savedAgreementHandoverData?.handover?.status === handoverStatuses.DRAFT &&
        this.handoverEditViewActiveStep === handoverEditViewActiveSteps.CLOSURE
      ) {
        this.setHandoverStatusReadyToSend();
      } else {
        this.openHandoverView();
      }
    },

    setHandoverStatusReadyToSend() {
      // If status is DRAFT > save and close will emit else close
      // Change status to  handoverStatuses.READY_TO_SEND
      const handoverEntityId = this.savedAgreementHandoverData?.handover?.entityId;
      const status = { status: handoverStatuses.READY_TO_SEND };
      this.actionPutHandover({ entityId: handoverEntityId, includedData: status })
        .then(() => {
          this.emitAgreementTrackerEvent(
            agreementTrackingEventNames.HANDOVER_READY_TO_SEND,
          );
        })
        .then(() => {
          this.actionGetHandover({ agreementId: this.agreementId });
        })
        .finally(() => {
          this.openHandoverView();
        });
    },

    emitAgreementTrackerEvent(event) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: trackingProcessNames.AGREEMENT,
        agreement: this.savedAgreementData[0],
      });
    },

    openHandoverProtocolFocused() {
      this.focusedProtocolView = true;
      this.openHandoverView(handoverViews.HANDOVER_PROTOCOL_VIEW);
    },

    setHandoverEditActiveStep(stepIndex) {
      this.handoverEditViewActiveStep = stepIndex;
    },

    openAnnex(annex) {
      this.editAnnexData = annex;
      this.isAnnexView = true;

      this.emitAgreementTrackerEvent(agreementTrackingEventNames.OPEN_ANNEX);
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
      });
    },
  },
});
</script>
