<template>
  <section
    :id="blok.id"
    v-editable="blok"
    class="testimonials-highlighted py-10 md:py-16"
    :class="
      blok.design === designType.card
        ? 'px-4'
        : 'text-white bg-gradient-to-r from-rendin-400 to-rendin-600 text-white'
    "
  >
    <div
      v-if="blok.design === designType.card"
      class="testimonials-highlighted--card max-w-5xl mx-auto px-4 sm:px-8 md:px-16 py-12 rounded-lg shadow-xl"
    >
      <RichText
        v-if="blok.title"
        :blok="blok.title"
        class="max-w-5xl mx-auto text-center text-2xl leading-tight mb-4"
      />
      <div class="flex gap-4 md:gap-6 justify-between items-center">
        <div>
          <r-icon
            class="text-3xl text-rendin-500 -mb-4 block"
            icon-name="quote-left"
            prefix="solid"
          />
          <RichText :blok="blok.testimonial" />
          <div class="flex justify-between items-center gap-4 mt-6">
            <div>
              <div class="font-bold">
                {{ blok.name }}
              </div>
              <div class="text-rendin-500">
                {{ blok.profession }}
              </div>
            </div>
            <img
              class="sm:hidden flex-shrink-0 rounded-full"
              height="90"
              :src="$transformImage(blok.image.filename, '90x90/smart')"
              width="90"
            />
          </div>
        </div>

        <div class="flex-shrink-0 hidden sm:block">
          <img
            class="rounded-full"
            height="224"
            :src="$transformImage(blok.image.filename, '224x224/smart')"
            width="224"
          />
        </div>
      </div>
    </div>
    <div v-else class="max-w-5xl mx-auto px-4">
      <div class="flex flex-wrap justify-center">
        <div class="md:w-1/3 lg:w-1/4 flex items-center">
          <img
            class="rounded-full mr-4"
            height="100"
            :src="$transformImage(blok.image.filename, '100x100/smart')"
            width="100"
          />
          <div>
            <div class="uppercase text-sm">
              {{ blok.name }}
            </div>
            <div class="text-sm">
              {{ blok.profession }}
            </div>
          </div>
        </div>
        <div class="flex w-full md:w-2/3 lg:w-3/4 md:pl-8">
          <div>
            <RichText :blok="blok.testimonial" class="italic" />
            <RichText
              :blok="blok.storyLink"
              class="testimonials-highlighted__link mt-4"
            />
          </div>
          <r-icon class="text-2xl p-4" icon-name="quote-right" prefix="solid" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const designType = {
  card: 'card',
  wideOrange: 'wideOrange',
};

export default {
  name: 'TestimonialsHighlighted',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    designType,
  }),
};
</script>

<style lang="scss">
.testimonials-highlighted {
  &__link a {
    color: white !important;
  }

  &--card {
    background-image: url('/images/testimonial-card-bg.png');
    background-repeat: no-repeat;
    background-position: right -20px bottom 15px;
    background-size: 110px;

    @media screen and (min-width: 640px) {
      background-position: right bottom -20px;
      background-size: auto 100%;
    }
  }
}
</style>
