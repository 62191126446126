<template>
  <section :id="blok.section_id" class="px-3 md:px-6">
    <div class="mx-auto max-w-5xl py-8 md:py-24 md:pb-20">
      <div class="flex flex-col items-center md:flex-row">
        <div class="w-full space-y-5 md:w-3/5 md:pr-16" v-html="content"></div>
        <div class="w-full mt-16 md:mt-0 md:w-3/4">
          <div
            class="relative h-auto p-8 py-10 overflow-hidden bg-white border-b-2 border-gray-300 rounded-lg shadow-2xl px-7"
          >
            <div v-if="showSuccess && blok.type === 'claim'">
              <h3 class="mb-8 text-2xl font-medium text-center">
                {{ blok.claim_success_title }}
              </h3>
              <p class="text-center text-lg font-medium">
                {{ blok.claim_success_title_details }}
              </p>
              <dl class="mt-4 max-w-sm mx-auto divide-y divide-gray-200">
                <div class="py-3 flex justify-between text-md font-medium">
                  <dt class="text-gray-600">
                    {{ $t('form.label.full_name') }}
                  </dt>
                  <dd class="text-gray-800">
                    {{ form.name }}
                  </dd>
                </div>

                <div class="py-3 flex justify-between text-md font-medium">
                  <dt class="text-gray-600">
                    {{ $t('form.label.email') }}
                  </dt>
                  <dd class="text-gray-800">
                    {{ form.email }}
                  </dd>
                </div>

                <div class="py-3 flex justify-between text-md font-medium">
                  <dt class="text-gray-600">
                    {{ $t('form.contact.placeholder.agreement_number') }}
                  </dt>
                  <dd class="text-gray-800">
                    {{ form.agreement_number }}
                  </dd>
                </div>

                <div class="py-3 flex justify-between text-md font-medium">
                  <dt class="text-gray-600">
                    {{ $t('form.contact.placeholder.claim_type') }}
                  </dt>
                  <dd class="text-gray-800 first-letter:capitalize">
                    {{ form.category }}
                  </dd>
                </div>

                <div class="py-3 flex justify-between text-md font-medium">
                  <dt class="text-gray-600">
                    {{ $t('form.contact.placeholder.estimated_amount') }}
                  </dt>
                  <dd class="text-gray-800">
                    {{ form.estimated_amount }}
                  </dd>
                </div>

                <!--                <div class="py-3 flex justify-between text-md font-medium">-->
                <!--                  <dt class="text-gray-600">-->
                <!--                    {{ $t('form.contact.placeholder.when_happened') }}-->
                <!--                  </dt>-->
                <!--                  <dd class="text-gray-800">-->
                <!--                    {{ $dayjs(form.when_happened).format('D MMMM, YYYY') }}-->
                <!--                  </dd>-->
                <!--                </div>-->
              </dl>

              <p class="text-center mt-5">
                {{ blok.claim_success_bottom_text }}
              </p>
              <p class="text-center mt-5">
                <a
                  class="text-rendin-500 hover:underline font-medium cursor-pointer text-base"
                  :href="blok.help_center_link"
                  target="_blank"
                >
                  {{ blok.visit_help_center }}
                </a>
              </p>
            </div>
            <Form
              v-else
              ref="contact"
              autocomplete="off"
              @submit.prevent="submitContact"
            >
              <h3 class="mb-6 text-2xl font-medium text-center">
                {{ blok.title }}
              </h3>
              <div class="block mb-4">
                <Field
                  v-slot="{ meta }"
                  v-model="form.name"
                  name="name"
                  rules="required|min:3"
                >
                  <input
                    id="name"
                    v-model="form.name"
                    autocomplete="false"
                    class="block w-full px-4 py-3 border border-gray-400 rounded-lg focus:border-rendin-500 focus:outline-none"
                    :class="{ 'focus:border-rendin-500': !meta.valid }"
                    name="name"
                    :placeholder="
                      blok.type === 'claim'
                        ? $t('form.placeholder.your_full_name')
                        : blok.name_placeholder
                    "
                    type="text"
                  />
                </Field>
              </div>
              <div class="block mb-4">
                <Field
                  v-slot="{ meta }"
                  v-model="form.email"
                  name="email"
                  rules="required|email"
                >
                  <input
                    id="email"
                    v-model="form.email"
                    autocomplete="false"
                    class="block w-full px-4 py-3 border border-gray-400 rounded-lg focus:border-rendin-500 focus:outline-none"
                    :class="{ 'focus:border-rendin-500': !meta.valid }"
                    name="email"
                    :placeholder="blok.email_placeholder"
                    type="email"
                  />
                </Field>
              </div>

              <div v-if="blok.type === 'claim'" class="">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3 mb-4">
                    <Field
                      v-slot="{ meta }"
                      v-model="form.agreement_number"
                      name="agreement_number"
                      rules="required|min:3"
                    >
                      <input
                        id="agreement_number"
                        v-model="form.agreement_number"
                        autocomplete="false"
                        class="block w-full px-4 py-3 border border-gray-400 rounded-lg focus:border-rendin-500 focus:outline-none"
                        :class="{ 'focus:border-rendin-500': !meta.valid }"
                        name="agreement_number"
                        :placeholder="$t('form.contact.placeholder.agreement_number')"
                        type="text"
                      />
                    </Field>
                  </div>

                  <div class="col-span-6 sm:col-span-3 mb-4">
                    <Field
                      v-slot="{ meta }"
                      v-model="form.category"
                      rules="required"
                      name="category"
                    >
                      <select
                        id="category"
                        v-model="form.category"
                        class="block w-full pr-10 px-4 border border-gray-400 rounded-lg focus:border-rendin-500 focus:outline-none"
                        :class="{ 'focus:border-rendin-500': !meta.valid }"
                        name="category"
                        style="height: 50px"
                      >
                        <option selected value="">
                          {{ $t('form.contact.placeholder.choose_claim_type') }}
                        </option>
                        <option value="late payments">
                          {{ $t('form.contact.claim_options.late_payment') }}
                        </option>
                        <option value="property damage">
                          {{ $t('form.contact.claim_options.property_damage') }}
                        </option>
                        <option selected value="other">
                          {{ $t('form.contact.claim_options.other') }}
                        </option>
                      </select>
                    </Field>
                  </div>
                </div>

                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3 mb-4">
                    <Field
                      v-slot="{ meta }"
                      v-model="form.estimated_amount"
                      rules="required"
                      name="estimated_amount"
                    >
                      <input
                        id="estimated_amount"
                        v-model="form.estimated_amount"
                        autocomplete="false"
                        class="block w-full px-4 py-3 border border-gray-400 rounded-lg focus:border-rendin-500 focus:outline-none"
                        :class="{ 'focus:border-rendin-500': !meta.valid }"
                        name="estimated_amount"
                        :placeholder="$t('form.contact.placeholder.estimated_amount')"
                        type="number"
                      />
                    </Field>
                  </div>

                  <div class="col-span-6 sm:col-span-3 mb-4">
                    <!--                      New dateTime component needed here-->

                    <!--                      <ValidationProvider rules="required" v-slot="{ passed, valid, failed }">-->
                    <!--                        <datetime-->
                    <!--                          id="when_happened"-->
                    <!--                          v-model="form.when_happened"-->
                    <!--                          class=""-->
                    <!--                          :class="{'focus:border-rendin-500': !passed, 'border-success-main': valid, 'border-error text-error': failed && hasSubmitted}"-->
                    <!--                          :input-class="['cursor-pointer', 'text-base', 'inside-block', 'w-full', 'focus:ring-rendin-500', 'focus:outline-none', 'w-full', 'text-base', 'text-black', 'placeholder-gray-400', 'border', 'border-gray-300', 'rounded-lg', 'shadow-sm', 'px-4', 'py-3']"-->
                    <!--                          :placeholder="$t('form.contact.placeholder.when_happened')"-->
                    <!--                          style="height:50px;"-->
                    <!--                        />-->
                    <!--                      </ValidationProvider>-->
                  </div>
                </div>
              </div>

              <div class="block mb-4">
                <Field
                  v-slot="{ classes, passed }"
                  v-model="form.message"
                  name="message"
                  rules="required|min:3"
                >
                  <textarea
                    id="message"
                    v-model="form.message"
                    class="block w-full px-4 py-3 border border-gray-400 rounded-lg focus:border-rendin-500 focus:outline-none h-48"
                    :class="{ 'focus:border-rendin-500': !passed, ...classes }"
                    name="message"
                    :placeholder="blok.email_text_placeholder"
                  ></textarea>
                </Field>
              </div>
              <div class="text-right">
                <span
                  v-if="showSuccess"
                  class="block text-success-main text-lg mt-6 pr-2 font-medium"
                  >{{ blok.success_message }}</span
                >

                <button
                  v-if="!showSuccess"
                  class="w-full inline-flex items-center justify-center rounded-full border border-transparent shadow-sm px-8 py-2 bg-rendin-500 text-base font-medium text-white hover:bg-rendin-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rendin-500 sm:ml-3 sm:w-auto sm:text-base"
                  :class="{ 'opacity-75': submitLoading }"
                  :disabled="submitLoading"
                  type="submit"
                  @click="submitButtonClicked"
                >
                  {{ blok.send_button_cta }}
                  <svg
                    v-if="submitLoading"
                    class="animate-spin h-5 w-5 ml-3 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    />
                    <path
                      class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { localizedRenderRichText } from '~/utils/localizedRenderRichText.js';
import { Field, Form } from 'vee-validate';

export default {
  name: 'SectionContact',
  components: { Field, Form },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      localizedRenderRichText,
    };
  },

  data() {
    return {
      errors: [],
      form: {
        name: null,
        type: 'contact',
        email: '',
        message: '',
        agreement_number: null,
        category: '',
        estimated_amount: null,
        when_happened: '',
      },
      showSuccess: false,
      showError: false,
      submitLoading: false,
      hasSubmitted: false,
    };
  },

  computed: {
    ...mapGetters(['getSettings']),
    content() {
      return this.localizedRenderRichText(this.blok.content, this.$localizedPath);
    },
  },

  methods: {
    submitButtonClicked() {
      this.hasSubmitted = true;
    },
    /* TODO - migration - validate that contact form works */
    async submitContact() {
      this.submitLoading = true;
      this.form.type = this.blok.type;

      const market = this.$getCountry();
      this.$fetch('/api/contact', {
        method: 'POST',
        body: { ...this.form, market: market },
      })
        .then(async () => {
          this.submitLoading = false;
          this.showSuccess = true;

          this.$trackEvent(
            'Contact us',
            {
              type: this.form.type,
            },
            {
              services: ['POSTHOG', 'GTAG'],
            },
          );

          // TODO: Hacky event sending to CustomerIO. Consider centralizing in './store/tracker'
          this.$cio.identify({
            id: this.form.email,
            email: this.form.email,
          });
          this.$cio.track('Contact us', {
            type: this.form.type,
            page_path: this.$route.fullPath,
            country: this.$getCountry(),
            locale: this.$getLocale(),
          });

          // TODO: Consider more centralized prod-vs-dev control here.
          // For ref: this Zapier call makes new ClickUp Ticket for Solution. This should
          // not fire in dev/demo. Previously it never reached in dev/demo, as '/api/contact/'
          // failed due to missing Zendesk tokens. However, now there is workaround in '/api/contact/'
          // and control logic was needed here.
          if (this.form.type === 'claim' && this.$config.public.rEnv === 'production') {
            await this.$fetch('/api/zapier-send', {
              method: 'POST',
              body: {
                url: 'https://hooks.zapier.com/hooks/catch/9636938/bbzcy3n/',
                pl: this.form,
              },
            }).then(() => {});
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
