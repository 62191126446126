<template>
  <div>
    <Field
      ref="providerAddressSearchRef"
      v-slot="{ meta, errors, validate, setTouched }"
      v-model="address"
      name="addressSearchInput"
      :validate-on-mount="immediate"
      :rules="addressSearchRules"
      tag="div"
    >
      <input-address-search
        :current-prediction-provider="currentPredictionProvider"
        :external-address="address"
        :failed-rules="isAddressSelected ? null : errors"
        :focus-on-load="focusOnLoad"
        :label="$t('form.label.address')"
        name="addressSearchInput"
        :open-dropdown-on-focus="!meta.valid"
        :placeholder="$t('form.placeholder.address_search')"
        :required="required"
        :select-no-options-text="$t('form.validation.select_no_options')"
        :source="source"
        :validation-failed="
          ((!meta.valid && meta.touched) || (!meta.valid && overrideFailed)) &&
          !showManualAddressForm
        "
        :validation-passed="meta.valid"
        :model-value="address"
        @reset-address="onResetAddress($event) && setTouched(true)"
        @select-address="
          onSelectAddress($event) && validate($event) && setTouched(true)
        "
      />
    </Field>
    <template
      v-if="
        isAddressSelected && currentPredictionProvider === PredictionProvider.GOOGLE
      "
    >
      <form-address-manual
        v-if="showManualAddressForm"
        :address-apartment-disclaimer="addressApartmentDisclaimer"
        :external-address="address"
        label-class="text-xs sm:text-base text-gray-600"
        name="manualAddressSearch"
        :source="source"
        @blur="onFormAddressManualBlur"
        @input="onManualInput"
      />
      <div v-else>
        <div class="py-2 text-sm">
          <span class="text-gray-700" @click="onClickShowManualForm">
            {{ $t('buttons.enter_address_manually_cta') }}</span
          >
          {{ ' ' }}
          <span
            class="cursor-pointer text-rendin-500 font-medium"
            @click="onClickShowManualForm"
          >
            {{ $t('buttons.enter_address_manually') }}</span
          >
        </div>
      </div>
    </template>
    <r-checkbox
      v-if="allowHideHouseNumber"
      v-model="address.addressHouseNumberHidden"
      :label="$t('form.label.hide_address')"
      name="addressHouseNumberHidden"
      @input="onBlur"
    />
    <input-apartment-number
      v-model:address-apartment-number="address.addressApartmentNumber"
      v-model:has-apartment-number="address.hasApartmentNumber"
      :disclaimer="addressApartmentDisclaimer"
      :immediate="immediate"
      :label="$t('form.label.address_apartment')"
      label-class="text-xs sm:text-base text-gray-600"
      name="apartmentNumberInput"
      :override-failed="overrideFailed"
      :placeholder="$t('form.placeholder.address_apartment')"
      :source="source"
      @blur="onBlur"
    />
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import { Field, validate } from 'vee-validate';
import { mapActions } from 'vuex';
import { defaultAddressObject } from '~/utils/objectStructures';
import InputAddressSearch from '~/components/snippets/InputAddressSearch.vue';
import FormAddressManual from '~/components/section/FormAddressManual.vue';
import { PredictionProvider, workflow } from '~/utils/constants';
import InputApartmentNumber from '~/components/snippets/InputApartmentNumber.vue';

export default {
  name: 'AddressSearchMolecule',
  components: { Field, InputApartmentNumber, FormAddressManual, InputAddressSearch },
  props: {
    addressApartmentDisclaimer: {
      type: String,
      required: false,
      default: '',
    },
    allowHideHouseNumber: {
      type: Boolean,
      default: false,
    },
    externalAddress: {
      type: Object,
      required: true,
      default: () => defaultAddressObject(),
    },
    focusOnLoad: {
      type: Boolean,
      default: false,
      required: false,
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
    },
    source: {
      type: String,
      required: true,
    },
    overrideFailed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input', 'blur'],
  data() {
    return {
      PredictionProvider: PredictionProvider,
      address: defaultAddressObject(),
      showManualAddressForm: false,
    };
  },
  computed: {
    addressSearchRules() {
      const houseNumberRequired = this.$getCountry() === 'pl';

      return {
        isValidAddressInput: true,
        isValidAddressInputCity: true,
        isValidAddressInputStreet: true,
        isValidAddressInputCountry: true,
        isValidAddressInputIndex: true,
        isValidAddressInputHouseNumber: houseNumberRequired,
      };
    },
    isAddressSelected() {
      return !!this.address.addressFirstLine;
    },
    currentPredictionProvider() {
      if (this.$getCountry().toUpperCase() === workflow.POLAND) {
        return PredictionProvider.GOOGLE;
      } else {
        return PredictionProvider.MAAAMET;
      }
    },
  },
  watch: {
    externalAddress: {
      handler(newValue) {
        if (!isEqual(newValue, this.address)) {
          this.address = defaultAddressObject(this.externalAddress);
        }
      },
      deep: true,
    },
    address: {
      handler(newAddress) {
        this.$emit('input', newAddress);
      },
      deep: true,
    },
  },
  created() {
    this.address = defaultAddressObject(this.externalAddress);
  },
  methods: {
    ...mapActions({
      trackManualAddressClicked: 'tracker/trackManualAddressClicked',
    }),
    async onSelectAddress(newAddress) {
      /* We treat Address Apartment Number as separate section, so it will not be written over */
      this.address = defaultAddressObject({
        ...newAddress,
        addressApartmentNumber: this.address.addressApartmentNumber,
        hasApartmentNumber: this.address.hasApartmentNumber,
        internalUserEditedAddressManually: false,
      });

      const validation = await validate(this.address, this.addressSearchRules);
      if (!validation.valid) {
        this.showManualAddressForm = true;
      } else {
        this.onBlur();
      }
    },
    onResetAddress(newAddress) {
      // TODO - migration - does this work reactively as expected?
      this.address = defaultAddressObject({
        ...newAddress,
        addressApartmentNumber: this.address.addressApartmentNumber,
        hasApartmentNumber: this.address.hasApartmentNumber,
        internalUserEditedAddressManually: false,
      });
      this.showManualAddressForm = false;
    },
    onManualInput(newAddress) {
      // TODO - migration - does this work reactively as expected?
      this.address = defaultAddressObject({
        ...newAddress,
        internalUserEditedAddressManually: true,
      });
    },
    onClickShowManualForm() {
      this.showManualAddressForm = true;
      this.trackManualAddressClicked({ source: this.source });
    },
    onBlur() {
      this.$emit('blur', this.address);
    },
    onFormAddressManualBlur(newAddress) {
      this.onManualInput(newAddress);
      this.onBlur();
    },
  },
};
</script>
