<template>
  <section v-editable="blok" class="bg-gray-50 px-3 py-8 md:py-12 flex-grow">
    <div class="my-4">
      <component
        :is="blok.component"
        v-for="blok in blok.profilePrimarySetting"
        :key="blok._uid"
        :blok="blok"
        show-email-change
        :submit-button-loading="putProfileInProgress"
        submit-button-wrapper-class="bg-gray-50 -mx-3"
        @submitted-profile-data="(profileData) => putProfileData(profileData)"
      />
    </div>
    <div class="my-4">
      <component
        :is="blok.component"
        v-for="blok in blok.profileIdCodeSettings"
        :key="blok._uid"
        :blok="blok"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export default {
  name: 'ProfileSettings',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      profile: 'users/profile',
      hasSessionUser: 'users/hasSessionUser',
      putProfileInProgress: 'profiles/putProfileInProgress',
    }),
  },

  beforeMount() {
    if (!this.hasSessionUser) {
      // redirect to login page and open SignInModal
      this.$router.push({ path: this.$localizedPath('/tenants/?login=open') });
    }
  },

  methods: {
    ...mapActions({
      actionPutProfile: 'profiles/putProfile',
    }),

    putProfileData(profileData) {
      if (!this.putProfileInProgress) {
        this.actionPutProfile({ profile: profileData, id: this.profile.id }).then(
          (response) => {
            if (!response.failed)
              Toastify({
                text: this.$t('profile_saved'),
                duration: 3000,
                gravity: 'top', // `top` or `bottom`
                backgroundColor: '#4caf50',
                className: 'text-white',
                position: 'center',
              }).showToast();
          },
        );
      }
    },
  },
};
</script>
