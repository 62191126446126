import { v4 as uuidv4 } from 'uuid';
import { toUpper, get } from 'lodash';
import Login from '~/components/_non-storybloks/session/Login';
import { sessionEventName } from '~/utils/trackerConstants';
import { LocalStorageKeys, userRole } from '~/utils/constants.js';
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithCustomToken,
} from 'firebase/auth';

const stateDefaults = {
  profile: null,
  profileAssociation: null,
  user: {
    email: null,
    uid: null,
    isAnonymous: null,
    emailVerified: null,
  },
  firebaseUser: {
    // Defined by the Firebase
  },
  firebaseClaims: {
    // Defined by the Firebase
  },
  appLoginToken: {
    fetchedAtSeconds: null,
    token: null,
  },
  preferences: {
    role: null,
  },
};

export const state = () => ({
  /**
   * @type {ProfileFromServer}
   */
  profile: stateDefaults.profile,
  profileAssociation: stateDefaults.profileAssociation,
  user: { ...stateDefaults.user },
  firebaseUser: { ...stateDefaults.firebaseUser },
  firebaseClaims: { ...stateDefaults.firebaseClaims },
  signupFlow: {
    userData: null,
    currentSignupFlowInProgress: null,
    errorCode: null,
    in: {
      onAuthStateChangedAction: {
        ignore: {
          fetchProfilesSyncAndDataAsync: {
            count: 0,
          },
        },
      },
    },
  },
  signInFlow: {
    signInWithEmailLinkDone: undefined,
  },
  appLoginToken: stateDefaults.appLoginToken,
  preferences: { ...stateDefaults.preferences },
});

const signUpFlowInitiatorSignUpAndCreateProfile = 'SignUpAndCreateProfile';

const errorCodeFirebaseEmailAlreadyInUse = 'auth/email-already-in-use';
const errorCodeFirebaseOperationNotAllowed = 'auth/operation-not-allowed';

export const getters = {
  /**
   * @returns {Object} user of the session; please note, it can be empty as its default setting is set from {@link stateDefaults}
   */
  sessionUser: (state) => state.user,
  /**
   * @returns {boolean} false in case user is not in the session; true otherwise.
   */
  hasSessionUser: (state) => state.user.uid !== null,

  signupFlow: (state) => state.signupFlow,
  signupFlowSignInAndCreateProfileRunning: (state) =>
    state.signupFlow.currentSignupFlowInProgress ===
    signUpFlowInitiatorSignUpAndCreateProfile,
  signupFlowIgnoreFetchProfilesAndDataCall: (state) => {
    return (
      state.signupFlow.in.onAuthStateChangedAction.ignore.fetchProfilesSyncAndDataAsync
        .count > 0
    );
  },

  firebaseUser: (state) => state.firebaseUser,
  firebaseClaims: (state) => state.firebaseClaims,

  /**
   * @return {ProfileFromServer|null}
   */
  profile: (state) => state.profile,
  profileAssociation: (state) => state.profileAssociation,

  /**
   * @return {ProfileFromServer|null}
   */
  profileId: (state) => (state.profile ? state.profile.id : null),

  /**
   * @returns {{fetchedAtSeconds: number|null, token: string|null}}
   */
  appLoginToken: (state) => state.appLoginToken,
  isAppleAnonymousUser: (state) => {
    const appleDomains = ['privaterelay.appleid.com'];

    if (!state.user || !state.user.email) return false;
    const match = appleDomains.some((domain) =>
      state.user.email.toLowerCase().includes(domain),
    );
    return match;
  },
  profileHasBasicInformation: (state) => {
    return (
      state.profile &&
      state.profile.firstName &&
      state.profile.lastName &&
      state.profile.email &&
      state.profile.phoneNumberFull &&
      state.profile.agreedTCAndPrivacyPolicyOn
    );
  },
  userRole: (state) => {
    return state.preferences.role;
  },
  hasAcceptedTermsAndConditions: (state) => {
    return state.profile && !!state.profile.agreedTCAndPrivacyPolicyOn;
  },
};

/**
 * @typedef {'PL'|'EE'} WorkFlow
 */

/**
 * @typedef {'EE'|'PL'} PersonalIdCodeCountryCode
 */

/**
 * @typedef {{
 *  id?: string,
 *  email?: string,
 *  firstName?: string,
 *  lastName?: string,
 *  idCode?: string,
 *  personalIdCodeCountryCode?: PersonalIdCodeCountryCode,
 *  phoneNumber?: string,
 *  phoneCountryCode?: string,
 *  phoneCountryCodeIso?: string,
 * }} Profile
 */

/**
 * @typedef {{
 *  withAgreement?: boolean,
 *  withAdvertisement?: boolean,
 * }} ProfileAssociation
 */

/**
 * @typedef {{
 *  id: string,
 *  email: string,
 *  firstName?: string,
 *  lastName?: string,
 *  idCode?: string,
 *  personalIdCodeCountryCode?: PersonalIdCodeCountryCode,
 *  phoneNumberFull?: string,
 *  workflow?: WorkFlow,
 *  scoreStatus: 'DECLINED' | 'APPROVED' | 'DECLINED',
 * }} ProfileFromServer
 */

export const mutations = {
  // See at @nuxtjs/firebase config at nuxt.config.js
  ON_AUTH_STATE_CHANGED_MUTATION: function (state, { authUser, claims }) {
    state.firebaseClaims = claims ? { ...claims } : { ...stateDefaults.firebaseClaims };
    mutations.UPDATE_FIREBASE_USER(state, {
      firebaseCurrentUser: authUser ? { ...authUser.toJSON() } : null,
    });
  },
  UPDATE_FIREBASE_USER: function (state, { firebaseCurrentUser }) {
    state.firebaseUser = firebaseCurrentUser
      ? { ...firebaseCurrentUser }
      : { ...stateDefaults.firebaseUser };
    mutations.UPDATE_SESSION_USER_FROM_FIREBASE_USER(state, {
      firebaseCurrentUser: firebaseCurrentUser,
    });
  },
  UPDATE_SESSION_USER_FROM_FIREBASE_USER: function (state, { firebaseCurrentUser }) {
    state.user = firebaseCurrentUser
      ? {
          email: firebaseCurrentUser.email,
          uid: firebaseCurrentUser.uid,
          isAnonymous: firebaseCurrentUser.isAnonymous,
          emailVerified: firebaseCurrentUser.emailVerified,
        }
      : { ...stateDefaults.user };
  },
  // Can be used to manually set user details before Firebase auth is init
  UPDATE_SESSION_USER: function (state, { sessionUser }) {
    state.user = sessionUser
      ? {
          email: sessionUser.email,
          uid: sessionUser.uid,
          isAnonymous: sessionUser.isAnonymous,
          emailVerified: sessionUser.emailVerified,
        }
      : { ...stateDefaults.user };
  },
  UPDATE_PROFILE: function (state, profile) {
    state.profile = profile ? { ...profile } : stateDefaults.profile;
    if (profile?.profileInitialType) {
      state.preferences.role =
        profile.profileInitialType === userRole.TENANT ||
        profile.profileInitialType === userRole.LANDLORD
          ? profile.profileInitialType
          : null;
    }
  },
  UPDATE_PROFILE_ASSOCIATIONS: function (state, profileAssociation) {
    state.profileAssociation = profileAssociation ? { ...profileAssociation } : null;
  },
  SIGNUP_FLOW_SET_USER_DATA: function (state, profileData) {
    state.signupFlow.userData = profileData ? { ...profileData } : null;
  },
  SIGNUP_FLOW_SET_IN_PROGRESS: function (state, signupFlowNameOrNull) {
    state.signupFlow.currentSignupFlowInProgress = signupFlowNameOrNull;
  },
  UPDATE_APP_LOGIN_TOKEN: function (state, token) {
    state.appLoginToken =
      token === null
        ? { ...stateDefaults.appLoginToken }
        : { fetchedAtSeconds: getSecondsSinceEpoch(), token: token };
  },
  SET_USER_ROLE: function (state, role) {
    state.preferences.role = role;
  },
  IGNORE_NEXT_CALL_OF_SIGNUP_FLOW_FETCH_PROFILES_AND_DATA: function (state) {
    state.signupFlow.in.onAuthStateChangedAction.ignore.fetchProfilesSyncAndDataAsync
      .count++;
  },
  SIGNUP_FLOW_FETCH_PROFILES_AND_DATA_CALL_IGNORED: function (state) {
    if (
      state.signupFlow.in.onAuthStateChangedAction.ignore.fetchProfilesSyncAndDataAsync
        .count > 0
    ) {
      state.signupFlow.in.onAuthStateChangedAction.ignore.fetchProfilesSyncAndDataAsync
        .count--;
    }
  },
};

const getSecondsSinceEpoch = () => Math.round(Date.now() / 1000);

/**
 * @typedef {'WEB'|'WEB_DIGIBROKER'} ProfileSourceIndication
 */

export const actions = {
  _updateStoreWithFirebaseAuthCurrentUser: function ({
    dispatch,
    commit,
    rootGetters,
  }) {
    const nuxtApp = useNuxtApp();

    const currentUser = nuxtApp.$auth.currentUser;
    const firebaseCurrentUser = currentUser ? currentUser.toJSON() : null;
    return Promise.resolve()
      .then(() =>
        Promise.resolve(
          commit('UPDATE_FIREBASE_USER', { firebaseCurrentUser: firebaseCurrentUser }),
        ),
      )
      .then(() =>
        firebaseCurrentUser && firebaseCurrentUser.email
          ? dispatch('tracker/updateUserPropertiesBasedOnStore', undefined, {
              root: true,
            })
          : Promise.resolve(),
      )
      .then(() => {
        dispatch('session/prefillFromFirebaseSessionAndProfile', undefined, {
          root: true,
        });
      });
  },
  updateEmail: function ({ dispatch, commit }, { email, onlyUpdateEmail }) {
    const nuxtApp = useNuxtApp();

    const auth = nuxtApp.$auth;
    const currentUser = auth.currentUser;
    return currentUser
      ? currentUser
          .updateEmail(email)
          .then(() =>
            onlyUpdateEmail
              ? Promise.resolve()
              : dispatch('_updateStoreWithFirebaseAuthCurrentUser'),
          )
      : Promise.resolve();
  },
  updatePassword: function (context, { passwd }) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;
    const currentUser = auth.currentUser;
    if (currentUser) {
      return currentUser.updatePassword(passwd).catch(console.error);
    }
  },
  _signUpWithEmailAndGeneratedPassword: function ({ dispatch, commit }, { setEmail }) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;
    commit('session/SIGNUP_FLOW_SET_ERROR_CODE', null, { root: true });
    return Promise.resolve()
      .then(() => dispatch('_setAuthLanguage'))
      .then(() => commit('IGNORE_NEXT_CALL_OF_SIGNUP_FLOW_FETCH_PROFILES_AND_DATA'))
      .then(() => createUserWithEmailAndPassword(auth, setEmail, uuidv4()))
      .then(() => dispatch('_updateStoreWithFirebaseAuthCurrentUser'))
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
        const errorCode = err.code;
        if (errorCode === errorCodeFirebaseOperationNotAllowed) {
          commit('session/SIGNUP_FLOW_SET_ERROR_CODE', errorCode, { root: true });
        }
        throw err;
      });
  },
  _setAuthLanguage: function ({ rootGetters }) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    if (auth && rootGetters.getLocale) {
      auth.languageCode = rootGetters.getLocale;
    }
    return Promise.resolve();
  },
  /**
   * @param {string} email - email to create profile with.
   * @param {Profile} profileData - data to create profile based.
   * @param {'postProfile'} functionToCall - we support limited (historically more than 1) number of calls.
   * @param {ProfileSourceIndication} indicatedProfileSource - indication of the flow where the profile is created from.
   */
  postProfile: function (
    { dispatch, rootGetters },
    { email, profileData, functionToCall, indicatedProfileSource },
  ) {
    const nuxtApp = useNuxtApp();

    return Promise.resolve()
      .then(() =>
        dispatch(
          'firebase/functionCallRegionEU',
          {
            functionName: functionToCall,
            data: {
              email: email,
              ...profileData,
              workflow: profileData.workflow
                ? profileData.workflow
                : rootGetters.getCountry.toUpperCase(),
              locale: profileData.locale ? profileData.locale : rootGetters.getLocale,
              indicatedProfileSource: indicatedProfileSource,
            },
          },
          { root: true },
        ),
      )
      .then((response) => {
        if (response.data?.error?.code === 'already-exists') {
          return Promise.resolve()
            .then(() => dispatch('_getProfile'))
            .then(() =>
              dispatch(
                'profiles/putProfile',
                { profile: profileData, id: rootGetters['users/profile'].id },
                { root: true },
              ),
            );
        }
      })
      .then(() =>
        // Please note the implementation of this dispatch carefully - some of it is in sync, some of it is not!
        dispatch('fetchProfilesSyncAndDataAsync'),
      )
      .then(() =>
        nuxtApp.$trackEvent(
          sessionEventName.SIGNUP,
          { email: email },
          rootGetters['tracker/getDefaultTrackerProviderOptions'],
        ),
      )
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
  _signUpAndCreateProfileDone: function ({ commit }) {
    commit('SIGNUP_FLOW_SET_USER_DATA', null);
    commit('SIGNUP_FLOW_SET_IN_PROGRESS', null);
  },
  _getProfileInfo: function ({ dispatch }) {
    return Promise.resolve().then(() =>
      dispatch(
        'firebase/functionCallRegionEU',
        {
          functionName: 'getProfileInfoWeb',
          data: {
            request: {
              additional: {
                associations: true,
              },
            },
          },
        },
        { root: true },
      ),
    );
  },
  _getProfile: function ({ commit, dispatch, getters }) {
    return Promise.resolve()
      .then(() => dispatch('_getProfileInfo'))
      .then((response) => {
        if (response.data?.success) {
          commit('UPDATE_PROFILE', response.data.data?.profileInfo);
          commit(
            'UPDATE_PROFILE_ASSOCIATIONS',
            response.data.data?.additional?.association,
          );
        }
      })
      .then(() => {
        const profile = getters.profile;

        return profile
          ? Promise.allSettled([
              dispatch('tracker/updateUserPropertiesBasedOnStore', undefined, {
                root: true,
              }),
              dispatch('session/prefillFromFirebaseSessionAndProfile', undefined, {
                root: true,
              }),
            ])
          : Promise.resolve();
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err);
      });
  },
  setupUserTrackers: function ({ dispatch, commit }) {
    return dispatch('tracker/updateUserPropertiesBasedOnStore', undefined, {
      root: true,
    });
  },
  sendSignInLinkToEmailFromAnonymousSession: function (
    { dispatch },
    { emailToSendSendSignInLinkTo, redirectUrl },
  ) {
    return dispatch('updateEmail', { email: emailToSendSendSignInLinkTo }).then(() =>
      dispatch('sendSignInLinkToEmail', { emailToSendSendSignInLinkTo, redirectUrl }),
    );
  },

  /**
   * @param {string} profileEmail - existing profile email.
   * @param {string} language -  'en' | 'pl' | 'et' | 'ru' | undefined = 'en'.
   */
  requestNonceAndSendPinToEmail: function ({ dispatch }, { profileEmail, language }) {
    return Promise.resolve().then(() =>
      dispatch(
        'firebase/functionCallRegionEU',
        {
          functionName: 'getTokenForLogin',
          data: {
            request: {
              tokenAccess: {
                email: profileEmail,
                language: language,
              },
            },
          },
        },
        { root: true },
      ).catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      }),
    );
  },

  /**
   * @param {string} profileEmail - existing profile email.
   * @param {string} pinSentToEmail - password sent to profile email
   * @param {string} nonce - nonce
   */
  requestTokenWithPinAndNonce: function (
    { dispatch, commit, rootGetters },
    { profileEmail, pinSentToEmail, nonce },
  ) {
    /* Fixed "PIN CODE" (password) for automated testing, needs to be in sync with Rendin Server  */
    const passwordUsedForCI = '-!-9n9m1bWyc3LdIDL3AGzHkL-!-';

    const password =
      rootGetters.useEmulator && rootGetters.useEmulatorForCi
        ? passwordUsedForCI
        : pinSentToEmail;

    return Promise.resolve().then(() =>
      dispatch(
        'firebase/functionCallRegionEU',
        {
          functionName: 'getTokenForLogin',
          data: {
            request: {
              accessToken: {
                email: profileEmail,
                password: password,
                nonce: nonce,
              },
            },
          },
        },
        { root: true },
      ).catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      }),
    );
  },

  /**
   * @param {string} emailToSendSendSignInLinkTo - email to send the link to for signing in.
   * @param {string} pathLocalizer - used to localize the path when opening the link.
   * @param {string|undefined} successfulLoginReroute - if defined, will be used as a path after successful login.
   */
  sendSignInLinkToEmail: function (
    { dispatch },
    {
      emailToSendSendSignInLinkTo,
      pathLocalizer = (path) => path,
      successfulLoginReroute = undefined,
    },
  ) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    const loginRerouteAsParamDefinition = successfulLoginReroute
      ? `${Login.pathParams.successfulLoginReroute}=${btoa(successfulLoginReroute)}`
      : '';
    const actionCodeSettings = {
      url: `${
        this.$getHost() + pathLocalizer(Login.path.page)
      }?${loginRerouteAsParamDefinition}`,
      handleCodeInApp: true,
    };
    return Promise.resolve()
      .then(() => dispatch('_setAuthLanguage'))
      .then(() =>
        sendSignInLinkToEmail(auth, emailToSendSendSignInLinkTo, actionCodeSettings),
      )
      .then(() => {
        this.$cookies.set('fire-login-email', emailToSendSendSignInLinkTo, {
          path: '/',
          maxAge: 60 * 60 * 24 * 7,
          secure: true,
          sameSite: 'None',
        });
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        console.error(err.message);
      });
  },
  /**
   * @returns {Promise<boolean>} - true in case of all good; false in case of something happened.
   */
  _signInFlowSafe: function (
    { commit, dispatch },
    { signInFlowSteps, skipFetchProfilesAndData },
  ) {
    return Promise.resolve()
      .then(() => dispatch('_setAuthLanguage'))
      .then(() => signInFlowSteps())
      .then(() => dispatch('_updateStoreWithFirebaseAuthCurrentUser'))
      .then(() =>
        !!skipFetchProfilesAndData === false
          ? // Please note the implementation of this dispatch carefully - some of it is in sync, some of it is not!
            dispatch('fetchProfilesSyncAndDataAsync')
          : Promise.resolve(),
      )
      .then(() => true) // All good!
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
        return false; // Something happened.
      });
  },
  /**
   * @param href - href sent back by the Google login mechanism. See the docs for reference
   * @returns {Promise<boolean>} - true in case of all good; false in case of something happened.
   */
  signInWithEmailLink: function ({ commit, dispatch, rootGetters }, { href }) {
    const emailUsedToSendSendSignInLinkTo = this.$cookies.get('fire-login-email');
    // We do not trigger sign in, if it is not from user using the same browser.
    if (typeof emailUsedToSendSendSignInLinkTo !== 'string') {
      return Promise.resolve(false); // Link used but not owned by this user.
    }
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    if (isSignInWithEmailLink(auth, href)) {
      const signInFlow = () =>
        Promise.resolve()
          .then(() => signInWithEmailLink(auth, emailUsedToSendSendSignInLinkTo, href))
          .then((userCredentials) => {
            const weHaveUserFromFirestoreReturned = userCredentials.user.uid;
            if (weHaveUserFromFirestoreReturned) {
              this.$cookies.remove('fire-login-email');
            }
          })
          .finally(() => {
            commit('session/SET_LOGIN_IN_PROGRESS', false, { root: true });
            nuxtApp.$trackEvent(
              sessionEventName.LOGIN,
              {},
              rootGetters['tracker/getDefaultTrackerProviderOptions'],
            );
          });
      return dispatch('_signInFlowSafe', { signInFlowSteps: signInFlow });
    }
  },
  signInWithToken: function ({ commit, dispatch }, { token }) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    if (typeof token === 'string' && token.trim().length > 0) {
      const signInFlow = () =>
        Promise.resolve()
          .then(() => signInWithCustomToken(auth, token))
          .then(() => commit('UPDATE_APP_LOGIN_TOKEN', token));
      return dispatch('_signInFlowSafe', { signInFlowSteps: signInFlow });
    }
  },
  /**
   * @param {ProfileSourceIndication} indicatedProfileSource - indication of the flow where the profile is created from.
   * @param {firebase.auth.AuthProvider} provider - auth provider from firebase auth
   * */
  _signInWithOAuthPopup: function ({ dispatch, getters, commit }, { provider }) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    const signInFlow = () =>
      Promise.resolve().then(() => {
        return signInWithPopup(auth, provider);
      });
    return Promise.resolve()
      .then(() => {
        commit('IGNORE_NEXT_CALL_OF_SIGNUP_FLOW_FETCH_PROFILES_AND_DATA');
        return dispatch('_signInFlowSafe', {
          signInFlowSteps: signInFlow,
          skipFetchProfilesAndData: true, // Callers must manually do it!
        });
      })
      .then((signInSuccessful) =>
        signInSuccessful
          ? Promise.resolve(auth.currentUser)
          : Promise.reject(new Error(`Signing in cancelled for ${provider.id} login`)),
      )
      .catch((err) => {
        commit('SIGNUP_FLOW_FETCH_PROFILES_AND_DATA_CALL_IGNORED');
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      });
  },
  _createProfileIfDoesNotExist: function (
    { dispatch, getters },
    { indicatedProfileSource, profileInitialType },
  ) {
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    return getters.profile
      ? Promise.resolve()
      : dispatch('postProfile', {
          email: auth.currentUser.email,
          profileData: {
            workflow: toUpper(getters.getCountry),
            locale: getters.getLocale,
            profileInitialType: profileInitialType ?? null,
          },
          functionToCall: 'postProfile',
          indicatedProfileSource: indicatedProfileSource,
        });
  },
  signInWithGooglePopup: function (
    { commit, dispatch, getters },
    { indicatedProfileSource, profileInitialType },
  ) {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });

    commit('session/SET_LOGIN_IN_PROGRESS', true, { root: true });
    return Promise.resolve()
      .then(() =>
        dispatch('_signInWithOAuthPopup', {
          provider: provider,
        }),
      )
      .then(() =>
        dispatch('_createProfileIfDoesNotExist', {
          indicatedProfileSource: indicatedProfileSource,
          profileInitialType: profileInitialType,
        }),
      )
      .then(() =>
        // Please note the implementation of this dispatch carefully - some of it is in sync, some of it is not!
        dispatch('fetchProfilesSyncAndDataAsync'),
      )
      .then(() => Promise.resolve(getters.firebaseUser))
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      })
      .finally(() => {
        commit('session/SET_LOGIN_IN_PROGRESS', false, { root: true });
      });
  },
  signInWithApplePopup: function (
    { commit, dispatch, getters, rootGetters },
    { indicatedProfileSource, profileInitialType },
  ) {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    commit('session/SET_LOGIN_IN_PROGRESS', true, { root: true });
    return (
      Promise.resolve()
        .then(() =>
          dispatch('_signInWithOAuthPopup', {
            provider: provider,
          }),
        )
        /* With Apple Sign In users can select Anonymous mode, where apple generates a new email for them. We cannot support this, so we delay profile creation until they change their email */
        .then(() => {
          const notAppleAnonymousUser = !getters.isAppleAnonymousUser;

          if (notAppleAnonymousUser) {
            return Promise.resolve()
              .then(() =>
                dispatch('_createProfileIfDoesNotExist', {
                  indicatedProfileSource: indicatedProfileSource,
                  profileInitialType: profileInitialType,
                }),
              )
              .then(() =>
                // Please note the implementation of this dispatch carefully - some of it is in sync, some of it is not!
                dispatch('fetchProfilesSyncAndDataAsync'),
              );
          }
        })
        .then(() => rootGetters['users/sessionUser'])
        .catch((err) => {
          dispatch('tracker/reportErrorToSentry', err, { root: true });
        })
        .finally(() => {
          commit('session/SET_LOGIN_IN_PROGRESS', false, { root: true });
        })
    );
  },
  fetchProfilesSyncAndDataAsync: async function ({ dispatch, getters }) {
    // I need these to be loaded in parallel, but I need to wait for both of them to finish.
    await Promise.all([
      dispatch('tenants/requestTenantProfile', undefined, { root: true }).catch(
        (err) => {
          dispatch('tracker/reportErrorToSentry', err, { root: true });
        },
      ),
      dispatch('_getProfile').catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      }),
    ]);
    // Now, I will trigger a tangling call to load agreements in the background - call, keep it dangling, and return.
    if (getters.profileAssociation?.withAgreement) {
      dispatch('agreements/getAgreements', undefined, { root: true });
    }
    if (getters.profileAssociation?.withAdvertisement) {
      dispatch('advertisements/getAdvertisements', undefined, { root: true });
    }
  },
  // See the plugin at plugins/firebase.ts
  onAuthStateChangedAction: function (
    { commit, dispatch, getters },
    { authUser, claims },
  ) {
    if (authUser) {
      dispatch('session/prefillFromFirebaseSessionAndProfile', undefined, {
        root: true,
      });
      if (authUser.toJSON().email) {
        if (getters.signupFlowIgnoreFetchProfilesAndDataCall) {
          commit('SIGNUP_FLOW_FETCH_PROFILES_AND_DATA_CALL_IGNORED');
        } else {
          // Please note the implementation of this dispatch carefully - some of it is in sync, some of it is not!
          dispatch('fetchProfilesSyncAndDataAsync');
        }
      }
    }
  },
  _clearUsersDataFromStore: function ({ commit }) {
    commit('UPDATE_PROFILE', null);
    commit('SIGNUP_FLOW_SET_USER_DATA', null);
    commit('SIGNUP_FLOW_SET_IN_PROGRESS', null);
    commit('session/SIGNUP_FLOW_SET_ERROR_CODE', null, { root: true });
    commit('UPDATE_APP_LOGIN_TOKEN', null);
    commit('SET_USER_ROLE', null);
  },
  fetchProfile: function ({ dispatch }) {
    return dispatch('_getProfile');
  },
  logout: function ({ dispatch, rootGetters, commit }) {
    // Global clear of items is requested too.
    const nuxtApp = useNuxtApp();
    const auth = nuxtApp.$auth;

    const cleanCookies = () => {
      localStorage.removeItem(LocalStorageKeys.DIGIBROKER_CACHE);
    };

    return Promise.resolve()
      .then(() =>
        dispatch('tenants/clearTenantProfileDataFromStore', undefined, { root: true }),
      )
      .then(() => dispatch('_clearUsersDataFromStore'))
      .then(() => cleanCookies())
      .then(() => auth.signOut())
      .then(() => commit('notifications/RESET', null, { root: true }))
      .then(() => commit('agreements/CLEAR_GET_AGREEMENTS', undefined, { root: true }))
      .then(() => dispatch('session/reset', undefined, { root: true }))
      .then(() =>
        nuxtApp.$trackEvent(
          sessionEventName.LOGOUT,
          {},
          rootGetters['tracker/getDefaultTrackerProviderOptions'],
        ),
      )
      .then(() => nuxtApp.$resetIdentity());
  },
  getAppLoginToken: function ({ commit, dispatch, getters }) {
    const getResponse = (token = null) => ({ token: token || null });
    const currentTokenInCache = get(getters.appLoginToken, 'token', null);
    const currentTokenInCacheSetInSeconds = get(
      getters.appLoginToken,
      'fetchedAtSeconds',
      false,
    );
    const inSeconds50minutes = 3000; // We trust the cache for this amount of time.
    const tokenHeldInCacheInSeconds =
      getSecondsSinceEpoch() - currentTokenInCacheSetInSeconds;
    if (
      currentTokenInCache &&
      currentTokenInCacheSetInSeconds &&
      tokenHeldInCacheInSeconds <= inSeconds50minutes
    ) {
      return Promise.resolve(getResponse(currentTokenInCache));
    } else {
      // Fetch current token.
      return Promise.resolve()
        .then(() =>
          dispatch(
            'firebase/functionCallRegionEU',
            { functionName: 'getTokenForLogin' },
            { root: true },
          ),
        )
        .then((response) => get(response, 'data.token', null))
        .then((token) => {
          if (!token) {
            throw new Error('Token not found when requested!');
          }
          return token;
        })
        .then((token) => {
          commit('UPDATE_APP_LOGIN_TOKEN', token);
          return token;
        })
        .then((token) => getResponse(token))
        .catch((err) => {
          dispatch('tracker/reportErrorToSentry', err, { root: true });
          return getResponse(undefined);
        });
    }
  },
  setUserRole({ commit, dispatch, getters }, role) {
    const oldRole = getters.userRole;
    commit('SET_USER_ROLE', role);
    dispatch(
      'profiles/putProfile',
      {
        profile: { profileInitialType: role },
        id: getters.profileId,
      },
      { root: true },
    ).then(() => {
      dispatch(
        'tracker/trackRolePickerSwitchRole',
        { newRole: role, oldRole: oldRole },
        { root: true },
      );
    });
  },

  /**
   * @param dispatch
   * @param {string} newEmail - new profile email.
   * @param {string} pinSentToEmail - Password (PIN code) sent to the requested email to confirm the change.
   */
  requestAuthenticationEmailChange({ dispatch }, { newEmail, pinSentToEmail }) {
    const authenticationData = {};
    if (newEmail) {
      authenticationData.email = newEmail;
    }
    if (pinSentToEmail) {
      authenticationData.password = pinSentToEmail;
    }
    return Promise.resolve().then(() =>
      dispatch(
        'firebase/functionCallRegionEU',
        {
          functionName: 'putProfileEmail',
          data: {
            requestData: {
              authentication: {
                ...authenticationData,
              },
            },
          },
        },
        { root: true },
      ).catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      }),
    );
  },
};
