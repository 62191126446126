// plugins/vee-validate.js (Nuxt 3 Plugin)

import { defineRule, configure } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  between,
  regex,
  numeric,
  min_value,
  max_value,
} from '@vee-validate/rules';
import countries from 'i18n-iso-countries';
import Isikukood from 'isikukood/dist/isikukood.esm.js';
import type { VueI18n } from 'vue-i18n';

export default defineNuxtPlugin({
  name: 'validation',
  async setup(nuxtApp) {
    const i18n = nuxtApp.$i18n as VueI18n;
    const getCountry = nuxtApp.$getCountry;
    defineRule('required', (value: unknown) => {
      if (!required(value)) {
        return i18n.t('form.validation.is_required');
      }
      return true;
    });

    defineRule('email', (value: unknown) => {
      if (!email(value)) {
        return i18n.t('form.validation.email');
      }
      return true;
    });

    defineRule('min', (value: unknown, [length]: [string | number]) => {
      if (!min(value, { length })) {
        return i18n.t('form.validation.min', { length });
      }
      return true;
    });

    defineRule('max', (value: unknown, [length]: [string | number]) => {
      if (!max(value, { length })) {
        return i18n.t('form.validation.max', { length });
      }
      return true;
    });

    defineRule(
      'between',
      (value: unknown, [minVal, maxVal]: [string | number, string | number]) => {
        if (!between(value, { min: minVal, max: maxVal })) {
          return i18n.t('form.validation.between', { min: minVal, max: maxVal });
        }
        return true;
      },
    );

    defineRule('regex', (value: unknown, [pattern]: [string | RegExp]) => {
      if (!regex(value, { regex: pattern })) {
        return i18n.t('form.validation.invalid_format');
      }
      return true;
    });

    defineRule('numeric', (value: unknown) => {
      if (!numeric(value)) {
        return i18n.t('form.validation.numeric');
      }
      return true;
    });

    defineRule('min_value', (value: unknown, [minVal]: [string | number]) => {
      if (!min_value(value, { min: minVal })) {
        return i18n.t('form.validation.min_value', { min: minVal });
      }
      return true;
    });

    defineRule('max_value', (value: unknown, [maxVal]: [string | number]) => {
      if (!max_value(value, { max: maxVal })) {
        return i18n.t('form.validation.max_value', { max: maxVal });
      }
      return true;
    });

    // Define Custom Rules

    // URL Validation
    defineRule('url', (value: unknown) => {
      const urlRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      return urlRegex.test(value) || 'This value must be a valid URL';
    });

    // Non-Anonymous Email
    defineRule('isNonAnonymousEmail', async (value: unknown) => {
      const appleDomains = ['privaterelay.appleid.com'];
      const containsApplePrivacyDomain = appleDomains.some((domain) => {
        return value.toLowerCase().includes(domain);
      });
      return (
        !containsApplePrivacyDomain ||
        'Please use your personal email, not anonymous one, so you could contact tenants & landlords.'
      );
    });

    // ID Code Validation
    defineRule('idCode', (value: string) => {
      if (getCountry() === 'pl') {
        return validatePLIdCode(value) || 'Invalid Polish ID code.';
      } else if (getCountry() === 'ee') {
        return validateEEIdCode(value) || 'Invalid Estonian ID code.';
      }
      return true;
    });

    // ID Code or Email
    defineRule('idCodeOrEmail', (value: string) => {
      const emailValidationRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (getCountry() === 'pl') {
        return (
          validatePLIdCode(value) ||
          emailValidationRegex.test(value) ||
          'Must be a valid ID code or email.'
        );
      } else if (getCountry() === 'ee') {
        return (
          validateEEIdCode(value) ||
          emailValidationRegex.test(value) ||
          'Must be a valid ID code or email.'
        );
      }
      return true;
    });

    // Required With
    defineRule('requiredWith', (value: unknown, [dependency]: [string | number]) => {
      if (dependency && dependency !== 'null' && dependency.length > 1) {
        return value ? true : 'This field is required.';
      }
      return true;
    });

    // Valid Telephone Input
    defineRule('isValidTelInput', (value: unknown, [isValid]: [string | boolean]) => {
      return isValid === 'true' || i18n.t('form.validation.phone');
    });

    // Workflow Correct
    defineRule('isWorkflowCorrect', (value: string) => {
      return ['EE', 'PL'].includes(value) || 'Invalid workflow.';
    });

    // Language Correct
    defineRule('isLanguageCorrect', (value: string) => {
      return ['et', 'pl', 'en', 'ru'].includes(value) || 'Invalid language selection.';
    });

    // Valid Address Inputs
    defineRule('isValidAddressInput', (value: unknown) => {
      return (
        !!value?.addressFirstLine ||
        i18n.t('form.validation.address_message') ||
        'Invalid address.'
      );
    });

    defineRule('isValidCityInput', (value: unknown) => {
      return (
        !!value?.addressCity ||
        i18n.t('form.validation.address_message') ||
        'Invalid city.'
      );
    });

    defineRule('isValidAddressInputStreet', (value: unknown) => {
      return (
        !!value?.addressStreet ||
        i18n.t('form.validation.address_street') ||
        'Invalid street.'
      );
    });

    defineRule('isValidAddressInputHouseNumber', (value: unknown) => {
      return (
        !!value?.addressHouseNumber ||
        i18n.t('form.validation.address_house_number') ||
        'Invalid house number.'
      );
    });

    defineRule('isValidAddressInputCity', (value: unknown) => {
      return (
        !!value?.addressCity ||
        i18n.t('form.validation.address_city') ||
        'Invalid city.'
      );
    });

    defineRule('isValidAddressInputCountry', (value: unknown) => {
      return (
        !!value?.addressCountry ||
        i18n.t('form.validation.address_country') ||
        'Invalid country.'
      );
    });

    defineRule('isValidAddressInputIndex', (value: unknown) => {
      return (
        !!value?.addressIndex ||
        i18n.t('form.validation.address_index') ||
        'Invalid index.'
      );
    });

    // Not Used Cyrillic
    defineRule('isNotUsedCyrillic', (value: string) => {
      if (!value) return true;
      const standardCyrillic =
        'АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯЁабвгдежзийклмнопрстуфхцчшщъыьэюяё';
      const extendedCyrillic = 'ЀЁЂЃЄЅІЇЈЉЊЋЌЍЎЏѐёђѓєѕіїјљњћќѝўџ';
      const cyrillic = standardCyrillic + extendedCyrillic;
      const cyrillicRegex = new RegExp(`^[^${cyrillic}]+$`);
      return (
        cyrillicRegex.test(value) ||
        i18n.t('form.validation.only_latin') ||
        'Only Latin characters are allowed.'
      );
    });

    // Valid Address Input with Apartment Number
    defineRule('isValidAddressInputWithApartmentNr', (value: unknown) => {
      if (value.addressApartmentNumber && value.hasApartmentNumber) {
        return true;
      } else if (!value.addressApartmentNumber && value.hasApartmentNumber === false) {
        return true;
      }
      return (
        i18n.t('form.validation.address_apartment_number') ||
        'Invalid apartment number configuration.'
      );
    });

    defineRule(
      'isValidHasApartmentAndApartmentNumber',
      (value: unknown, params: { hasApartmentNumber: string | boolean }) => {
        const { hasApartmentNumber } = params;
        if (value && hasApartmentNumber) {
          return true;
        } else if (!value && hasApartmentNumber === false) {
          return true;
        }
        return (
          i18n.t('form.validation.address_apartment_number') ||
          'Invalid apartment number configuration.'
        );
      },
    );

    // Select Value Is Number
    defineRule('selectValueIsNumber', (value: unknown) => {
      if (
        Number.isFinite(parseInt(value)) ||
        (value && typeof value.value === 'number')
      ) {
        return true;
      }
      return 'The selected value must be a number.';
    });

    // Floors Total Validation
    defineRule('floorsTotalValidation', (value: unknown, [floor]: [unknown]) => {
      if (
        (Number(value) >= 1 || Number(value?.value) >= 1) &&
        (Number(value) >= Number(floor) || Number(value?.value) >= Number(floor?.value))
      ) {
        return true;
      }
      return 'Floor number must be valid.';
    });

    // Date in Future
    defineRule('isDateInFuture', (value: unknown) => {
      if (value) {
        const today = new Date().setHours(0, 0, 0, 0);
        const date = new Date(value).setHours(0, 0, 0, 0);
        return date >= today || 'The date must be in the future.';
      }
      return 'Invalid date.';
    });

    // Date Between
    defineRule('isDateBetween', (value: unknown, [minDate, maxDate]: [Date, Date]) => {
      if (value && minDate && maxDate) {
        const date = new Date(value).valueOf();
        const min = new Date(minDate).valueOf();
        const max = new Date(maxDate).valueOf();
        return (
          (date >= min && date <= max) || 'Date must be between the specified range.'
        );
      }
      return 'Invalid date range.';
    });

    // Country ISO Validation
    defineRule('isCountryIso', (value: unknown) => {
      return countries.isValid(value) || 'Invalid country ISO code.';
    });

    // Float Validation
    defineRule('isFloat', (value) => {
      const floatValidationRegex = /^\d+(?:[\.,]\d{1,2})?$/;
      return floatValidationRegex.test(value) || 'The value must be a valid float.';
    });

    // Not Empty Option
    defineRule('isNotEmptyOption', (value: unknown) => {
      if (value && value?.value && value.label) {
        return true;
      }
      return 'This option cannot be empty.';
    });

    // Configure VeeValidate
    configure({
      generateMessage: (ctx) => {
        // You can customize the message generation here if needed
        return ctx.message || 'Invalid field.';
      },
      validateOnBlur: true, // Validate on blur
      validateOnChange: true, // Validate on change
      validateOnInput: false, // Validate on input
      validateOnModelUpdate: true, // Validate when the model updates
    });

    // Helper Functions
    const validatePLIdCode = (pesel: string) => {
      if (!pesel || pesel.length !== 11) return false;

      const arr = pesel.split('');
      let sum = 0;

      for (let i = 0; i < arr.length - 1; i++) {
        sum += +arr[i] * getMultiplier(i + 1);
      }

      const modulo = sum % 10;
      const lastD = Number(pesel.slice(-1));

      return (modulo === 0 && lastD === 0) || lastD === 10 - modulo;
    };

    const getMultiplier = (index: number) => {
      switch (index % 4) {
        case 1:
          return 1;
        case 2:
          return 3;
        case 3:
          return 7;
        case 0:
          return 9;
        default:
          throw new Error('Invalid index for multiplier calculation.');
      }
    };

    const validateEEIdCode = (code: string) => {
      const ik = new Isikukood(code);
      return ik.validate() || false;
    };
  },
});
