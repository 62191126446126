<template>
  <div class="flex flex-col gap-2 items-center">
    <client-only>
      <input
        :id="id"
        :accept="accept"
        class="absolute invisible"
        :name="id"
        type="file"
        @change="selectFile"
      />
      <label class="relative flex" :for="id" style="width: 200px; height: 200px">
        <label
          class="bg-white shadow-md absolute flex justify-center items-center p-3 rounded-full z-20 right-0 cursor-pointer"
          :for="id"
        >
          <r-icon class="text-3xl font-bold" icon-name="pen" />
        </label>
        <VeProgress
          :angle="90"
          class="absolute z-10"
          color="#FD5B01"
          :empty-thickness="0"
          hide-legend
          line="round"
          :progress="profileCompletionPercentage"
          :size="200"
          :thickness="6"
        />
        <r-image
          class="bg-white"
          elevated
          :loading="uploadingPicture"
          rounded
          :src="shareableProfileImage || '/images/no-profile-picture.png'"
        />
      </label>

      <div v-if="showMinSizeError && minImageSizeError" class="my-2 text-error text-sm">
        {{ minImageSizeError }}
      </div>
      <div v-if="showMaxSizeError && maxFileSizeError" class="my-2 text-error text-sm">
        {{ maxFileSizeError }}
      </div>
    </client-only>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { useTenantProfileFormBase } from '~/composables/useTenantProfileFormBase.ts';
import { transformedCloudinaryImage } from '~/utils/transformCloudinary.js';
import { defineAsyncComponent } from 'vue';

const VeProgress = defineAsyncComponent({
  loader: async () => {
    const module = await import('vue-ellipse-progress');
    return module.VeProgress || module.default;
  },
  delay: 0,
});

/**
 * Image upload component with Cloudinary integration
 */
export default {
  name: 'TenantProfilePictureUpload',

  components: { VeProgress },
  props: {
    minImageSizeError: {
      type: String,
      default: 'Image minimum allewed size 375x500px',
    },
    maxFileSizeError: {
      type: String,
      default: 'Image should not be bigger than 10MB',
    },
    minImageHeight: {
      type: Number,
      default: 375,
    },
    minImageWidth: {
      type: Number,
      default: 500,
    },
    maxUploadFileSize: {
      type: Number,
      default: 10 * 1024 * 1024,
    },
    accept: {
      type: String,
      default: 'image/png,image/gif,image/jpeg,image/webp, ,.heic,.heif',
    },
    profileCompletionPercentage: {
      type: Number,
      required: false,
      default: 0,
    },
    tenantProfile: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ['submit'],

  async setup() {
    const { getAnalyticEventNames, trackTenantProfileEvent } =
      useTenantProfileFormBase();

    return {
      getAnalyticEventNames,
      trackTenantProfileEvent,
      transformedCloudinaryImage,
    };
  },

  data() {
    return {
      shareableProfileImage: null,
      id: null,
      showMinSizeError: false,
      showMaxSizeError: false,
      uploadingPicture: false,
    };
  },
  computed: {
    ...mapGetters({
      isENVIsProduction: 'isENVIsProduction',
    }),
  },
  watch: {
    tenantProfile() {
      if (this.tenantProfile) {
        this.initialize(this.tenantProfile);
      }
    },
  },
  beforeMount() {
    this.id = uuidv4();
    if (this.tenantProfile) {
      this.initialize(this.tenantProfile);
    }
  },
  methods: {
    async selectFile(e) {
      this.showMinSizeError = false;
      this.showMaxSizeError = false;
      const config = useRuntimeConfig();
      const files = e.target.files;

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          /* Validate max file size before uploading */
          if (file.size < this.maxUploadFileSize) {
            this.uploadingPicture = true;
            this.shareableProfileImage = null;

            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', config.public.CLOUDINARY_UPLOAD_PRESET);

            try {
              const response = await fetch(
                `https://api.cloudinary.com/v1_1/${config.public.CLOUDINARY_CLOUD_NAME}/upload`,
                {
                  method: 'POST',
                  body: formData,
                },
              );
              const fileResponse = await response.json();
              /* Validate image width and height after response */
              if (
                fileResponse.height > this.minImageHeight &&
                fileResponse.width > this.minImageWidth
              ) {
                this.shareableProfileImage = this.replaceImageFileExtension(
                  fileResponse.secure_url,
                );
              } else {
                /* Show error if image width and height are too small */
                this.showMinSizeError = true;
              }

              this.uploadingPicture = false;
              this.$emit('submit', {
                shareableProfileImage: this.shareableProfileImage
                  ? this.shareableProfileImage
                  : undefined,
              });
              this.trackTenantProfileEvent({
                eventName:
                  this.getAnalyticEventNames.TENANT_PROFILE_EDIT_PROFILE_PICTURE,
              });
            } catch (error) {
              console.error('File upload error:', error);
            }
          } else {
            /* Show error if file is too large */
            this.showMaxSizeError = true;
          }
        }
      }
    },

    removeAt(idx) {
      this.imageUrls.splice(idx, 1);
    },

    /* For modifying .heic & .heif file extensions */
    replaceImageFileExtension(imgUrl) {
      if (
        imgUrl.substr(imgUrl.lastIndexOf('.'), imgUrl.length) === ('.heic' || '.heif')
      ) {
        return `${imgUrl.substr(0, imgUrl.lastIndexOf('.'))}.jpg`;
      } else {
        return imgUrl;
      }
    },

    initialize(tenantProfile) {
      this.shareableProfileImage = tenantProfile.shareableProfileImage;
    },
  },
};
</script>
