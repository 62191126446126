<template>
  <div class="digibroker">
    <DigibrokerNav
      v-if="digibrokerNav"
      :key="digibrokerNav._uid"
      :back-button-active="
        currentStepIndex !== 0 && currentStep !== digibrokerSteps.SUBMIT_ADVERTISEMENT
      "
      :blok="digibrokerNav"
      :current-progress-percentage="currentProgressPercentage"
      :save-button-active="currentStepIndex >= 2"
      @click-back="resetAndGoToPreviousStep"
      @click-save-and-exit="saveAndGoToPreviousPage"
    />
    <client-only>
      <template #placeholder>
        <r-loader class="py-28" type="animatedLogo" />
      </template>
      <!--  Other subcomponents will portal here  -->
      <div class="pt-9 pb-5 sm:py-5">
        <div class="flex justify-center items-center h-1/2">
          <div v-if="currentStep === digibrokerSteps.DOES_ALREADY_HAVE_TENANT">
            <StepDoesAlreadyHaveTenant
              :ref="digibrokerSteps.DOES_ALREADY_HAVE_TENANT"
              :already-has-tenant="alreadyHasTenant"
              :initial-load="true"
              :intro-text="blok.IntoText"
              :tabs="blok.hasTenantSelectTabs"
              @select-has-tenant="selectHasTenant"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_TYPE_OF_PROPERTY"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectTypeOfProperty
              :ref="digibrokerSteps.SELECT_TYPE_OF_PROPERTY"
              :initial-load="
                !hasStepBeenVisited(digibrokerSteps.SELECT_TYPE_OF_PROPERTY)
              "
              :intro-text="blok.adIntro"
              :property-type="rawFormData.propertyType"
              :request-in-progress="requestInProgress"
              :tabs="blok.propertyTypeTabs"
              @select-property-type="onSelectPropertyType"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_ADDRESS"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectAddress
              :ref="digibrokerSteps.SELECT_ADDRESS"
              v-model="rawFormData.address"
              :address-apartment-disclaimer="blok.addressApartmentDisclaimer"
              :address-instruction="blok.addressInstruction"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_ADDRESS)"
              :intro-text="blok.address"
              :is-agreement-address-search="alreadyHasTenant"
              :next-button-id="blok.inputAddressNextButtonId"
              :next-button-label="blok.inputNextButtonText"
              :previous-button-id="blok.inputAddressPreviousButtonId"
              :request-in-progress="requestInProgress"
              :source="source"
              :submit-button-id="blok.addressConfirmButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_FLOORS"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectFloors
              :ref="digibrokerSteps.SELECT_FLOORS"
              v-model:floor="rawFormData.floor"
              v-model:floors-total="rawFormData.floorsTotal"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_FLOORS)"
              :intro-text="blok.floor"
              :is-mobile-screen="isMobileScreen"
              :next-button-id="blok.inputFloorNextButtonId"
              :next-button-label="blok.inputNextButtonText"
              :previous-button-id="blok.inputFloorPreviousButtonId"
              :request-in-progress="requestInProgress"
              :submit-button-id="blok.floorConfirmButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_AREA"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectArea
              :ref="digibrokerSteps.SELECT_AREA"
              v-model:object-area="rawFormData.objectArea"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_AREA)"
              :intro-text="blok.area"
              :next-button-id="blok.inputAreaNextButtonId"
              :next-button-label="blok.inputNextButtonText"
              :previous-button-id="blok.inputAreaPreviousButtonId"
              :request-in-progress="requestInProgress"
              :submit-button-id="blok.areaConfirmButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_ROOMS"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectRooms
              :ref="digibrokerSteps.SELECT_ROOMS"
              v-model:number-of-rooms="rawFormData.numberOfRooms"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_ROOMS)"
              :intro-text="blok.rooms"
              :next-button-id="blok.inputRoomNextButtonId"
              :next-button-label="blok.inputNextButtonText"
              :previous-button-id="blok.inputRoomPreviousButtonId"
              :request-in-progress="requestInProgress"
              :submit-button-id="blok.roomConfirmButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_ADDITIONAL_DETAILS"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectAdditionalDetails
              :ref="digibrokerSteps.SELECT_ADDITIONAL_DETAILS"
              v-model:has-parking="rawFormData.hasParking"
              v-model:has-storage="rawFormData.hasStorage"
              v-model:pets-allowed="rawFormData.petsAllowed"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="
                !hasStepBeenVisited(digibrokerSteps.SELECT_ADDITIONAL_DETAILS)
              "
              :intro-text="blok.additional"
              :next-button-id="blok.inputAdNextButtonId"
              :next-button-label="blok.inputNextButtonText"
              :previous-button-id="blok.inputAdPreviousButtonId"
              :request-in-progress="requestInProgress"
              :submit-button-id="blok.adNextButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_DESCRIPTION"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectDescription
              :ref="digibrokerSteps.SELECT_DESCRIPTION"
              v-model:description="rawFormData.description"
              :description-max-length="blok.descriptionMaxLength"
              :description-text="blok.descriptionContent"
              :description-triggers-regex="descriptionTriggersRegex"
              :go-to-next-step-cta="blok.inputNextEnterText"
              highlight-triggers-words
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_DESCRIPTION)"
              :intro-text="blok.descriptionIntro"
              :modal-content="blok.descriptionModal"
              :modal-label="blok.descriptionHelpModalLabel"
              :next-button-id="blok.descriptionNextButtonId"
              :next-button-label="blok.descriptionNextButtonText"
              :previous-button-id="blok.inputAreaPreviousButtonId"
              :request-in-progress="requestInProgress"
              :submit-button-id="blok.descriptionNextButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_RENT_RATE"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectRentRate
              :ref="digibrokerSteps.SELECT_RENT_RATE"
              v-model:additional-fee="additionalFee"
              v-model:insurance-invoice-to="rawFormData.insuranceInvoiceTo"
              v-model:rent-amount="rawFormData.rentAmount"
              :additional-fee-content="blok.additionalFeeContent"
              :additional-fee-modal-contents="blok.additionalFeeModal"
              :additional-fee-title="blok.additionalFeeTitle"
              :country="country"
              :currency-label="currencyLabel"
              :enable-additional-fees="country.toUpperCase() === workflow.POLAND"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_RENT_RATE)"
              :insurance-invoice-to-content="blok.insuranceInvoiceToContent"
              :insurance-invoice-to-modal-contents="blok.insuranceInvoiceToModal"
              :insurance-invoice-to-title="blok.insuranceInvoiceToTitle"
              :intro-text="blok.rentIntro"
              :next-button-id="blok.rentNextButtonId"
              :next-button-label="blok.inputNextButtonText"
              previous-button-id=""
              :rendin-fee-modal-contents="rendinFeeModalContents"
              :rendin-fee-prefix="blok.rendinFeePrefix"
              :rendin-fee-sufix="blok.rendinFeeSufix"
              :request-in-progress="requestInProgress"
              :submit-button-id="blok.rentNextButtonId"
              @go-to-next-step="submitAndGoToNextStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SESSION_FLOW"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSessionFlow
              :ref="digibrokerSteps.SESSION_FLOW"
              :new-profile-more-info="blok.Login[0].newProfileMoreInfo"
              :profile-login-intro="blok.Login[0].profileLoginIntro"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_IMAGES"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectImages
              :ref="digibrokerSteps.SELECT_IMAGES"
              v-model:images="rawFormData.images"
              :button-id-finalize-property="blok.propertyFinalUpdateButtonId"
              :button-id-preview-property="blok.propertyPreviewButtonId"
              :button-label-add-photo="blok.photosUploadAddPhotoButton"
              :button-label-browse-photos="blok.photosUploadBrowseButton"
              :button-label-finalize-property="blok.propertyFinalUpdateButtonText"
              :button-label-open-photo="blok.photosUploadOpenPhotoLabel"
              :button-label-preview-property="blok.propertyPreviewButtonText"
              :button-label-upload-photos="blok.photosUploadBrowseLabel"
              :file-size-error-text="blok.photosMaxFileSizeError"
              :file-size-max="blok.photosMaxUploadFileSize"
              :image-instruction="blok.photoInstruction"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_IMAGES)"
              :intro-text="blok.photosIntro"
              :min-no-of-images="Number(blok.minNoOfImages)"
              :modal-help-content="blok.photosUploadHelpModal"
              :modal-help-label="blok.photosUploadHelpModalLabel"
              :photo-disclaimer-content="blok.photosDisclaimer"
              :photo-requirements-content="blok.photosRequirements"
              :photo-size-error-text="blok.photosMinImageSizeError"
              :photo-size-min-height="blok.photosMinImageHeight"
              :photo-size-min-width="blok.photosMinImageWidth"
              :request-in-progress="requestInProgress"
              @preview-advertisement="
                submitAndGoToStep(digibrokerSteps.PREVIEW_ADVERTISEMENT)
              "
              @submit-advertisement="
                submitAndGoToStep(digibrokerSteps.SUBMIT_ADVERTISEMENT)
              "
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.PREVIEW_ADVERTISEMENT"
            class="flex-grow max-w-xl min-h-1/2-screen"
          >
            <StepPreviewAdvertisement
              :ref="digibrokerSteps.PREVIEW_ADVERTISEMENT"
              :additional-fees="additionalFee.amount ? [additionalFee] : null"
              :button-id-back-to-edit="blok.propertyBackToEditButtonId"
              :button-id-finalize-advertisement="blok.propertyFinalUpdateButtonId"
              :button-label-back-to-edit="blok.propertyBackToEditButtonText"
              :button-label-finalize-advertisement="blok.propertyFinalUpdateButtonText"
              :country="country"
              :property="validatedData"
              :show-finalize-add-button="canFinalizeAdd"
              @finalize-add="submitAndGoToStep(digibrokerSteps.SUBMIT_ADVERTISEMENT)"
              @go-to-previous-step="resetAndGoToPreviousStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SUBMIT_ADVERTISEMENT"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSubmitAdvertisement
              :ref="digibrokerSteps.SUBMIT_ADVERTISEMENT"
              :loading-lines="[blok.loadingLine1, blok.loadingLine2, blok.loadingLine3]"
              @finalize-add="finalizeAdd()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SELECT_AGREEMENT_TYPE"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSelectAgreementType
              v-model:selected-agreement-type="rawFormData.agreementType"
              :country="country"
              :go-to-next-step-cta="blok.inputNextEnterText"
              :initial-load="!hasStepBeenVisited(digibrokerSteps.SELECT_AGREEMENT_TYPE)"
              :intro-text="blok.agreementTypeIntro"
              :next-button-label="blok.inputNextButtonText"
              :request-in-progress="requestInProgress"
              @auto-select="submitAndSkipStep()"
              @go-to-next-step="submitAndGoToNextStep()"
            />
          </div>
          <div
            v-else-if="currentStep === digibrokerSteps.SUBMIT_AGREEMENT"
            class="flex-grow max-w-3xl min-h-1/2-screen"
          >
            <StepSubmitAdvertisement
              :ref="digibrokerSteps.SUBMIT_AGREEMENT"
              :loading-lines="[blok.loadingLine1, blok.loadingLine2, blok.loadingLine3]"
              @finalize-add="finalizeAgreement()"
            />
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isNil } from 'lodash';
import { reactive } from 'vue';
import {
  additionalFeeInterval,
  additionalFeeType,
  adPropertyTypes,
  AdvertisementSourceType,
  digibrokerSteps,
  DigibrokerSuccessModalType,
  digibrokerTrackingEventNames,
  LocalStorageKeys,
  redirectLinks,
  sessionFlowSteps,
  userRole,
  webSourceType,
  workflow,
} from '~/utils/constants';
import StepSelectAgreementType from '~/components/storyblok/digibroker-steps/StepSelectAgreementType.vue';
import { validateAdvertisementDataForFinalize } from '~/utils/validateAdvertisementDataForFinalize';
import { depositRegexArray, shortTermRegexArray } from '~/utils/objectStructures';
import DigibrokerNav from '~/components/snippets/DigibrokerNav.vue';
import { useDigibrokerSteps } from '~/composables/useDigibrokerSteps.js';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

const defaultAddressObject = () => {
  return {
    addressFirstLine: null,
    addressCity: null,
    addressStreet: null,
    addressCountry: null,
    addressIndex: null,
    addressHouseNumber: null,
    addressHouseNumberHidden: false,
    addressApartmentNumber: null,
    hasApartmentNumber: true,
  };
};

const defaultListingObject = () => {
  return {
    agreementType: null,
    propertyType: '',
    address: defaultAddressObject(),
    floor: null,
    floorsTotal: null,
    objectArea: null,
    numberOfRooms: null,
    hasStorage: false,
    hasParking: false,
    petsAllowed: false,
    description: '',
    rentAmount: null,
    additionalFees: [],
    images: [],
    insuranceInvoiceTo: userRole.TENANT,
  };
};

export default {
  name: 'DigibrokerNext',
  components: { DigibrokerNav, StepSelectAgreementType },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const rawFormData = reactive(defaultListingObject());
    const validatedData = reactive(defaultListingObject());

    const {
      currentStep,
      currentStepIndex,
      currentProgressPercentage,
      hasStepBeenVisited,
      addStepVisited,
      goToStart,
      goToStep,
      goToPreviousStep,
      goToNextStep,
      visitedSteps,
      skipStep,
      steps,
    } = useDigibrokerSteps();

    const router = useRouter();
    const route = useRoute();

    return {
      steps,
      currentStep,
      currentStepIndex,
      currentProgressPercentage,
      visitedSteps,
      hasStepBeenVisited,
      addStepVisited,
      goToStart,
      goToNextStep,
      goToStep,
      goToPreviousStep,
      skipStep,
      rawFormData,
      validatedData,
      router,
      route,
    };
  },
  data() {
    return {
      digibrokerSteps: digibrokerSteps,
      workflow: workflow,
      alreadyHasTenant: null,
      advertisementFirebaseKey: null,
      agreementFirebaseKey: null,
      additionalFee: {
        type: additionalFeeType.FIXED_UTILITIES,
        paymentInterval: additionalFeeInterval.MONTHLY,
        source: webSourceType.WEB_DIGIBROKER,
        amount: null,
        lastPostAmount: null, // We want to track old amount to know, if we have to perform extra API operation
      },
      minLoadingTimePassed: false,
      requestInProgress: false,
      source: webSourceType.WEB_DIGIBROKER,
      isMobileScreen: false, // refactor this into a plugin
      descriptionTriggersRegex: [...depositRegexArray(), ...shortTermRegexArray()],
    };
  },
  computed: {
    ...mapGetters({
      country: 'getCountry',
      currencyLabel: 'getCurrencyLabel',
      postAdvertisementResponse: 'advertisements/postAdvertisementResponse',
      currentSessionStep: 'session/currentStep',
      hasSessionUser: 'users/hasSessionUser',
      getAnalyticEventProperties: 'tracker/getAnalyticEventProperties',
    }),
    canFinalizeAdd() {
      /* TODO - this validation is actually more complicated now, recheck */
      return validateAdvertisementDataForFinalize(this.validatedData).success;
    },
    digibrokerNav() {
      return Array.isArray(this.blok?.DigibrokerNav)
        ? this.blok.DigibrokerNav[0]
        : null;
    },
    currentSteps() {
      const steps = [];
      steps.push(digibrokerSteps.DOES_ALREADY_HAVE_TENANT);
      if (!this.alreadyHasTenant) {
        /* START OF Advertisement Assistant */
        steps.push(
          ...[digibrokerSteps.SELECT_TYPE_OF_PROPERTY, digibrokerSteps.SELECT_ADDRESS],
        );

        if (
          this.rawFormData.propertyType === adPropertyTypes.APARTMENT ||
          this.rawFormData.propertyType === adPropertyTypes.ROOM
        ) {
          steps.push(digibrokerSteps.SELECT_FLOORS);
        }

        steps.push(digibrokerSteps.SELECT_AREA);

        if (this.rawFormData.propertyType !== adPropertyTypes.ROOM) {
          steps.push(digibrokerSteps.SELECT_ROOMS);
        }

        steps.push(
          ...[
            digibrokerSteps.SELECT_ADDITIONAL_DETAILS,
            digibrokerSteps.SELECT_RENT_RATE,
          ],
        );

        if (this.currentSessionStep !== sessionFlowSteps.COMPLETED) {
          steps.push(digibrokerSteps.SESSION_FLOW);
        }

        steps.push(
          ...[
            digibrokerSteps.SELECT_DESCRIPTION,
            digibrokerSteps.SELECT_IMAGES,
            digibrokerSteps.PREVIEW_ADVERTISEMENT,
            digibrokerSteps.SUBMIT_ADVERTISEMENT,
          ],
        );
      } else {
        /* START OF Agreement Assistant */
        if (this.country === 'pl') {
          steps.push(digibrokerSteps.SELECT_AGREEMENT_TYPE);
        }

        steps.push(
          ...[
            digibrokerSteps.SELECT_ADDRESS,
            digibrokerSteps.SELECT_AREA,
            digibrokerSteps.SELECT_ROOMS,
            digibrokerSteps.SELECT_ADDITIONAL_DETAILS,
            digibrokerSteps.SELECT_RENT_RATE,
          ],
        );

        if (this.currentSessionStep !== sessionFlowSteps.COMPLETED) {
          steps.push(digibrokerSteps.SESSION_FLOW);
        }

        steps.push(digibrokerSteps.SUBMIT_AGREEMENT);
      }
      return steps;
    },
    isAdvertisementDraftReadyForUpsert() {
      const address = this.validatedData.address;

      return (
        address.addressCountry !== null &&
        address.addressCity != null &&
        address.addressStreet != null
      );
    },
    isAgreementDraftReadyForUpsert() {
      const address = this.validatedData.address;

      /* TODO - further validation */
      return (
        address.addressCountry !== null &&
        address.addressCity != null &&
        address.addressStreet != null
      );
    },
    rendinFeeModalContents() {
      // We have extra clause for room rental
      if (
        this.rawFormData.propertyType === adPropertyTypes.ROOM &&
        this.blok.rendinFeeModal &&
        this.blok.rendinFeeModalRoomAddon
      ) {
        return [...this.blok.rendinFeeModal, ...this.blok.rendinFeeModalRoomAddon];
      }
      return this.blok.rendinFeeModal;
    },
  },
  watch: {
    postAdvertisementResponse(value) {
      if (value) {
        Toastify({
          text: this.$t('draft_saved'),
          duration: 3000,
          gravity: 'top', // `top` or `bottom`
          backgroundColor: '#4caf50',
          className: 'text-white',
          position: 'center',
        }).showToast();
      }
    },
    currentSteps: {
      handler() {
        this.steps = this.currentSteps;
      },
      immediate: true,
    },
    currentStep(value, oldValue) {
      /* Default - event will be emitted on EXIT */
      const step = oldValue;
      this.trackDigibrokerStep(step, value);

      /* Last step will be emitted on LOAD */
      if (value === this.steps[this.steps.length - 1]) {
        this.trackDigibrokerStep(value, value);
      }

      /* Scroll to the start of current step */
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.scrollPageUp();
        });
      });
    },
  },
  created() {
    this.loadFromCache();
    this.setOverridenSuccessfulRedirect(this.$localizedPath(this.route.path));
    this.setSignInModalDisabledByOtherFlow();

    /* If step param is present, try to go directly to another step but leave data as is */
    if (this.route.query.start) {
      this.goToStart();
      this.router.replace({
        ...this.route,
        query: {
          ...this.route.query,
          start: undefined,
        },
      });
    }
    /* If step_listing param is present, go directly to SELECT_TYPE_OF_PROPERTY step, reset alreadyHasTenant and agreementFirebaseKey  */
    if (this.route.query.step_listing) {
      this.alreadyHasTenant = false;
      this.agreementFirebaseKey = null;
      this.goToStep(digibrokerSteps.SELECT_TYPE_OF_PROPERTY);

      this.router.replace({
        ...this.route,
        query: {
          ...this.route.query,
          step_listing: undefined,
        },
      });
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();

    if (this.alreadyHasTenant === null) {
      this.emitDigibrokerTrackerEvent(digibrokerTrackingEventNames._START_);
    }
  },
  beforeUnmount() {
    this.resetOverridenSuccesfulRedirect();
    this.resetSignInModalDisabledByOtherFlow();
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations({
      setOverridenSuccessfulRedirect: 'session/SET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
      resetOverridenSuccesfulRedirect: 'session/RESET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
      setSignInModalDisabledByOtherFlow:
        'session/SET_SIGN_IN_MODAL_DISABLED_BY_OTHER_FLOW',
      resetSignInModalDisabledByOtherFlow:
        'session/RESET_SIGN_IN_MODAL_DISABLED_BY_OTHER_FLOW',
    }),
    ...mapActions({
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      actionUpsertAdd: 'adds/upsertAdd',
      actionFinalizeAdd: 'adds/finalizeAdd',
      actionTrackDigibrokerEvent: 'tracker/trackDigibrokerEvent',
      actionSetSuccessModal: 'adds/setDigibrokerSuccessModal',
      actionGoToPreviousPage: 'routerHistory/goToPreviousPage',

      actionPostAdvertisementAdditionalFee:
        'advertisements/postAdvertisementAdditionalFee',
      actionPutAdvertisementAdditionalFee:
        'advertisements/putAdvertisementAdditionalFee',
      actionDeleteAdvertisementAdditionalFee:
        'advertisements/deleteAdvertisementAdditionalFee',

      actionPostAgreementAdditionalFee: 'agreements/postAgreementAdditionalFee',
      actionDeleteAgreementAdditionalFee: 'agreements/deleteAgreementAdditionalFee',

      actionPutAgreement: 'agreements/putAgreement',
      actionPostAgreement: 'agreements/postAgreement',
    }),
    upsertAdd() {
      this.requestInProgress = true;
      if (!this.hasSessionUser) {
        setTimeout(() => {
          this.requestInProgress = false;
        }, 500);
        return Promise.resolve();
      } else {
        return this.actionUpsertAdd({
          firebaseId: this.advertisementFirebaseKey,
          addData: { ...this.validatedData, ...this.validatedData.address },
          source: webSourceType.WEB_DIGIBROKER,
        })
          .then(
            (advertisementFirebaseKey) =>
              (this.advertisementFirebaseKey = advertisementFirebaseKey),
          )
          .then(async () => {
            if (!this.additionalFee.id && this.additionalFee.amount) {
              await this.postAdditionalFee().then((res) => {
                this.additionalFee.lastPostAmount = this.additionalFee.amount;
                this.additionalFee.id = res.response;
              });
            } else if (this.additionalFee.id && this.additionalFee.amount) {
              await this.putAdvertisementAdditionalFee();
              this.additionalFee.lastPostAmount = this.additionalFee.amount;
            } else if (this.additionalFee.id && !this.additionalFee.amount) {
              await this.deleteAdditionalFee().then(() => {
                this.additionalFee.id = null;
                this.additionalFee.lastPostAmount = null;
              });
            }
          })
          .then(() => {
            this.saveToCache();
          })
          .finally(() => (this.requestInProgress = false));
      }
    },
    async finalizeAdd() {
      await this.upsertAdd();

      return Promise.all([
        this.canFinalizeAdd
          ? this.actionFinalizeAdd({
              firebaseId: this.advertisementFirebaseKey,
              source: AdvertisementSourceType.WEB_DIGIBROKER,
            })
          : this.upsertAdd(),
        this.wait(7500),
      ]).then(() => {
        this.actionSetSuccessModal({
          visible: true,
          parent: webSourceType.WEB_DIGIBROKER,
          type: DigibrokerSuccessModalType.LISTING,
        });
        this.cleanCache();
        setTimeout(() => {
          this.$router.push({
            path: this.$localizedPath(
              `/dashboard/object/${this.advertisementFirebaseKey}`,
            ),
            query: { success: '1' },
          });
        }, 750);
      });
    },
    async upsertAgreement() {
      this.requestInProgress = true;

      if (!this.hasSessionUser) {
        // Fake loader for out-of-session users
        await this.wait(500);
      } else {
        const agreementData = {
          workflow: this.country.toUpperCase(),
          ...this.validatedData,
          ...this.validatedData.address,
        };
        delete agreementData.address;

        if (this.agreementFirebaseKey) {
          await this.actionPutAgreement({
            id: this.agreementFirebaseKey,
            agreement: agreementData,
            source: webSourceType.WEB_DIGIBROKER,
          });
        } else {
          const { response } = await this.actionPostAgreement({
            agreement: agreementData,
          });

          this.agreementFirebaseKey = response;
        }

        if (!this.additionalFee.id && this.additionalFee.amount) {
          const { response } = await this.postAdditionalFee();

          this.additionalFee.lastPostAmount = this.additionalFee.amount;

          this.additionalFee.id = response;
        } else if (
          this.additionalFee.id &&
          this.additionalFee.amount &&
          this.additionalFee.amount !== this.additionalFee.lastPostAmount
        ) {
          await this.deleteAdditionalFee();
          this.additionalFee.id = null;
          this.additionalFee.lastPostAmount = null;

          const { response } = await this.postAdditionalFee();
          this.additionalFee.id = response;
          this.additionalFee.lastPostAmount = this.additionalFee.amount;
        }
      }

      this.saveToCache();
      this.requestInProgress = false;
    },
    async finalizeAgreement() {
      // First check that we have uploaded up-to-date form

      /* We need to wait some arbitrary time since  */
      const agreementPromise = !this.requestInProgress
        ? this.upsertAgreement().then(() => this.wait(1000))
        : Promise.resolve();

      await Promise.all([agreementPromise, this.wait(7500)]);
      await this.actionSetSuccessModal({
        visible: true,
        parent: webSourceType.WEB_DIGIBROKER,
        type: DigibrokerSuccessModalType.AGREEMENT,
      });

      this.cleanCache();
      setTimeout(() => {
        this.$router.push({
          path: this.$localizedPath(
            `/dashboard/agreement/${this.agreementFirebaseKey}`,
          ),
          query: { success: '1' },
        });
      }, 750);
    },
    // Simply forces the async operation to wait for a set duration
    wait(duration) {
      return new Promise((resolve) => setTimeout(resolve, duration));
    },
    saveToCache() {
      if (import.meta.server) return;

      const cache = {
        currentStepIndex: this.currentStepIndex,
        advertisementFirebaseKey: this.advertisementFirebaseKey,
        agreementFirebaseKey: this.agreementFirebaseKey,
        validatedData: this.validatedData,
        visitedSteps: this.visitedSteps,
        additionalFee: this.additionalFee,
        alreadyHasTenant: this.alreadyHasTenant,
      };

      localStorage.setItem(LocalStorageKeys.DIGIBROKER_CACHE, JSON.stringify(cache));
    },
    loadFromCache() {
      if (import.meta.server) return;

      // TODO - fallback to cookies for backwards compatibility, can be removed in July 2023
      const cache = JSON.parse(localStorage.getItem(LocalStorageKeys.DIGIBROKER_CACHE));
      if (!cache) {
        return false;
      }

      if (!isNil(cache.advertisementFirebaseKey))
        this.advertisementFirebaseKey = cache.advertisementFirebaseKey;
      if (!isNil(cache.agreementFirebaseKey))
        this.agreementFirebaseKey = cache.agreementFirebaseKey;
      if (!isNil(cache.currentStepIndex))
        this.currentStepIndex = cache.currentStepIndex;
      if (!isNil(cache.alreadyHasTenant))
        this.alreadyHasTenant = cache.alreadyHasTenant;
      if (!isNil(cache.visitedSteps)) this.visitedSteps.push(...cache.visitedSteps);

      const parsedPropertyDetails = cache.validatedData;

      if (parsedPropertyDetails) {
        this.assignPrimitivesAndArrayToOtherObject(
          parsedPropertyDetails,
          this.validatedData,
        );
        this.assignPrimitivesAndArrayToOtherObject(
          parsedPropertyDetails.address,
          this.validatedData.address,
        );

        this.resetFormData();
      }

      const parsedAdditionalFee = cache.additionalFee;
      if (parsedAdditionalFee) {
        this.assignPrimitivesAndArrayToOtherObject(
          parsedAdditionalFee,
          this.additionalFee,
        );
      }
    },
    cleanCache() {
      localStorage.removeItem(LocalStorageKeys.DIGIBROKER_CACHE);
    },
    /* TODO - upsert add if possible when moving to next step */
    postAdditionalFee() {
      const data = {
        additionalFeeType: this.additionalFee.type,
        paymentInterval: this.additionalFee.paymentInterval,
        amount: this.additionalFee.amount,
        source: this.additionalFee.source,
      };

      if (this.alreadyHasTenant) {
        return this.actionPostAgreementAdditionalFee({
          agreementId: this.agreementFirebaseKey,
          type: data.additionalFeeType,
          source: data.source,
          paymentInterval: data.paymentInterval,
          amount: data.amount,
        });
      } else {
        return this.actionPostAdvertisementAdditionalFee({
          advertisementId: this.advertisementFirebaseKey,
          ...data,
        });
      }
    },
    putAdvertisementAdditionalFee() {
      return this.actionPutAdvertisementAdditionalFee({
        advertisementId: this.advertisementFirebaseKey,
        additionalFeeId: this.additionalFee.id,
        amount: this.additionalFee.amount,
        source: this.additionalFee.source,
      });
    },
    deleteAdditionalFee() {
      if (this.alreadyHasTenant) {
        return this.actionDeleteAgreementAdditionalFee({
          agreementId: this.agreementFirebaseKey,
          additionalFeeId: this.additionalFee.id,
        });
      } else {
        return this.actionDeleteAdvertisementAdditionalFee({
          advertisementId: this.advertisementFirebaseKey,
          additionalFeeId: this.additionalFee.id,
          source: this.additionalFee.source,
        });
      }
    },
    async saveAndGoToPreviousPage() {
      await this.validateCurrentStepAndSave();

      const inSessionUrl = this.alreadyHasTenant
        ? this.$localizedPath(redirectLinks.LANDLORD_AGREEMENTS)
        : this.$localizedPath(redirectLinks.LANDLORD_PROPERTIES);
      const url = this.hasSessionUser ? inSessionUrl : this.$localizedPath('/');
      this.actionGoToPreviousPage(url);
    },
    async validateCurrentStepAndSave() {
      const stepCanBeValidated =
        this.$refs[this.currentStep] &&
        typeof this.$refs[this.currentStep].validate === 'function';

      const isValid = stepCanBeValidated
        ? await this.$refs[this.currentStep].validate()
        : true;

      if (isValid) {
        this.saveFormData();
      }
    },
    selectHasTenant(alreadyHasTenant) {
      this.alreadyHasTenant = alreadyHasTenant;
      this.submitAndGoToNextStep();
    },
    async submitAndSkipStep() {
      await this.validateCurrentStepAndSave();
      this.skipStep();
    },
    async submitAndGoToNextStep() {
      await this.validateCurrentStepAndSave();
      this.goToNextStep();
    },
    async submitAndGoToStep(step) {
      await this.validateCurrentStepAndSave();
      this.goToStep(step);
    },
    resetAndGoToPreviousStep() {
      this.resetFormData();
      this.goToPreviousStep();
    },
    saveFormData() {
      this.assignPrimitivesAndArrayToOtherObject(this.rawFormData, this.validatedData);
      this.assignPrimitivesAndArrayToOtherObject(
        this.rawFormData.address,
        this.validatedData.address,
      );
      this.saveToCache();

      if (this.alreadyHasTenant === true && this.isAgreementDraftReadyForUpsert) {
        this.upsertAgreement();
      } else if (
        this.alreadyHasTenant === false &&
        this.isAdvertisementDraftReadyForUpsert
      ) {
        this.upsertAdd();
      }
    },
    resetFormData() {
      this.assignPrimitivesAndArrayToOtherObject(this.validatedData, this.rawFormData);
      this.assignPrimitivesAndArrayToOtherObject(
        this.validatedData.address,
        this.rawFormData.address,
      );
    },
    assignPrimitivesAndArrayToOtherObject(source, destination) {
      for (const [key, value] of Object.entries(source)) {
        /* Reactivity concern - we cannot assign objects directly because that would break reactivity so they have to be specially handled */
        if (typeof value === 'object' && !Array.isArray(value) && value !== null)
          continue;
        if (Array.isArray(value)) {
          destination[key] = [...value];
        } else {
          destination[key] = value;
        }
      }
    },
    trackDigibrokerStep(step, nextStep) {
      if (import.meta.client) {
        if (!this.hasStepBeenVisited(nextStep)) {
          const eventName = this.stepToEventName(step);
          if (eventName) {
            this.emitDigibrokerTrackerEvent(eventName, this.stepToEventName(nextStep));
          }
        }
      }
    },
    scrollPageUp() {
      if (import.meta.client) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
    /* stepName - {digibrokerSteps} constant */
    stepToEventName(stepName) {
      switch (stepName) {
        case digibrokerSteps._START_:
          return digibrokerTrackingEventNames._START_;
        case digibrokerSteps.DOES_ALREADY_HAVE_TENANT:
          if (this.alreadyHasTenant) {
            return digibrokerTrackingEventNames.HAS_TENANT;
          } else {
            return digibrokerTrackingEventNames.SEARCH_TENANT;
          }
        case digibrokerSteps.SELECT_TYPE_OF_PROPERTY:
          return digibrokerTrackingEventNames.SELECT_TYPE_OF_PROPERTY;
        case digibrokerSteps.SELECT_ADDRESS:
          return digibrokerTrackingEventNames.SELECT_ADDRESS;
        case digibrokerSteps.SELECT_FLOORS:
          return digibrokerTrackingEventNames.SELECT_FLOORS;
        case digibrokerSteps.SELECT_AREA:
          return digibrokerTrackingEventNames.SELECT_AREA;
        case digibrokerSteps.SELECT_ROOMS:
          return digibrokerTrackingEventNames.SELECT_ROOMS;
        case digibrokerSteps.SELECT_RENT_RATE:
          return digibrokerTrackingEventNames.SELECT_RENT_RATE;
        case digibrokerSteps.SELECT_ADDITIONAL_DETAILS:
          return digibrokerTrackingEventNames.SELECT_ADDITIONAL_DETAILS;
        case digibrokerSteps.SELECT_DESCRIPTION:
          return digibrokerTrackingEventNames.SELECT_DESCRIPTION;
        case digibrokerSteps.SELECT_IMAGES:
          return digibrokerTrackingEventNames.SELECT_IMAGES;
        case digibrokerSteps.PREVIEW_ADVERTISEMENT:
          return digibrokerTrackingEventNames.PREVIEW_AD;
        case digibrokerSteps.SUBMIT_ADVERTISEMENT:
          return digibrokerTrackingEventNames.FINALIZE_AD;
        case digibrokerSteps.SELECT_AGREEMENT_TYPE:
          return digibrokerTrackingEventNames.SELECT_AGREEMENT_TYPE;
        case digibrokerSteps.SUBMIT_AGREEMENT:
          return digibrokerTrackingEventNames.SUBMIT_AGREEMENT;
        default:
          return undefined;
      }
    },
    emitDigibrokerTrackerEvent(eventName, nextStep) {
      const eventProperties = {
        [this.getAnalyticEventProperties.STEP]: eventName,
        [this.getAnalyticEventProperties.NEXT_STEP]: nextStep,
        [this.getAnalyticEventProperties.PROPERTY_TYPE]:
          this.validatedData.propertyType,
        [this.getAnalyticEventProperties.DIGIBROKER_HAS_TENANT]: this.alreadyHasTenant,
        [this.getAnalyticEventProperties.ADVERTISEMENT_KEY]: this
          .advertisementFirebaseKey
          ? this.advertisementFirebaseKey
          : null,
        [this.getAnalyticEventProperties.AGREEMENT_KEY]: this.agreementFirebaseKey
          ? this.agreementFirebaseKey
          : null,
      };

      this.actionTrackDigibrokerEvent({
        eventName: eventName,
        props: eventProperties,
      });
    },
    onResize() {
      const width = window.innerWidth;

      if (width > 767) {
        this.isMobileScreen = false;
      } else {
        this.isMobileScreen = true;
      }
    },
    onSelectPropertyType(type) {
      this.rawFormData.propertyType = type;

      if (type === adPropertyTypes.ROOM) {
        this.rawFormData.numberOfRooms = 1;
      }

      this.submitAndGoToNextStep();
    },
  },
};
</script>
<style lang="scss">
.digibroker {
  &__tab {
    svg path {
      fill: black;
    }

    &--active,
    &:hover,
    &:focus {
      svg path {
        fill: #fd5b01;
      }
    }
  }

  .r-fade {
    opacity: 0;
    animation-duration: 1s;
    animation-name: fade-in;
    animation-fill-mode: forwards;
    animation-timing-function: ease;

    &--delay {
      &--1 {
        animation-delay: 1s;
      }

      &--2 {
        animation-delay: 2s;
      }

      &--3 {
        animation-delay: 3s;
      }

      &--4 {
        animation-delay: 4s;
      }

      &--5 {
        animation-delay: 5s;
      }

      &--6 {
        animation-delay: 6s;
      }

      &--7 {
        animation-delay: 7s;
      }

      &--8 {
        animation-delay: 8s;
      }
    }
  }

  .r-fade-bottom-start {
    opacity: 0;
  }

  .r-fade-bottom {
    opacity: 0;
    animation-duration: 1s;
    animation-name: fade-bottom;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-bottom {
    from {
      opacity: 0;
      transform: translateY(2rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
