<template>
  <r-overlay size="small" :visible="showModal" @close="closeOverlay()">
    <section class="background-check-info-modal px-4 pt-10 pb-4">
      <h1 class="font-medium text-xl">
        {{ $t('agreement.background_check_info_modal.title') }}
      </h1>
      <p class="text-gray-600 mt-2 mb-4">
        {{ $t('agreement.background_check_info_modal.description.' + $getCountry()) }}
      </p>
      <r-accordion-group
        :active-index="accordionGroupActiveIndex"
        flavor="flat"
        :value="terms"
        @switch="(index) => openTerm(index)"
      >
        <template #approved>
          <div class="break-words whitespace-pre-line text-sm">
            {{
              $t(
                'agreement.background_check_info_modal.approved.description.' +
                  $getCountry(),
              )
            }}
          </div>
        </template>

        <template #pending>
          <div class="break-words whitespace-pre-line text-sm">
            {{ $t('agreement.background_check_info_modal.pending.description') }}
          </div>
        </template>
        <template #declined>
          <div class="break-words whitespace-pre-line text-sm">
            {{ $t('agreement.background_check_info_modal.declined.description') }}
          </div>
        </template>
      </r-accordion-group>
    </section>
  </r-overlay>
</template>
<script>
import { mapActions } from 'vuex';
import {
  trackingProcessNames,
  agreementTrackingEventNames,
} from '~/utils/trackerConstants';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BackgroundCheckInfoModal',

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
  },
  emits: ['closeOverlay'],

  data() {
    return {
      accordionGroupActiveIndex: -1,
      previousAccordionGroupActiveIndex: null,
      trackingProcessNames: trackingProcessNames,
      agreementTrackingEventNames: agreementTrackingEventNames,
    };
  },

  computed: {
    terms() {
      return [
        {
          id: 'approved',
          icon: 'user-shield',
          iconClass: 'text-green-500 text-xl',
          title: this.$t('agreement.background_check_info_modal.approved.title'),
        },
        {
          id: 'pending',
          icon: 'file-check',
          iconClass: 'text-yellow-500 text-xl',
          title: this.$t('agreement.background_check_info_modal.pending.title'),
        },
        {
          id: 'declined',
          icon: 'user-large-slash',
          iconClass: 'text-error text-xl',
          title: this.$t('agreement.background_check_info_modal.declined.title'),
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
    }),

    closeOverlay() {
      this.$emit('closeOverlay');
    },

    openTerm(index) {
      this.accordionGroupActiveIndex = index;
      if (index > 0) this.previousAccordionGroupActiveIndex = index;

      let termName = '';
      if (this.accordionGroupActiveIndex === -1) {
        if (this.previousAccordionGroupActiveIndex > 0) {
          termName = this.terms[this.previousAccordionGroupActiveIndex].id;
        } else {
          termName = 'unknown';
        }
      } else {
        termName = this.terms[this.accordionGroupActiveIndex].id;
      }

      const properties = {
        action: this.accordionGroupActiveIndex === -1 ? 'Close' : 'Open',
        termName: termName,
      };

      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.INSPECT_BACKGROUND_CHECK_INFO,
        properties,
      );
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },
  },
});
</script>
