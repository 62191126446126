<template>
  <div
    :id="blok.id"
    class="py-6 px-4 sm:py-12"
    :class="[backgroundColors[blok.backgroundColor]]"
  >
    <div v-if="blok?.preCaseStudyContent?.length > 0" class="flex justify-center">
      <div class="flex flex-col md:w-6/12 pb-12">
        <component
          :is="blok.component"
          v-for="blok in blok.preCaseStudyContent.filter((item) =>
            ['RichTextBlok', 'ButtonBlok', 'IconBlok'].includes(item.component),
          )"
          :key="blok._uid"
          :blok="blok"
        />
      </div>
    </div>
    <div
      v-if="blok.highLightedCaseStudy && highLightedCaseStudy"
      class="bg-white mx-auto max-w-6xl grid md:grid-cols-2 md:gap-6 p-4 rounded-lg"
    >
      <nuxt-link
        class="cursor-pointer"
        :to="$localizedPath(highLightedCaseStudy.full_slug)"
      >
        <div
          v-if="highLightedCaseStudy.content.image.filename"
          class="h-80 w-full bg-gray-100 bg-no-repeat bg-cover rounded-lg"
          :style="{
            'background-image':
              'url(' +
              $transformStoryblokImage(
                highLightedCaseStudy.content.image.filename,
                '/m/730x0',
              ) +
              ')',
          }"
        ></div>
      </nuxt-link>
      <div>
        <nuxt-link
          class="cursor-pointer"
          :to="$localizedPath(highLightedCaseStudy.full_slug)"
        >
          <h2>
            <RichText
              v-if="highLightedCaseStudy.content.title"
              :blok="highLightedCaseStudy.content.title"
              class="text-xl my-4"
              plain
            />
          </h2>
        </nuxt-link>
        <div
          v-if="highLightedCaseStudy?.content?.caseStudyCardTags?.length > 0"
          class="flex flex-wrap gap-1"
        >
          <div
            v-for="tag in highLightedCaseStudy.content.caseStudyCardTags"
            :key="tag._uid"
            class="bg-peach-500 p-1.5 rounded-lg text-xs"
          >
            <RichText
              v-if="tag.richTextContent"
              :blok="tag.richTextContent"
              class="case-study__tag"
            />
          </div>
        </div>
        <div class="text-gray-600">
          <RichText
            v-if="highLightedCaseStudy.content.intro"
            :blok="highLightedCaseStudy.content.intro"
          />
        </div>
        <r-button
          icon="arrow-right-long"
          icon-class="ml-2"
          icon-position-after
          :label="$t('read_more')"
          size="small"
          :to="$localizedPath(highLightedCaseStudy.full_slug)"
          width="wide"
        />
      </div>
    </div>
    <div class="flex justify-center pt-9">
      <component
        :is="blok.component"
        v-for="blok in blok.addNewButton.filter((item) =>
          ['ButtonBlok'].includes(item.component),
        )"
        :key="blok._uid"
        :blok="blok"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { backgroundColors } from '~/utils/constants';

export default {
  name: 'HighLightedCaseStudy',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  async setup() {
    const storyblokApi = useStoryblokApi();

    return {
      storyblokApi,
    };
  },
  data() {
    return {
      highLightedCaseStudy: null,
      backgroundColors: backgroundColors,
    };
  },
  async fetch() {
    const options = {
      version: this.$config.public.STORYBLOK_VERSION,
      language: this.getLocale,
      starts_with: `${this.$getCountry() === 'pl' ? 'poland' : 'estonia'}/case-studies/`,
      is_startpage: 0,
      page: 1,
      content_type: 'CaseStudy',
    };

    await this.storyblokApi.get(`cdn/stories`, options).then((response) => {
      this.filterArray(response.data.stories);
    });
  },

  computed: {
    ...mapGetters({
      getLocale: 'getLocale',
    }),
  },

  methods: {
    filterArray(data) {
      data.forEach((caseStudy) => {
        if (caseStudy.uuid === this.blok.highLightedCaseStudy) {
          this.highLightedCaseStudy = caseStudy;
        }
      });
    },
  },
};
</script>

<style scoped></style>
