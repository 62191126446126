<template>
  <section class="agreement-edit-view-parties">
    <div class="bg-white rounded-lg shadow-lg pt-4 pb-6 px-4 md:px-10">
      <div class="text-rendin-700 uppercase font-medium my-4">
        {{ $t('agreement.label.agreement_parties') }}
      </div>

      <div class="grid md:grid-cols-2 gap-4">
        <!--    Landlord -->
        <div class="bg-peach-100 rounded-lg">
          <div v-if="agreementFromFirebase?.landlord" class="flex flex-col h-full">
            <div class="flex-grow">
              <r-profile-info-card
                :email="
                  showLandlordProfileChangingButton
                    ? ''
                    : agreementFromFirebase?.landlord?.email
                "
                :email-label="$t('agreement.card.label.email')"
                :full-name="constructAgreementName(agreementFromFirebase?.landlord)"
                :full-name-label="$t('agreement.card.label.name')"
                :phone-number="
                  showLandlordProfileChangingButton
                    ? ''
                    : constructPhoneNr(
                        agreementFromFirebase.landlord.phoneCountryCode,
                        agreementFromFirebase.landlord.phoneNumber,
                      )
                "
                :phone-number-label="$t('agreement.card.label.phone')"
                :title="$t('agreement.card.label.landlord')"
                title-class="text-sm"
              />
              <div
                v-if="showLandlordErrors"
                class="p-2 text-sm bg-error-secondary rounded-lg mx-4 mb-4"
              >
                <!-- Landlords errors -->
                <span v-if="landlordPersonalIdCodeMissing">{{
                  landlordPersonalIdCodeMissing
                }}</span>
                <span v-if="landlordProfileIncomplete">{{
                  landlordProfileIncomplete
                }}</span>
                <span v-if="landlordProfileIncorrectForPlNo">{{
                  landlordProfileIncorrectForPlNo
                }}</span>
                <span v-if="landlordProfileIncorrectForPlNi">{{
                  landlordProfileIncorrectForPlNi
                }}</span>
                <span v-if="landlordProfileIncompleteMissingCompany">
                  {{ landlordProfileIncompleteMissingCompany }}</span
                >
                <span v-if="landlordProfileIncompleteMissingAddress">
                  {{ landlordProfileIncompleteMissingAddress }}</span
                >

                <!-- Change profile modal-->
                <r-button
                  v-if="showLandlordProfileChangingButton"
                  :label="$t('buttons.complete_your_profile_to_proceed')"
                  no-background
                  no-padding
                  @click="openProfileChangingModal()"
                />
              </div>
            </div>

            <div class="flex flex-wrap sm:flex-nowrap px-4 pb-4 gap-2">
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :label="$t('agreement.button.change_landlord')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="openAddLandlordView()"
              />
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :is-loading="landlordToRemoveInProgress"
                :label="$t('agreement.button.remove')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="removeLandlord()"
              />
            </div>
          </div>

          <div v-else class="p-4 flex flex-col h-full text-center">
            <div class="flex-grow my-4">
              <div class="font-medium">
                {{ $t('agreement.dialog.add_landlord.title') }}
              </div>
              <div class="text-sm text-gray-600 my-4">
                {{ $t('agreement.dialog.add_landlord.content') }}
              </div>
            </div>

            <div class="flex flex-wrap sm:flex-nowrap gap-2">
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :is-loading="landlordInfoInProgress"
                :label="$t('agreement.button.add_yourself')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="addLandlord(profileId)"
              />
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :label="$t('agreement.button.add_someone_else')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="openAddLandlordView(true)"
              />
            </div>
          </div>
        </div>

        <!--    Tenant -->
        <div class="bg-peach-100 rounded-lg">
          <div v-if="agreementFromFirebase?.tenant" class="flex flex-col h-full">
            <div class="flex-grow">
              <r-profile-info-card
                :email="
                  agreementFromFirebase?.tenant?.scoreStatus ===
                    BackgroundCheckResult.APPROVED &&
                  !showTenantErrors &&
                  agreementFromFirebase?.tenant?.email
                    ? agreementFromFirebase.tenant.email
                    : ''
                "
                :email-label="$t('agreement.card.label.email')"
                :full-name="constructAgreementName(agreementFromFirebase?.tenant)"
                :full-name-label="$t('agreement.card.label.name')"
                :phone-number="
                  agreementFromFirebase?.tenant?.scoreStatus ===
                    BackgroundCheckResult.APPROVED && !showTenantErrors
                    ? constructPhoneNr(
                        agreementFromFirebase.tenant.phoneCountryCode,
                        agreementFromFirebase.tenant.phoneNumber,
                      )
                    : ''
                "
                :phone-number-label="$t('agreement.card.label.phone')"
                :title="$t('agreement.card.label.tenant')"
                title-class="text-sm"
              />
              <div
                v-if="
                  agreementFromFirebase?.tenant?.scoreStatus !==
                    BackgroundCheckResult.APPROVED || showTenantErrors
                "
                class="p-2 text-sm bg-error-secondary rounded-lg mx-4 mb-4"
              >
                <div v-if="showTenantErrors">
                  <!-- Tenant errors -->
                  <div v-if="tenantProfileIncomplete">
                    {{ tenantProfileIncomplete }}
                  </div>
                  <div v-if="tenantProfileBusinessError">
                    {{ tenantProfileBusinessError }}
                  </div>
                  <div v-if="tenantPersonalIdCodeMissing">
                    {{ tenantPersonalIdCodeMissing }}
                  </div>
                </div>

                <!-- Tenant background check errors -->
                <div
                  v-if="
                    agreementFromFirebase?.tenant?.scoreStatus !==
                    BackgroundCheckResult.APPROVED
                  "
                >
                  <span
                    v-if="
                      agreementFromFirebase?.tenant?.scoreStatus ===
                      BackgroundCheckResult.DECLINED
                    "
                    >{{ $t('agreement.error.tenant_background_check_declined') }}</span
                  >
                  <span
                    v-if="
                      agreementFromFirebase?.tenant?.scoreStatus ===
                      BackgroundCheckResult.PENDING
                    "
                    >{{ $t('agreement.error.tenant_background_check_pending') }}</span
                  >

                  <r-button
                    :label="$t('read_more')"
                    no-background
                    no-padding
                    @click="openBackgroundCheckInfoModal()"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-wrap sm:flex-nowrap px-4 pb-4 gap-2">
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :is-loading="tenantInfoInProgress"
                :label="$t('agreement.button.change_tenant')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="openAddTenantView()"
              />
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :is-loading="tenantToRemoveInProgress"
                :label="$t('agreement.button.remove')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="removeTenant()"
              />
            </div>
          </div>

          <div v-else class="p-4 flex flex-col h-full text-center">
            <div class="flex-grow my-4">
              <div class="font-medium">
                {{ $t('agreement.dialog.add_tenant.title') }}
              </div>
              <div class="text-sm text-gray-600 mt-4">
                {{ $t('agreement.dialog.add_tenant.content') }}
              </div>
            </div>

            <div class="flex flex-wrap sm:flex-nowrap gap-2">
              <r-button
                border-color="light-gray"
                color="black"
                inverted
                :is-loading="tenantInfoInProgress"
                :label="addTenantLabel"
                label-class="font-normal"
                size="small"
                width="full"
                @click="openAddTenantView()"
              />
              <r-button
                border-color="light-gray"
                color="black"
                icon="link"
                icon-class="ml-2"
                icon-position-after
                inverted
                :label="$t('agreement.button.send_invite')"
                label-class="font-normal"
                size="small"
                width="full"
                @click="openSharingModal('Add tenant')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--   coTenants -->
    <div class="bg-white rounded-lg shadow-lg pt-4 pb-6 px-4 md:px-10 my-2">
      <div class="text-rendin-700 uppercase font-medium my-4">
        {{ $t('agreement.label.cotenants') }}
      </div>

      <div class="flex flex-col sm:flex-row gap-4 justify-between items-center my-4">
        <div class="text-sm text-gray-600 flex-shrink">
          {{ $t('agreement.disclaimer.add-cotenant_info') }}
        </div>
        <r-button
          border-color="light-gray"
          class="w-full sm:w-auto"
          color="black"
          icon="plus"
          icon-class="mr-2"
          inverted
          :is-loading="coTenantInfoInProgress"
          :label="$t('agreement.button.add_cotenant')"
          label-class="font-normal whitespace-nowrap"
          size="small"
          width="small"
          @click="coTenantInfoInProgress ? null : openAddCoTenantView()"
        />
      </div>

      <div
        v-for="(coTenant, index) in agreementFromFirebase.coTenants"
        :id="coTenant.profileId"
        :key="index"
        :ref="coTenant.profileId"
        class="my-4"
        :class="{ 'opacity-50': coTenantsToRemove.includes(coTenant.profileId) }"
      >
        <div class="p-3 bg-peach-100 rounded-lg">
          <div class="flex flex-wrap gap-2 justify-between items-center">
            <r-title-with-icon
              v-if="coTenant"
              icon="user"
              :label="$t('agreement.card.label.name')"
              :title="constructAgreementName(coTenant)"
            />
            <r-button
              border-color="light-gray"
              color="black"
              inverted
              :is-loading="coTenantsToRemove.includes(coTenant.profileId)"
              :label="$t('agreement.button.remove')"
              label-class="font-normal"
              size="small"
              width="small"
              @click="removeCoTenant(coTenant.profileId)"
            />
          </div>

          <!--    CoTenant profile error -->
          <div
            v-if="showCoTenantProfileError(coTenant)"
            class="p-2 text-sm bg-error-secondary rounded-lg mt-4"
          >
            {{ $t('agreement.error.incomplete_profile_coTenant') }}
          </div>
        </div>
      </div>
    </div>

    <!--    Add landlord modal-->
    <search-or-add-new-profile-modal
      :agreement-from-firebase="agreementFromFirebase"
      new-profile-type="LANDLORD"
      :show-modal="showAddLandlordModal"
      :start-from-first-search-view="showAddLandlordModalSearchView"
      @close-overlay="showAddLandlordModal = false"
      @selected-profile-id="(profileId) => addLandlord(profileId)"
    />

    <!--    Add tenant modal-->
    <LazyCandidacyListModal
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :invite-link="
        linkToInvite(
          agreementFromFirebase.invitationShortCodes[0],
          agreementFromFirebase.workflow,
        )
      "
      :opening-source="candidacyListModalOpenSrc"
      :show-modal="showAddTenantModal"
      @close-overlay="showAddTenantModal = false"
      @read-more-about-background-check="showBackgroundCheckForCandidacy()"
      @selected-profile-id="(profileId) => addTenant(profileId)"
      @share-link="
        [openSharingModal(candidacyListModalOpenSrc), (showAddTenantModal = false)]
      "
    />
    <!--    Add coTenant modal-->
    <SearchOrAddNewProfileModal
      :agreement-from-firebase="agreementFromFirebase"
      new-profile-type="COTENANT"
      :show-modal="showAddCoTenantView"
      @close-overlay="showAddCoTenantView = false"
      @selected-profile-id="(profileId) => addCoTenant(profileId)"
    />

    <!-- Sharing modal for Invite -->
    <RSharingModal
      :description="parseFullAddressFromAddressParameters(agreementFromFirebase)"
      :modal-button-text="$t('web_sharing_modal.copy_link')"
      :modal-description="$t('or')"
      :modal-title="$t('web_sharing_modal.invite_tenant_to_agreement')"
      :selected-networks="sharingModalNetworksForAgreement"
      :share-button-for-mobile="$t('buttons.share')"
      :sharing-instructions="
        $t('web_sharing_modal.invite_tenant_to_agreement_description')
      "
      :show-modal="sharingModalOpen"
      show-sharing-instructions-for-mobile
      :title="parseFullAddressFromAddressParameters(agreementFromFirebase)"
      :url="
        linkToInvite(
          agreementFromFirebase.invitationShortCodes[0],
          agreementFromFirebase.workflow,
        )
      "
      @close-overlay="sharingModalOpen = false"
      @copy-link-click="
        trackInviteLinkCopyEvent({
          objectType: trackingProcessNames.AGREEMENT_DRAFTING,
          openingLocation: sharingModalLocation,
          channel: 'Link',
        })
      "
      @custom-web-sharing-modal-open="
        trackInviteLinkOpenSharingEvent({
          objectType: trackingProcessNames.AGREEMENT_DRAFTING,
          openingLocation: sharingModalLocation,
          modalType: sharingModalTypes.DESKTOP_SHARE_MODAL,
        })
      "
      @native-web-sharing-modal-open="
        trackInviteLinkOpenSharingEvent({
          objectType: trackingProcessNames.AGREEMENT_DRAFTING,
          openingLocation: sharingModalLocation,
          modalType: sharingModalTypes.MOBILE_NATIVE_SHARE,
        })
      "
      @social-network-popup-open="
        (network) =>
          trackInviteLinkCopyEvent({
            objectType: trackingProcessNames.AGREEMENT_DRAFTING,
            openingLocation: sharingModalLocation,
            channel: network,
          })
      "
    />

    <!-- Profile changing modal for landlord -->
    <ProfileChangeModal
      v-if="showProfileChangeModal"
      :agreement-from-firebase="agreementFromFirebase"
      :agreement-id="agreementId"
      :show-modal="showProfileChangeModal"
      @close-overlay="closeProfileChangingModal()"
      @request-tabs-validation="requestTabsValidation()"
    />

    <!-- Background check info modal -->
    <BackgroundCheckInfoModal
      :show-modal="showBackgroundCheckModal"
      @close-overlay="closeBackgroundCheckInfoModal()"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector';
import SearchOrAddNewProfileModal from '~/components/section/agreement-components/SearchOrAddNewProfileModal';
import CandidacyListModal from '~/components/section/agreement-components/CandidacyListModal';
import ProfileChangeModal from '~/components/section/agreement-components/ProfileChangeModal';
import {
  BackgroundCheckResult,
  sharingModalTypes,
  agreementTypes,
  sharingModalNetworksForAgreement,
  redirectLinks,
} from '~/utils/constants';
import {
  agreementTrackingEventNames,
  trackingProcessNames,
} from '~/utils/trackerConstants';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';
import BackgroundCheckInfoModal from '~/components/section/agreement-components/BackgroundCheckInfoModal';
import { constructAgreementName } from '~/utils/agreementNameConstructor';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AgreementEditViewParties',
  components: {
    CandidacyListModal,
    SearchOrAddNewProfileModal,
    ProfileChangeModal,
    BackgroundCheckInfoModal,
  },
  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    validate: {
      type: Number,
      default: null,
    },
    validationExtraAttention: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'agreementPartiesFormValidation',
    'requestServerValidation',
    'requestTabsValidation',
  ],

  setup() {
    return {
      constructAgreementName,
      constructPhoneNr,
      parseFullAddressFromAddressParameters,
    };
  },

  data: () => ({
    candidates: null,
    showOverlay: false,
    showAddLandlordModal: false,
    showAddLandlordModalSearchView: false,
    showAddTenantModal: false,
    showAddCoTenantView: false,
    tenantInfoInProgress: false,
    landlordInfoInProgress: false,
    coTenantInfoInProgress: false,
    tenantToRemoveInProgress: null,
    landlordToRemoveInProgress: null,
    coTenantsToRemove: [],

    profileMatchError: null,
    tenantProfileBusinessError: null,
    tenantPersonalIdCodeMissing: null,
    landlordPersonalIdCodeMissing: null,
    tenantProfileIncomplete: null,
    landlordProfileIncomplete: null,
    landlordProfileIncorrectForPlNo: null,
    landlordProfileIncorrectForPlNi: null,
    landlordProfileIncompleteMissingCompany: null,
    landlordProfileIncompleteMissingAddress: null,
    BackgroundCheckResult: BackgroundCheckResult,
    getCandidatesData: null,
    trackingProcessNames: trackingProcessNames,
    agreementTrackingEventNames: agreementTrackingEventNames,
    candidacyListModalOpenSrc: null,
    sharingModalOpen: false,
    sharingModalLocation: null,
    sharingModalTypes: sharingModalTypes,
    showProfileChangeModal: false,
    sharingModalNetworksForAgreement,
    showBackgroundCheckModal: false,
    reopenAddTenantModal: false,
  }),

  computed: {
    ...mapGetters({
      getCountry: 'getCountry',
      isENVIsProduction: 'isENVIsProduction',
      profileId: 'users/profileId',
    }),

    isTenantAndLandlordSamePerson() {
      return (
        this.agreementFromFirebase.tenant &&
        this.agreementFromFirebase.landlord &&
        this.agreementFromFirebase.tenant.personalIdCode ===
          this.agreementFromFirebase.landlord.personalIdCode
      );
    },
    isTenantProfileTypeBusiness() {
      return (
        this.agreementFromFirebase.tenant &&
        this.agreementFromFirebase.tenant.type === 'BUSINESS'
      );
    },
    isTenantScoreStatusNotApproved() {
      return (
        this.agreementFromFirebase.tenant &&
        this.agreementFromFirebase.tenant.scoreStatus !== 'APPROVED'
      );
    },
    isTenantPersonalIdCodeMissing() {
      return (
        this.agreementFromFirebase.tenant &&
        !this.agreementFromFirebase.tenant.personalIdCode
      );
    },
    isLandlordPersonalIdCodeMissing() {
      return (
        this.agreementFromFirebase.landlord &&
        !this.agreementFromFirebase.landlord.personalIdCode
      );
    },
    isTenantProfileIncomplete() {
      return (
        this.agreementFromFirebase.tenant &&
        (!this.agreementFromFirebase.tenant.firstName ||
          !this.agreementFromFirebase.tenant.lastName ||
          !this.agreementFromFirebase.tenant.phoneNumber ||
          !this.agreementFromFirebase.tenant.email)
      );
    },
    isLandlordProfileIncomplete() {
      return (
        this.agreementFromFirebase.landlord &&
        (!this.agreementFromFirebase.landlord.firstName ||
          !this.agreementFromFirebase.landlord.lastName ||
          !this.agreementFromFirebase.landlord.phoneNumber ||
          !this.agreementFromFirebase.landlord.email)
      );
    },
    isLandlordProfileCorrectForPlNo() {
      return (
        this.agreementFromFirebase?.landlord?.type === 'BUSINESS' &&
        this.agreementFromFirebase?.agreementTypeFromAgreement === agreementTypes.PL_NO
      );
    },
    isLandlordProfileCorrectForPlNi() {
      return (
        this.agreementFromFirebase?.landlord?.type === 'PERSONAL' &&
        this.agreementFromFirebase?.agreementTypeFromAgreement === agreementTypes.PL_NI
      );
    },
    isLandlordProfileTypeBusinessAndCompanyInfoMissing() {
      // For Polish company companyTaxNumber is required not companyNumbe
      if (
        this.agreementFromFirebase.landlord &&
        this.agreementFromFirebase.landlord.addressCountry === 'PL'
      ) {
        return (
          this.agreementFromFirebase.landlord &&
          this.agreementFromFirebase.landlord.type === 'BUSINESS' &&
          (!this.agreementFromFirebase.landlord.companyName ||
            !this.agreementFromFirebase.landlord.companyTaxNumber)
        );
      }
      return (
        this.agreementFromFirebase.landlord &&
        this.agreementFromFirebase.landlord.type === 'BUSINESS' &&
        (!this.agreementFromFirebase.landlord.companyName ||
          !this.agreementFromFirebase.landlord.companyNumber)
      );
    },
    isLandlordProfileTypeBusinessAndAddressInfoMissing() {
      return (
        this.agreementFromFirebase.landlord &&
        this.agreementFromFirebase.landlord.type === 'BUSINESS' &&
        (!this.agreementFromFirebase.landlord.addressCity ||
          !this.agreementFromFirebase.landlord.addressCountry ||
          !this.agreementFromFirebase.landlord.addressFirstline ||
          !this.agreementFromFirebase.landlord.addressPostalCode)
      );
    },
    creatorProfileIsLandlord() {
      return (
        this.agreementFromFirebase.landlordProfileId ===
        this.agreementFromFirebase.creatorProfileId
      );
    },
    showLandlordProfileChangingButton() {
      return this.creatorProfileIsLandlord && this.showLandlordErrors;
    },
    showLandlordErrors() {
      return (
        !!this.landlordPersonalIdCodeMissing ||
        !!this.landlordProfileIncomplete ||
        !!this.landlordProfileIncorrectForPlNo ||
        !!this.landlordProfileIncorrectForPlNi ||
        !!this.landlordProfileIncompleteMissingAddress ||
        !!this.landlordProfileIncompleteMissingCompany
      );
    },

    showTenantErrors() {
      return (
        !!this.tenantProfileIncomplete ||
        !!this.tenantProfileBusinessError ||
        !!this.tenantPersonalIdCodeMissing
      );
    },

    addTenantLabel() {
      return this.getCandidatesData
        ? `${this.$t('tenant')} (${this.getCandidatesData?.length})`
        : this.$t('tenant');
    },
  },

  watch: {
    validate(val) {
      if (val) {
        this.validateAgreementParties();
        this.getCandidates();
      }
    },
    showAddTenantModal(val) {
      /* We opened Add Tenant modal */
      if (val) {
        this.trackCandidatesViewListEvent({
          processName: trackingProcessNames.AGREEMENT,
          agreementId: this.agreementId,
        });
      }
    },
  },

  mounted() {
    this.getCandidates();
    this.validateAgreementParties();
  },

  methods: {
    ...mapActions({
      actionGetCandidates: 'candidacies/getCandidates',
      actionGetAgreement: 'agreements/getAgreement',
      actionPutAgreement: 'agreements/putAgreement',
      actionAgreementCoTenantAdd: 'agreements/agreementCoTenantAdd',
      actionAgreementCoTenantRemove: 'agreements/agreementCoTenantRemove',
      trackCandidatesViewListEvent: 'tracker/trackCandidatesViewListEvent',
      actionTrackAgreementFlowEvent: 'tracker/trackAgreementFlowEvent',
      trackInviteLinkOpenSharingEvent: 'tracker/trackInviteLinkOpenSharingEvent',
      trackInviteLinkCopyEvent: 'tracker/trackInviteLinkCopyEvent',
    }),

    validateAgreementParties() {
      let validationSuccessful = true;
      let validationErrorForTabHeadline = null;
      let validationPartiesForTabHeadline = null;

      // Clear data
      this.profileMatchError = null;
      this.tenantProfileBusinessError = null;
      this.tenantPersonalIdCodeMissing = null;
      this.landlordPersonalIdCodeMissing = null;
      this.tenantProfileIncomplete = null;
      this.landlordProfileIncomplete = null;
      this.landlordProfileIncorrectForPlNo = null;
      this.landlordProfileIncorrectForPlNi = null;
      this.landlordProfileIncompleteMissingAddress = null;
      this.landlordProfileIncompleteMissingCompany = null;

      if (this.agreementFromFirebase && this.agreementId) {
        // 1.Check if LL and T are added
        let landlordNameOrError = '';
        let tenantNameOrError = '';

        if (
          this.agreementFromFirebase?.landlord?.firstName &&
          this.agreementFromFirebase?.landlord?.lastName
        ) {
          if (
            this.agreementFromFirebase.landlord.type === 'BUSINESS' &&
            this.agreementFromFirebase.landlord.companyName
          ) {
            landlordNameOrError = `<div class="text-gray-900">${this.agreementFromFirebase.landlord.companyName} (${this.agreementFromFirebase.landlord.firstName} ${this.agreementFromFirebase.landlord.lastName})</div>`;
          } else {
            landlordNameOrError = `<div class="text-gray-900">${this.agreementFromFirebase.landlord.firstName} ${this.agreementFromFirebase.landlord.lastName}</div>`;
          }
        } else {
          validationSuccessful = false;
          landlordNameOrError = `<div>${this.$t('agreement.error.missing_landlord')}</div>`;
        }

        if (
          this.agreementFromFirebase?.tenant?.firstName &&
          this.agreementFromFirebase?.tenant?.lastName
        ) {
          tenantNameOrError = `<div class="text-gray-900">${this.agreementFromFirebase.tenant.firstName} ${this.agreementFromFirebase.tenant.lastName}</div>`;
        } else {
          validationSuccessful = false;
          tenantNameOrError = `<div>${this.$t('agreement.error.missing_tenant')}</div>`;
        }

        validationPartiesForTabHeadline = landlordNameOrError + tenantNameOrError;
        if (!validationPartiesForTabHeadline)
          validationPartiesForTabHeadline = `<div class="text-gray-400">${this.$t('agreement.error.add_agreement_parties')}</div>`;

        // Error - LL ja T can not be same person
        if (this.isTenantAndLandlordSamePerson) {
          this.profileMatchError = this.$t('agreement.error.profiles_match');
          validationSuccessful = false;
          validationErrorForTabHeadline = this.profileMatchError;
        }
        // Error - T can not be with business profile
        if (this.isTenantProfileTypeBusiness) {
          this.tenantProfileBusinessError = this.$t(
            'agreement.error.business_profile_tenant_not_allowed',
          );
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.tenantProfileBusinessError;
        }
        // Error - T bg check not approved
        if (this.isTenantScoreStatusNotApproved) {
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline ||
            this.$t('agreement.error.security_not_accepted_profile_tenant');
        }
        // Error - T personal ID code check
        if (this.isTenantPersonalIdCodeMissing) {
          this.tenantPersonalIdCodeMissing = this.$t(
            'agreement.error.id_code_incorrect_tenant',
          )[this.$getCountry()];
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.tenantPersonalIdCodeMissing;
        }
        // Error - LL personal ID code check
        if (this.isLandlordPersonalIdCodeMissing) {
          this.landlordPersonalIdCodeMissing = this.creatorProfileIsLandlord
            ? this.$t('agreement.error.id_code_incorrect_landlord_creator_is_landlord')[
                this.$getCountry()
              ]
            : this.$t('agreement.error.id_code_incorrect_landlord')[this.$getCountry()];

          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.landlordPersonalIdCodeMissing;
        }
        // Error - T profile missing information, name, phone, email
        if (this.isTenantProfileIncomplete) {
          this.tenantProfileIncomplete = this.$t(
            'agreement.error.incomplete_profile_tenant',
          );
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.tenantProfileIncomplete;
        }
        // Error - L profile missing information, name, phone, email
        if (this.isLandlordProfileIncomplete) {
          this.landlordProfileIncomplete = this.creatorProfileIsLandlord
            ? this.$t('agreement.error.incomplete_profile_landlord_creator_is_landlord')
            : this.$t('agreement.error.incomplete_profile_landlord');
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.landlordProfileIncomplete;
        }
        // Error - L profile type private is not correct for agreementType PL_NO
        if (this.isLandlordProfileCorrectForPlNo) {
          this.landlordProfileIncorrectForPlNo = this.$t(
            'profile.validation.business_profile_landlord_not_allowed',
          );
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.landlordProfileIncorrectForPlNo;
        }
        // Error - L profile type business is not correct for agreementType PL_NI
        if (this.isLandlordProfileCorrectForPlNi) {
          this.landlordProfileIncorrectForPlNi = this.$t(
            'profile.validation.private_landlord_not_allowed',
          );
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline || this.landlordProfileIncorrectForPlNi;
        }
        // Error - Business L profile missing information company
        if (this.isLandlordProfileTypeBusinessAndCompanyInfoMissing) {
          this.landlordProfileIncompleteMissingCompany = this.creatorProfileIsLandlord
            ? this.$t(
                'agreement.error.incomplete_profile_landlord_missing_company_creator_is_landlord',
              )
            : this.$t('agreement.error.incomplete_profile_landlord_missing_company');
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline ||
            this.landlordProfileIncompleteMissingCompany;
        }
        // Error - Business L profile missing information address
        if (this.isLandlordProfileTypeBusinessAndAddressInfoMissing) {
          this.landlordProfileIncompleteMissingAddress = this.creatorProfileIsLandlord
            ? this.$t(
                'agreement.error.incomplete_profile_landlord_missing_address_creator_is_landlord',
              )
            : this.$t('agreement.error.incomplete_profile_landlord_missing_address');
          validationSuccessful = false;
          validationErrorForTabHeadline =
            validationErrorForTabHeadline ||
            this.landlordProfileIncompleteMissingAddress;
        }
        // Error - CoT profile information check
        if (this.agreementFromFirebase?.coTenants?.length > 0) {
          this.agreementFromFirebase.coTenants.forEach((coTenant) => {
            if (
              !coTenant.firstName ||
              !coTenant.lastName ||
              !coTenant.email ||
              !coTenant.personalIdCode ||
              !coTenant.phoneNumber
            ) {
              validationSuccessful = false;
              if (!validationErrorForTabHeadline) {
                validationErrorForTabHeadline = this.$t(
                  'agreement.error.incomplete_profile_coTenant',
                );
              }
            }
          });
        }
      } else {
        validationSuccessful = false;
        validationPartiesForTabHeadline = `<div class="text-gray-400">${this.$t('agreement.error.add_agreement_parties')}</div>`;
      }

      const result = {
        success: validationSuccessful,
        error: validationErrorForTabHeadline || validationPartiesForTabHeadline,
      };

      this.$emit('agreementPartiesFormValidation', result);

      return result;
    },

    getCandidates() {
      if (this.agreementId && this.agreementFromFirebase.invitationId) {
        this.actionGetCandidates({ agreementId: this.agreementId }).then((result) => {
          this.getCandidatesData = result.response;
        });
      }
    },

    addLandlord(landlordProfileId) {
      const data = { landlordProfileId: landlordProfileId };

      if (landlordProfileId) {
        this.landlordInfoInProgress = true;
        this.scrollPageUp();
        if (this.agreementId) {
          this.actionPutAgreement({ id: this.agreementId, agreement: { ...data } })
            .then(() =>
              this.emitAgreementTrackerEvent(
                this.agreementTrackingEventNames.ADD_LANDLORD,
                { addedProfileId: landlordProfileId },
              ),
            )
            .then(() => this.getAgreement())
            .then(() => {
              if (this.validationExtraAttention) this.$emit('requestServerValidation');
            })
            .finally(() => {
              this.landlordInfoInProgress = false;
            });

          this.showAddLandlordModal = false;
          this.showAddLandlordModalSearchView = false;
        }
      }
    },

    openAddLandlordView(openFirstSearchView) {
      this.showAddLandlordModal = true;
      this.showAddLandlordModalSearchView = !!openFirstSearchView;
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_LANDLORD);
    },

    openAddTenantView() {
      this.candidacyListModalOpenSrc = 'Add Tenant';
      this.showAddTenantModal = true;
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_TENANT);
    },

    openAddCoTenantView() {
      this.showAddCoTenantView = true;
      this.emitAgreementTrackerEvent(this.agreementTrackingEventNames.OPEN_CO_TENANT);
    },

    openBackgroundCheckInfoModal() {
      this.showBackgroundCheckModal = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_BACKGROUND_CHECK_INFO,
      );
    },

    showBackgroundCheckForCandidacy() {
      this.showAddTenantModal = false;
      this.reopenAddTenantModal = true;

      this.showBackgroundCheckModal = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_BACKGROUND_CHECK_INFO,
      );
    },

    closeBackgroundCheckInfoModal() {
      this.showBackgroundCheckModal = false;

      if (this.reopenAddTenantModal) {
        this.openAddTenantView();
        this.reopenAddTenantModal = false;
      }
    },

    addCoTenant(coTenantProfileId) {
      this.coTenantInfoInProgress = true;

      if (this.agreementId) {
        this.actionAgreementCoTenantAdd({
          agreementId: this.agreementId,
          profileId: coTenantProfileId,
        })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.ADD_CO_TENANT,
              { addedProfileId: coTenantProfileId },
            ),
          )
          .then(() => this.getAgreement())
          .then(() => {
            if (this.validationExtraAttention) this.$emit('requestServerValidation');
          })
          .finally(() => {
            this.coTenantInfoInProgress = false;
          });

        this.showAddCoTenantView = false;
      }
    },

    removeCoTenant(coTenantProfileId) {
      this.coTenantsToRemove.push(coTenantProfileId);

      if (this.agreementId) {
        this.actionAgreementCoTenantRemove({
          agreementId: this.agreementId,
          profileId: coTenantProfileId,
        })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.REMOVE_CO_TENANT,
              { removedProfileId: coTenantProfileId },
            ),
          )
          .then(() => this.getAgreement())
          .then(() => {
            if (this.validationExtraAttention) this.$emit('requestServerValidation');
          })
          .finally(() => {
            this.coTenantsToRemove = this.coTenantsToRemove.filter(
              (id) => id !== coTenantProfileId,
            );
          });
      }
    },

    addTenant(tenantProfileId) {
      const data = { tenantProfileId: tenantProfileId };

      if (tenantProfileId) {
        this.tenantInfoInProgress = true;
        this.scrollPageUp();
        if (this.agreementId) {
          this.actionPutAgreement({ id: this.agreementId, agreement: { ...data } })
            .then(() =>
              this.emitAgreementTrackerEvent(
                this.agreementTrackingEventNames.ADD_TENANT,
                { addedProfileId: tenantProfileId },
              ),
            )
            .then(() => this.getAgreement())
            .then(() => {
              if (this.validationExtraAttention) this.$emit('requestServerValidation');
            })
            .finally(() => {
              this.tenantInfoInProgress = false;
            });

          this.showAddTenantModal = false;
        }
      }
    },

    removeLandlord() {
      if (
        this.agreementId &&
        this.agreementFromFirebase &&
        this.agreementFromFirebase.landlord
      ) {
        this.landlordToRemoveInProgress = true;
        this.actionPutAgreement({
          id: this.agreementId,
          agreement: {},
          removeLandlord: true,
        })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.REMOVE_LANDLORD,
              { removedProfileId: this.agreementFromFirebase.landlordProfileId },
            ),
          )
          .then(() => this.getAgreement())
          .then(() => {
            if (this.validationExtraAttention) this.$emit('requestServerValidation');
          })
          .finally(() => {
            this.landlordToRemoveInProgress = false;
          });
      }
    },

    removeTenant() {
      if (
        this.agreementId &&
        this.agreementFromFirebase &&
        this.agreementFromFirebase.tenant
      ) {
        this.tenantToRemoveInProgress = true;
        this.actionPutAgreement({
          id: this.agreementId,
          agreement: {},
          removeTenant: true,
        })
          .then(() =>
            this.emitAgreementTrackerEvent(
              this.agreementTrackingEventNames.REMOVE_TENANT,
              {
                removedProfileId: this.agreementFromFirebase.tenantProfileId,
              },
            ),
          )
          .then(() => this.getAgreement())
          .then(() => {
            if (this.validationExtraAttention) this.$emit('requestServerValidation');
          })
          .finally(() => {
            this.tenantToRemoveInProgress = false;
          });
      }
    },

    getAgreement() {
      return this.actionGetAgreement({ id: this.agreementId }).then(() =>
        this.validateAgreementParties(),
      );
    },

    copyLink(url, tipPlace) {
      const copyTooltip = this.$tippy(tipPlace, {
        trigger: 'manual',
        duration: 500,
        animation: 'fade',
        content: this.$t('notifications.copied_to_clipboard'),
        onShow(instance) {
          setTimeout(function () {
            instance.hide();
          }, 2000);
        },
      });

      navigator.clipboard.writeText(url).then(function () {
        setTimeout(() => copyTooltip.show());
      });
    },

    linkToInvite(invitationShortCode, workflow) {
      if (invitationShortCode) {
        if (this.isENVIsProduction) {
          if (workflow === 'EE') {
            return `https://rendin.ee${this.$localizedPath(redirectLinks.INVITE_AGREEMENT + invitationShortCode)}`;
          } else {
            return `https://rendin.pl${this.$localizedPath(redirectLinks.INVITE_AGREEMENT + invitationShortCode)}`;
          }
        } else {
          return (
            window.location.origin +
            this.$localizedPath(redirectLinks.INVITE_AGREEMENT + invitationShortCode)
          );
        }
      }
      return null;
    },

    emitAgreementTrackerEvent(event, customProperties) {
      this.actionTrackAgreementFlowEvent({
        eventName: event,
        processName: this.trackingProcessNames.AGREEMENT_DRAFTING,
        agreement: this.agreementFromFirebase,
        props: customProperties,
      });
    },

    openSharingModal(location) {
      if (!this.agreementFromFirebase.invitationShortCodes[0]) this.getAgreement();
      this.sharingModalLocation = location;
      this.sharingModalOpen = true;
    },

    requestTabsValidation() {
      this.$emit('requestTabsValidation');
    },

    openProfileChangingModal() {
      this.showProfileChangeModal = true;
      this.emitAgreementTrackerEvent(
        this.agreementTrackingEventNames.OPEN_AGREEMENT_PROFILE_CHANGE,
        { location: 'Parties Tab' },
      );
    },

    closeProfileChangingModal() {
      this.showProfileChangeModal = false;
      this.$emit('requestTabsValidation');
    },

    showCoTenantProfileError(coTenant) {
      return (
        !coTenant.firstName ||
        !coTenant.lastName ||
        !coTenant.email ||
        !coTenant.personalIdCode ||
        !coTenant.phoneNumber
      );
    },

    scrollPageUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
});
</script>
