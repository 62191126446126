<template>
  <section class="handover-status-view">
    <!--      Tenant status steps-->
    <div v-if="isTenantView">
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 pb-16">
        <div class="text-rendin-700 uppercase font-medium my-2">
          {{ $t('agreement.handover_v2.label') }}
        </div>

        <div class="text-sm text-gray-600 my-4">
          {{ $t('agreement.handover_v2.tenant_status.subtitle') }}
        </div>

        <div class="my-8">
          <r-accordion-group
            :active-index="tenantStatusStepsActiveIndex"
            flavor="plain"
            stay-open
            :value="tenantStatusSteps"
          >
            <template #DRAFT>
              <div
                class="handover-status-view__step"
                v-html="$t('agreement.handover_v2.tenant_status.waiting_handover_sub')"
              ></div>
            </template>
            <template #COMPLETED>
              <div
                class="handover-status-view__step"
                v-html="$t('agreement.handover_v2.tenant_status.waiting_review_sub')"
              ></div>
            </template>
            <template #FINISHED>
              <div
                class="handover-status-view__step"
                v-html="
                  $t('agreement.handover_v2.tenant_status.handover_finalised_sub')
                "
              ></div>
            </template>
          </r-accordion-group>
        </div>
      </div>

      <div
        v-if="handoverStatusPassed(handoverStatuses.READY_TO_SEND)"
        class="mt-6 sm:flex justify-end"
      >
        <r-button
          :label="$t('agreement.handover_v2.tenant_status.review_handover')"
          @click="openHandoverProtocol()"
        />
      </div>
    </div>

    <!--      Landlord and creator status steps-->
    <div v-else>
      <div class="bg-white shadow-lg rounded-lg p-4 sm:px-8 pb-16">
        <div class="text-rendin-700 uppercase font-medium my-2">
          {{ $t('agreement.handover_v2.label') }}
        </div>

        <div class="text-sm text-gray-600 my-4">
          {{ $t('agreement.handover_v2.landlord_status.subtitle') }}
        </div>

        <div class="my-8">
          <r-accordion-group
            :active-index="landlordStatusStepsActiveIndex"
            flavor="plain"
            stay-open
            :value="landlordStatusSteps"
          >
            <template #INITIATE>
              <div
                class="handover-status-view__step"
                v-html="$t('agreement.handover_v2.landlord_status.initiate_sub')"
              ></div>
            </template>
            <template #DRAFT>
              <div
                class="handover-status-view__step"
                v-html="$t('agreement.handover_v2.landlord_status.draft_saved_sub')"
              ></div>
            </template>
            <template #READY_TO_SEND>
              <div
                class="handover-status-view__step"
                v-html="$t('agreement.handover_v2.landlord_status.send_to_tenant_sub')"
              ></div>
            </template>
            <template #COMPLETED>
              <div
                class="handover-status-view__step"
                v-html="$t('agreement.handover_v2.landlord_status.tenant_review_sub')"
              ></div>
            </template>
            <template #FINISHED>
              <div
                class="handover-status-view__step"
                v-html="
                  $t('agreement.handover_v2.landlord_status.handover_finalised_sub')
                "
              ></div>
            </template>
          </r-accordion-group>
        </div>
      </div>

      <div class="mt-6 sm:flex justify-end">
        <!--        Start handover -->
        <r-button
          v-if="!agreementHandover?.handover?.status"
          class="w-full sm:w-auto"
          :label="
            $t('agreement.handover_v2.landlord_status.initiate_handover_protocol')
          "
          @click="openHandoverEdit()"
        />
        <!--      Handover in DRAFT -->
        <div
          v-else-if="agreementHandover?.handover?.status === handoverStatuses.DRAFT"
          class="flex flex-col sm:flex-row gap-4"
        >
          <r-button
            border-color="light-gray"
            class="w-full sm:w-auto"
            color="black"
            inverted
            :label="$t('agreement.handover_v2.landlord_status.handover_preview')"
            @click="openHandoverProtocol()"
          />
          <r-button
            class="w-full sm:w-auto"
            :label="$t('agreement.handover_v2.landlord_status.edit_handover')"
            @click="openHandoverEdit()"
          />
        </div>
        <!--      Handover in READY_TO_SEND -->
        <div
          v-else-if="
            agreementHandover?.handover?.status === handoverStatuses.READY_TO_SEND
          "
          class="flex flex-col sm:flex-row gap-4"
        >
          <r-button
            border-color="light-gray"
            class="w-full sm:w-auto"
            color="black"
            inverted
            :label="$t('agreement.handover_v2.landlord_status.edit_handover')"
            @click="openHandoverEdit()"
          />
          <r-button
            class="w-full sm:w-auto"
            :label="$t('agreement.handover_v2.landlord_status.preview_and_send')"
            @click="openHandoverProtocol()"
          />
        </div>
        <!--      Handover in COMPLETED -->
        <r-button
          v-else-if="agreementHandover?.handover?.status === handoverStatuses.COMPLETED"
          border-color="light-gray"
          class="w-full sm:w-auto"
          color="black"
          inverted
          :label="$t('agreement.handover_v2.landlord_status.handover_preview')"
          @click="openHandoverProtocol()"
        />
        <r-button
          v-else-if="agreementHandover?.handover?.status === handoverStatuses.FINISHED"
          class="w-full sm:w-auto"
          :label="$t('buttons.view_handover_act')"
          @click="openHandoverProtocol()"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { handoverStatuses, handoverStatusesOrder } from '~/utils/constants';

export default {
  name: 'HandoverStatusView',

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    appHandoverLink: {
      type: String,
      default: null,
    },
  },

  emits: ['openHandoverProtocol', 'openHandoverEdit'],

  data: () => ({
    tenantStatusStepsActiveIndex: -1,
    landlordStatusStepsActiveIndex: -1,
    handoverStatuses,
    showLandlordHandoverConformationModal: false,
  }),

  computed: {
    tenantStatusSteps() {
      return [
        {
          id: handoverStatuses.DRAFT,
          icon: this.handoverStatusIcon(handoverStatuses.DRAFT),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.DRAFT),
          title: this.$t('agreement.handover_v2.tenant_status.waiting_handover'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.DRAFT),
        },
        {
          id: handoverStatuses.COMPLETED,
          icon: this.handoverStatusIcon(handoverStatuses.COMPLETED),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.COMPLETED),
          title: this.$t('agreement.handover_v2.tenant_status.waiting_review'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.COMPLETED),
        },
        {
          id: handoverStatuses.FINISHED,
          icon: this.handoverStatusIcon(handoverStatuses.FINISHED),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.FINISHED),
          title: this.$t('agreement.handover_v2.tenant_status.handover_finalised'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.FINISHED),
        },
      ];
    },

    landlordStatusSteps() {
      return [
        {
          id: 'INITIATE',
          icon: this.handoverStatusIcon(),
          iconClass: this.handoverStatusIconClasses(),
          iconPrefixSolid: true,
          title: this.$t('agreement.handover_v2.landlord_status.initiate'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(),
        },
        {
          id: handoverStatuses.DRAFT,
          icon: this.handoverStatusIcon(handoverStatuses.DRAFT),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.DRAFT),
          title: this.$t('agreement.handover_v2.landlord_status.draft_saved'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.DRAFT),
        },
        {
          id: handoverStatuses.READY_TO_SEND,
          icon: this.handoverStatusIcon(handoverStatuses.READY_TO_SEND),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.READY_TO_SEND),
          title: this.$t('agreement.handover_v2.landlord_status.send_to_tenant'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.READY_TO_SEND),
        },
        {
          id: handoverStatuses.COMPLETED,
          icon: this.handoverStatusIcon(handoverStatuses.COMPLETED),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.COMPLETED),
          title: this.$t('agreement.handover_v2.landlord_status.tenant_review'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.COMPLETED),
        },
        {
          id: handoverStatuses.FINISHED,
          icon: this.handoverStatusIcon(handoverStatuses.FINISHED),
          iconPrefixSolid: true,
          iconClass: this.handoverStatusIconClasses(handoverStatuses.FINISHED),
          title: this.$t('agreement.handover_v2.landlord_status.handover_finalised'),
          titleClass: 'handover-status-view__step-title',
          open: this.handoverStatusActive(handoverStatuses.FINISHED),
        },
      ];
    },

    currentHandoverStatusIndex() {
      return handoverStatusesOrder.indexOf(this.agreementHandover?.handover?.status);
    },
  },

  methods: {
    stepStatusIndex(stepStatus) {
      return handoverStatusesOrder.indexOf(stepStatus);
    },

    handoverStatusIcon(stepStatus) {
      const initiateStepHandoverStarted =
        this.stepStatusIndex(stepStatus) === -1 && this.currentHandoverStatusIndex > -1;
      const stepBeforeActiveStatus =
        this.stepStatusIndex(stepStatus) < this.currentHandoverStatusIndex;

      if (initiateStepHandoverStarted || stepBeforeActiveStatus) {
        return 'circle-check';
      }
      return 'scrubber';
    },

    handoverStatusIconClasses(stepStatus) {
      const initiateStepHandoverNotStarted =
        this.stepStatusIndex(stepStatus) === -1 &&
        this.currentHandoverStatusIndex === -1;
      const initiateStepHandoverStarted =
        this.stepStatusIndex(stepStatus) === -1 && this.currentHandoverStatusIndex > -1;
      const stepBeforeActiveStatus =
        this.stepStatusIndex(stepStatus) < this.currentHandoverStatusIndex;
      const stepEqualToActiveStatus =
        this.stepStatusIndex(stepStatus) === this.currentHandoverStatusIndex;
      const classes = ['handover-status-view__step-icon'];

      if (initiateStepHandoverNotStarted || stepEqualToActiveStatus) {
        classes.push('handover-status-view__step-icon--active');
      } else if (initiateStepHandoverStarted || stepBeforeActiveStatus) {
        classes.push('handover-status-view__step-icon--passed');
      }

      return classes.join(' ');
    },

    handoverStatusPassed(stepStatus) {
      return (
        this.agreementHandover?.handover?.status === this.handoverStatuses.FINISHED ||
        this.stepStatusIndex(stepStatus) < this.currentHandoverStatusIndex
      );
    },

    handoverStatusActive(stepStatus) {
      return this.stepStatusIndex(stepStatus) === this.currentHandoverStatusIndex;
    },

    openHandoverProtocol() {
      this.$emit('openHandoverProtocol');
    },

    openHandoverEdit() {
      this.$emit('openHandoverEdit');
    },
  },
};
</script>

<style lang="scss">
.handover-status-view {
  .r-accordion {
    @apply relative pb-4 overflow-hidden;
    &::after {
      @apply bg-gray-200 block absolute left-8 bottom-2 w-full h-px content-[''];
    }

    &:last-child::after {
      @apply content-none;
    }

    &:last-child .handover-status-view__step-icon::after {
      @apply content-none;
    }
  }

  .handover-status-view__step {
    @apply break-words whitespace-pre-line max-w-3xl ml-8 mt-3;
  }

  .handover-status-view__step-title {
    @apply text-gray-600 text-lg font-medium ml-3;
  }

  .handover-status-view__step-icon {
    @apply text-gray-50 text-xl ring-2 ring-gray-200 bg-gray-200 rounded-full flex absolute top-4 -translate-y-1/2 ml-1.5;

    &::after {
      @apply bg-gray-200 w-0.5 block absolute translate-x-1/2 right-1/2 h-screen content-[''] top-7;
    }

    &--active {
      @apply text-grayCool-400 ring-violet-100 ring-4 ring-offset-1;

      &::before {
        z-index: -1;
        @apply content-[''] absolute -top-0.5 -bottom-0.5 -left-0.5 -right-0.5 border-2 border-grayCool-500 rounded-full;
      }
    }

    &--passed {
      @apply text-green-600 ring-green-500 bg-white;

      &:after {
        @apply bg-green-500;
      }
    }
  }
}
</style>
