<template>
  <div class="bg-gray-50 relative" :class="{ 'pb-20': !showMobileApplyButton }">
    <client-only>
      <r-overlay
        body-classes="p-4 pt-12"
        size="medium"
        :visible="mobileInviteModalOpen"
        @close="mobileInviteModalOpen = false"
      >
        <div
          v-if="currentInviteFlowStep === inviteFlowSteps.LOADING"
          class="relative flex-h-screen flex justify-center items-center"
        >
          <r-loader type="animatedLogo" />
        </div>
        <div
          v-else-if="currentInviteFlowStep === inviteFlowSteps.SESSION_FLOW"
          class="flex-h-screen"
        >
          <div v-if="emailLoginStartScreen || needToFillProfile">
            <r-title-with-text
              v-if="isAgreementInvite"
              content-font-size="16px"
              :rich-text-content="
                needToFillProfile
                  ? blok.formContentAgreementInvite
                  : blok.loginContentAgreementInvite
              "
              :title="
                needToFillProfile
                  ? blok.formTitleAgreementInvite
                  : blok.loginTitleAgreementInvite
              "
              title-font-size="18px"
            />
            <r-title-with-text
              v-else
              content-font-size="16px"
              :rich-text-content="
                needToFillProfile ? blok.formContent : blok.loginContent
              "
              :title="needToFillProfile ? blok.formTitle : blok.loginTitle"
              title-font-size="18px"
            />
          </div>
          <div>
            <session-flow
              :indicated-profile-source="indicatedProfileSource"
              :no-text="true"
              :profile-initial-type="userRole.TENANT"
              show-login-view-back-to-start-link
              sticky-primary-button
              :successful-reroute="$localizedPath($router.currentRoute.path)"
              @login-start="
                actionTrackInvitationStartLoginEvent({
                  authProvider: $event,
                  advertisementId: invite.advertisementDocId,
                  agreementId: invite.agreementDocId,
                  code: shortCode,
                })
              "
              @login-view-pin-screen-visible="emailLoginStartScreen = false"
              @login-view-start-screen-visible="emailLoginStartScreen = true"
              @profile-primary-info-submit="
                actionTrackInvitationFillProfileEvent({
                  advertisementId: invite.advertisementDocId,
                  agreementId: invite.agreementDocId,
                  code: shortCode,
                })
              "
            />
          </div>
        </div>
        <div
          v-else-if="currentInviteFlowStep === inviteFlowSteps.BACKGROUND_CHECK"
          class="flex-h-screen flex flex-col items-stretch my-2"
        >
          <background-check-window
            :blok="blok.backGroundCheckSections"
            class="min-h-1/2-screen"
            :is-agreement-invite="isAgreementInvite"
            mobile-version
            :score="userProfile.scoreStatus"
            sticky-primary-button
            @submit-id-code="
              [
                (firstTimeSignUpCompletedScreen = true),
                actionTrackInvitationSubmitBackgroundCheckEvent({
                  advertisementId: invite.advertisementDocId,
                  agreementId: invite.agreementDocId,
                  code: shortCode,
                }),
              ]
            "
          />
          <Transition name="slide-right">
            <div
              class="bg-gray-50 px-4 py-4 flex flex-wrap gap-2 items-center justify-center rounded-md"
            >
              <div class="w-16 h-16 flex justify-center">
                <img :src="blok.backgroundCheckRibbonImage.filename" />
              </div>
              <div class="w-48 flex-shrink-0 flex-grow">
                <p class="text-xs">
                  {{
                    $t('advertisement_invite.apply_form.value_proposition_text', {
                      depositAmount: invite.rentAmount,
                      currency: invite.workflow === 'PL' ? 'zł' : '€',
                    })
                  }}
                </p>
              </div>
            </div>
          </Transition>
          <!--    Empty div reserves space for our sticky modal button   -->
          <div class="h-16"></div>
        </div>
        <div
          v-else-if="
            currentInviteFlowStep === inviteFlowSteps.CANDIDACY_FORM ||
            currentInviteFlowStep === inviteFlowSteps.SHOW_CANDIDACY
          "
          class="flex-h-screen"
        >
          <advertisement-invite-apply-form
            class="mb-2"
            :emit-apply-in-progress="postCandidateInProgress"
            :invite="invite"
            :is-agreement-invite="isAgreementInvite"
            :profile="dataForProfileInfoCard"
            sticky-primary-button
            @apply-button-clicked="applyEvent"
            @textarea-change="getMessage($event)"
          />
        </div>
      </r-overlay>
    </client-only>
    <client-only>
      <!--                Background               -->
      <r-banner
        v-if="blok.headerImages && loadingIsNotNeeded && hasSessionUser"
        :images="blok.headerImages"
      />
      <!--                Loader               -->
      <div
        v-if="!loadingIsNotNeeded"
        class="relative min-h-1/2-screen flex justify-center items-center"
      >
        <r-loader type="animatedLogo" />
      </div>
      <div
        v-else
        class="relative mx-auto max-w-5xl px-4 py-10 md:py-20 flex flex-col w-full"
      >
        <!--   Advertisement not active/Wrong shortCode/Api failed view               -->
        <div
          v-if="isPropertyDataNull || getPropertyDataFailed || inviteNotActive"
          class="bg-white mb-2 flex justify-center rounded-lg shadow-lg"
        >
          <div class="md:w-2/3 my-4">
            <r-title-with-picture
              v-if="isPropertyDataNull"
              :content="$t('error.wrong_url.content')"
              content-style="text-lg"
              :image="blok.notActiveImage.filename"
              :redirect-button-link="redirectLinkToTenantOffer"
              :redirect-button-text="$t('buttons.browse_advertisements')"
              :title="$t('error.wrong_url.title')"
              title-style="text-2xl"
            />
            <r-title-with-picture
              v-else-if="getPropertyDataFailed"
              :content="blok.serverFailedContent"
              content-style="text-lg"
              :image="blok.serverFailedImage.filename"
              image-size="w-2/3"
              :redirect-button-link="redirectLinkToTenantOffer"
              :redirect-button-text="$t('buttons.browse_advertisements')"
              :title="$t('error.api_failed.title')"
              title-style="text-2xl"
            />
            <r-title-with-picture
              v-else-if="isAgreementInvite && inviteNotActive"
              :content="blok.notActiveContentAgreementInvite"
              content-style="text-lg"
              :image="blok.notActiveImageAgreementInvite.filename"
              :redirect-button-link="redirectLinkToTenantOffer"
              :redirect-button-text="$t('buttons.browse_advertisements')"
              :title="blok.notActiveTitleAgreementInvite"
              title-style="text-2xl"
            />
            <r-title-with-picture
              v-else
              :content="blok.notActiveContent"
              content-style="text-lg"
              :image="blok.notActiveImage.filename"
              :redirect-button-link="redirectLinkToTenantOffer"
              :redirect-button-text="$t('buttons.browse_advertisements')"
              :title="blok.notActiveTitle"
              title-style="text-2xl"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="!isAgreementInvite" class="pb-4">
            <r-button
              border-color="light-gray"
              color="black"
              hover="hover:text-rendin-500 hover:shadow-lg"
              icon="arrow-left-long"
              icon-class="sm:pr-3"
              inverted
              :label="
                $t(
                  notDefaultBackButtonTitle ? 'buttons.back' : 'buttons.back_to_search',
                )
              "
              @click="
                goToPreviousPage($localizedPath(redirectLinks.TENANT_PROFILE_OFFERS))
              "
            />
          </div>
          <div class="flex flex-col md:flex-row gap-2">
            <!--             Left side        -->
            <div class="w-full md:w-1/2 max-w-xl mx-auto">
              <!--             Property        -->
              <background-check-intro
                v-if="
                  findComponentInArray(
                    'backgroundCheckIntro',
                    blok.backGroundCheckSections,
                  ) &&
                  currentInviteFlowStep === inviteFlowSteps.BACKGROUND_CHECK &&
                  !isAgreementInvite
                "
                :background-check-intro-info="
                  findComponentInArray(
                    'backgroundCheckIntro',
                    blok.backGroundCheckSections,
                  )
                "
                class="mb-2 px-4 sm:hidden"
                @second-content-click="
                  isMobileScreen
                    ? (mobileInviteModalOpen = true)
                    : scrollMeTo('backgroundCheck')
                "
              />
              <r-property
                :additional-fees="invite.additionalFees"
                :address="parseFullAddressFromAddressParameters(invite)"
                :area-label="$t('offer.size')"
                :broker-fee-label="$t('offer.additional_fee.type.broker_fee')"
                :contract-fee-label="$t('offer.additional_fee.type.contract_fee')"
                :currency="getCurrency(invite)"
                :deposit-label="$t('offer.deposit')"
                :description-label="$t('offer.description')"
                :fee-label="$t('offer.rendin_fee')"
                :fixed-utilities-label="
                  invite.workflow === workflow.POLAND
                    ? $t('offer.additional_fee.type.fixed_utilities.pl')
                    : $t('offer.additional_fee.type.fixed_utilities.ee')
                "
                :floor="
                  invite.advertisementDocId && isAgreementInvite ? invite.floor : null
                "
                :floor-label="$t('offer.floor')"
                :floors-total="invite.advertisementDocId ? invite.floorsTotal : null"
                :has-parking="invite.hasParking"
                :has-storage="invite.hasStorage"
                :images="isAgreementInvite ? null : invite.images"
                :insurance-invoice-to="invite.insuranceInvoiceTo"
                :object-area="invite.objectArea"
                :parking-label="$t('offer.parking')"
                :pets-allowed="invite.petsAllowed"
                :pets-allowed-label="$t('offer.pets')"
                :property-type="invite.propertyType"
                :read-more-label="$t('offer.read_more')"
                :rendin-fee="
                  invite.insuranceInvoiceTo === userRole.TENANT
                    ? invite.insuranceAmount
                    : null
                "
                rendin-fee-details
                :rent-amount="invite.rentAmount"
                :rent-label="$t('offer.price')"
                :rooms="invite.numberOfRooms"
                :rooms-label="$t('offer.rooms')"
                show-why-no-deposit
                :storage-label="$t('offer.storage')"
                :why-no-deposit-label="blok.whyNoDepositLabel"
                @on-rendin-fee-details-click="openRendinFeeInfoModal()"
                @on-why-deposit-click="onWhyDepositClick()"
                @open-gallery="onUseGallery()"
                @page-change="onUseGallery()"
              >
                <template v-if="isAgreementInvite" #intro>
                  <component
                    :is="theBlok.component"
                    v-for="theBlok in blok.AgreementInviteIntro.filter((item) =>
                      [
                        'TextBlok',
                        'RichTextBlok',
                        'FeatureCheckmarkList',
                        'Separator',
                      ].includes(item.component),
                    )"
                    :key="theBlok._uid"
                    :blok="theBlok"
                    class="w-full"
                  />
                </template>

                <template v-if="invite.description" #description>
                  {{ invite.description }}
                </template>
              </r-property>
            </div>
            <!--             Right side        -->
            <div
              ref="rightSide"
              class="w-full md:w-1/2 max-w-xl mx-auto flex flex-col gap-2"
            >
              <div
                v-if="currentInviteFlowStep === inviteFlowSteps.LOADING"
                class="bg-white shadow-lg rounded-lg p-6 relative flex justify-center items-center"
              >
                <r-loader type="animatedLogo" />
              </div>
              <div
                v-else-if="currentInviteFlowStep === inviteFlowSteps.SESSION_FLOW"
                class="flex flex-col items-center gap-2"
              >
                <!-- TODO for Kadri - Component removed for testing 07.20024, delete component and data from Storyblok when it's clear we don't need it any more -->

                <!--                <rendin-introduction-->
                <!--                  v-if="!hasSessionUser && blok.introductionAccordionsContent && blok.introductionAccordionsContent?.length > 0"-->
                <!--                  :accordions-content="blok.introductionAccordionsContent[0]"-->
                <!--                  :accordions-title="blok.introductionAccordionsTitle"-->
                <!--                  class="bg-white shadow-lg rounded-lg"-->
                <!--                  :content="blok.introductionContent"-->
                <!--                  :is-agreement-invite="isAgreementInvite"-->
                <!--                  :title="blok.introductionTitle"-->
                <!--                />-->

                <!-- LL contact info for mobile view -->
                <div
                  v-if="!isAgreementInvite"
                  class="bg-white rounded-lg p-4 mb-2 w-full md:hidden"
                >
                  <div class="bg-peach-100 rounded-lg p-4">
                    <h3 class="text-lg font-semibold">
                      {{ $t('advertisement_invite.contact_the_landlord') }}
                    </h3>
                    <r-profile-info-card
                      :copiable="landlordInfoCopiable"
                      :email="invite?.contact?.email"
                      :full-name="
                        invite?.contact?.firstName + ' ' + invite?.contact?.lastName
                      "
                      no-padding
                      :phone-number="
                        constructPhoneNr(
                          invite?.contact?.phoneCountryCode,
                          invite?.contact?.phoneNumber,
                        )
                      "
                    />
                  </div>
                  <div class="mt-4">
                    <r-button
                      :label="
                        $t(
                          'advertisement_invite.apply_form.button_show_contact_information',
                        )
                      "
                      size="small"
                      width="full"
                      @click="mobileInviteModalOpen = true"
                    />
                  </div>
                </div>

                <div
                  v-if="!isMobileScreen"
                  class="bg-white shadow-lg rounded-lg p-6 w-full"
                >
                  <div v-if="emailLoginStartScreen || needToFillProfile">
                    <r-title-with-text
                      v-if="isAgreementInvite"
                      content-font-size="16px"
                      :rich-text-content="
                        needToFillProfile
                          ? blok.formContentAgreementInvite
                          : blok.loginContentAgreementInvite
                      "
                      :title="
                        needToFillProfile
                          ? blok.formTitleAgreementInvite
                          : blok.loginTitleAgreementInvite
                      "
                      title-font-size="24px"
                    />
                    <div v-else>
                      <div class="bg-peach-100 rounded-lg p-4 mb-6">
                        <h3 class="text-lg font-semibold">
                          {{ $t('advertisement_invite.contact_the_landlord') }}
                        </h3>
                        <r-profile-info-card
                          :copiable="landlordInfoCopiable"
                          :email="invite?.contact?.email"
                          :full-name="
                            invite?.contact?.firstName + ' ' + invite?.contact?.lastName
                          "
                          no-padding
                          :phone-number="
                            constructPhoneNr(
                              invite?.contact?.phoneCountryCode,
                              invite?.contact?.phoneNumber,
                            )
                          "
                        />
                      </div>

                      <r-title-with-text
                        content-font-size="16px"
                        :rich-text-content="
                          needToFillProfile ? blok.formContent : blok.loginContent
                        "
                        :title="needToFillProfile ? blok.formTitle : blok.loginTitle"
                        title-font-size="18px"
                      />
                    </div>
                  </div>

                  <session-flow
                    class="-mt-4"
                    :indicated-profile-source="indicatedProfileSource"
                    :no-text="true"
                    :profile-initial-type="userRole.TENANT"
                    show-login-view-back-to-start-link
                    :successful-reroute="$localizedPath($router.currentRoute.path)"
                    @login-start="
                      actionTrackInvitationStartLoginEvent({
                        authProvider: $event,
                        advertisementId: invite.advertisementDocId,
                        agreementId: invite.agreementDocId,
                        code: shortCode,
                      })
                    "
                    @login-view-pin-screen-visible="emailLoginStartScreen = false"
                    @login-view-start-screen-visible="emailLoginStartScreen = true"
                    @profile-primary-info-submit="
                      actionTrackInvitationFillProfileEvent({
                        advertisementId: invite.advertisementDocId,
                        agreementId: invite.agreementDocId,
                        code: shortCode,
                      })
                    "
                  />
                </div>
              </div>
              <div
                v-else-if="currentInviteFlowStep === inviteFlowSteps.BACKGROUND_CHECK"
                ref="backgroundCheck"
                class="flex flex-col bg-white rounded-lg shadow-lg"
              >
                <div
                  v-if="!isAgreementInvite"
                  class="bg-peach-100 rounded-lg p-4 mx-6 mt-6"
                >
                  <h3 class="text-lg font-semibold">
                    {{ $t('advertisement_invite.contact_the_landlord') }}
                  </h3>
                  <r-profile-info-card
                    :copiable="landlordInfoCopiable"
                    :email="invite?.contact?.email"
                    :full-name="
                      invite?.contact?.firstName + ' ' + invite?.contact?.lastName
                    "
                    no-padding
                    :phone-number="
                      constructPhoneNr(
                        invite?.contact?.phoneCountryCode,
                        invite?.contact?.phoneNumber,
                      )
                    "
                  />
                </div>

                <background-check-intro
                  v-if="
                    findComponentInArray(
                      'backgroundCheckIntro',
                      blok.backGroundCheckSections,
                    ) && !isAgreementInvite
                  "
                  :background-check-intro-info="
                    findComponentInArray(
                      'backgroundCheckIntro',
                      blok.backGroundCheckSections,
                    )
                  "
                  class="mb-2 hidden sm:flex m-6"
                />
                <background-check-window
                  v-if="
                    findComponentInArray(
                      'ProfilePersonalIdCodeSetting',
                      blok.backGroundCheckSections,
                    )
                  "
                  :blok="blok.backGroundCheckSections"
                  :is-agreement-invite="isAgreementInvite"
                  :score="userProfile.scoreStatus"
                  @submit-id-code="
                    actionTrackInvitationSubmitBackgroundCheckEvent({
                      advertisementId: invite.advertisementDocId,
                      agreementId: invite.agreementDocId,
                      code: shortCode,
                    })
                  "
                />
              </div>
              <template
                v-else-if="currentInviteFlowStep === inviteFlowSteps.CANDIDACY_FORM"
              >
                <!-- LL contact info for mobile view -->
                <div
                  v-if="!isAgreementInvite"
                  class="bg-white rounded-lg p-4 mb-2 w-full md:hidden"
                >
                  <div class="bg-peach-100 rounded-lg p-4">
                    <h3 class="text-lg font-semibold">
                      {{ $t('advertisement_invite.contact_the_landlord') }}
                    </h3>
                    <r-profile-info-card
                      :copiable="landlordInfoCopiable"
                      :email="invite?.contact?.email"
                      :full-name="
                        invite?.contact?.firstName + ' ' + invite?.contact?.lastName
                      "
                      no-padding
                      :phone-number="
                        constructPhoneNr(
                          invite?.contact?.phoneCountryCode,
                          invite?.contact?.phoneNumber,
                        )
                      "
                    />
                  </div>
                  <div class="mt-4">
                    <r-button
                      :label="
                        $t(
                          'advertisement_invite.apply_form.button_show_contact_information',
                        )
                      "
                      size="small"
                      width="full"
                      @click="mobileInviteModalOpen = true"
                    />
                  </div>
                </div>

                <advertisement-invite-apply-form
                  v-if="!isMobileScreen"
                  class="mb-2 bg-white rounded-lg shadow-md p-6"
                  :emit-apply-in-progress="postCandidateInProgress"
                  :invite="invite"
                  :is-agreement-invite="isAgreementInvite"
                  :profile="dataForProfileInfoCard"
                  @apply-button-clicked="applyEvent"
                  @textarea-change="getMessage($event)"
                />
              </template>

              <r-title-with-picture
                v-else-if="
                  currentInviteFlowStep === inviteFlowSteps.SHOW_DELETED_CANDIDATE
                "
                class="bg-white mb-2 flex justify-center rounded-lg shadow-lg"
                :content="blok.deletedCandidacyContent"
                :image="
                  blok.deletedCandidacyImage
                    ? blok.deletedCandidacyImage.filename
                    : undefined
                "
                :redirect-button-link="redirectLinkToTenantOffer"
                :redirect-button-text="$t('buttons.browse_advertisements')"
                :title="blok.deletedCandidacyTitle"
                title-style="text-2xl"
              />
              <template
                v-else-if="currentInviteFlowStep === inviteFlowSteps.SHOW_CANDIDACY"
              >
                <background-check-window
                  v-if="scoreStatusFailed"
                  :blok="blok.backGroundCheckSections"
                  class="bg-white p-4 rounded-lg shadow-lg"
                  :is-agreement-invite="isAgreementInvite"
                  :score="postCandidateToInviteCalculatedScore"
                  @submit-id-code="
                    [
                      (firstTimeSignUpCompletedScreen = true),
                      actionTrackInvitationSubmitBackgroundCheckEvent({
                        advertisementId: invite.advertisementDocId,
                        agreementId: invite.agreementDocId,
                        code: shortCode,
                      }),
                    ]
                  "
                />
                <applied-home-detail-view
                  v-else
                  class="mb-2"
                  :first-time-sign-up="firstTimeSignUpCompletedScreen"
                  :is-agreement-invite="isAgreementInvite"
                  :owner-card-email="contact.email"
                  :owner-card-full-name="constructAgreementName(contact)"
                  :owner-card-full-phone-number="
                    invite.workflow === 'PL'
                      ? constructPhoneNr(
                          contact?.phoneCountryCode,
                          contact?.phoneNumber,
                        )
                      : null
                  "
                  :owner-card-title="$t('landlord')"
                  :owner-visible="contact"
                  :profile-card-email="userProfile.email"
                  :profile-card-full-name="
                    userProfile.firstName + ' ' + userProfile.lastName
                  "
                  :profile-card-phone-number="
                    constructPhoneNr(
                      userProfile?.phoneCountryCode,
                      userProfile?.phoneNumber,
                    )
                  "
                  :profile-card-red-button-is-loading="deleteCandidateInProgress"
                  :profile-card-red-button-text="
                    isAgreementInvite
                      ? $t('offer.revoke_candidacy')
                      : $t('advertisement_invite.not_interested_in_this_property')
                  "
                  :profile-card-title="
                    $t('advertisement_invite.apply_form.profile_card_title')
                  "
                  @profile-card-red-button-clicked="
                    invite.advertisementDocId && !deleteCandidateInProgress
                      ? deleteCandidacy(invite.advertisementDocId, undefined)
                      : deleteCandidacy(undefined, invite.agreementDocId)
                  "
                >
                  <template #tenant>
                    <r-alert
                      v-if="!tenantProfileCompleted && !isAgreementInvite"
                      class="mt-4"
                      :primary-button-label="
                        firstTimeSignUpCompletedScreen
                          ? $t(
                              'advertisement_invite.incomplete_tenant_profile_alert.button_first_time_sign_up',
                            )
                          : $t(
                              'advertisement_invite.incomplete_tenant_profile_alert.button_session',
                            )
                      "
                      :title="
                        $t('advertisement_invite.incomplete_tenant_profile_alert.title')
                      "
                      :type="alertTypes.INFORMATIVE"
                      @click-primary-button="redirectToTenantProfileSettings"
                    >
                      <div
                        v-html="
                          $t(
                            'advertisement_invite.incomplete_tenant_profile_alert.content',
                          )
                        "
                      ></div>
                    </r-alert>
                  </template>
                </applied-home-detail-view>
              </template>

              <template
                v-if="
                  currentInviteFlowStep === inviteFlowSteps.SESSION_FLOW ||
                  currentInviteFlowStep === inviteFlowSteps.BACKGROUND_CHECK
                "
              >
                <component
                  :is="theBlok.component"
                  v-for="theBlok in blok.socialProof"
                  :key="theBlok._uid"
                  :active-testimonial-id="socialProofActiveTestimonialId"
                  :blok="theBlok"
                />
              </template>
            </div>
          </div>
        </div>
        <advertisement-suggestions-block
          v-if="isAdvertisementInvite"
          :advertisement-id="invite.advertisementDocId"
          :cta-button-label="blok.suggestionsCtaButtonLabel"
          :header="blok.suggestionsHeader"
          :view-all-ads-button-label="blok.suggestionsViewAllAdsButtonLabel"
        />
      </div>
      <template #placeholder>
        <!--                Loader for fetch process               -->
        <div class="relative min-h-1/2-screen flex justify-center items-center">
          <r-loader type="animatedLogo" />
        </div>
      </template>

      <Transition name="slide-bottom">
        <div
          v-if="showMobileApplyButton"
          class="sticky bottom-0 w-full h-20 bg-white flex justify-center items-center p-4 animate-slide-up-from-bottom z-40"
        >
          <r-button
            icon="check"
            size="small"
            width="full"
            @click="mobileInviteModalOpen = true"
          >
            <span class="ml-1">{{
              $t('advertisement_invite.apply_form.call_to_apply_button_text')
            }}</span>
          </r-button>
        </div>
      </Transition>
      <r-overlay size="small" :visible="showModal" @close="closeModals()">
        <div v-if="rendinFeeModalOpen" class="flex flex-col p-4">
          <component
            :is="childBlok.component"
            v-for="childBlok in blok.rendinFeeInfoModal.filter((item) =>
              ['RichTextBlok', 'ImageBlok'].includes(item.component),
            )"
            :key="childBlok._uid"
            :blok="childBlok"
          />
        </div>
        <div v-else-if="whyDepositModalOpen" class="flex flex-col p-4">
          <component
            :is="childBlok.component"
            v-for="childBlok in modalContent.filter((item) =>
              ['RichTextBlok', 'ImageBlok'].includes(item.component),
            )"
            :key="childBlok._uid"
            :blok="childBlok"
          />
        </div>
        <div class="p-4">
          <r-button
            inverted
            :label="$t('buttons.close')"
            size="small"
            width="full"
            @click="closeModals"
          />
        </div>
      </r-overlay>
    </client-only>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { parseFullAddressFromAddressParameters } from '~/utils/addressCollector';
import {
  redirectLinks,
  BackgroundCheckResult,
  sessionFlowSteps,
  profileSourceIndications,
  postCandidateResults,
  ComponentSize,
  workflow,
  userRole,
  inviteSourceTypes,
} from '~/utils/constants';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';
import { getCurrency } from '~/utils/getCurrency.js';
import RStickyContainer from '~/components/r-interface-components/r-sticky-container';
import { constructAgreementName } from '~/utils/agreementNameConstructor';
import { constructPhoneNr } from '~/utils/phoneNumberConstructor';
// import RendinIntroduction from '~/components/snippets/RendinIntroduction';
import SocialProofWithValuePropsAndTestimonials from '~/components/snippets/SocialProofWithValuePropsAndTestimonials';
import RAlert from '~/components/r-interface-components/r-alert.vue';
import { alertTypes } from '~/components/r-interface-components/utils/constants';
import AppliedHomeDetailView from '~/components/snippets/AppliedHomeDetailView';
import { _functionsHostRegionEU, getFunctionsURL } from '~/utils/firebase';
import BackgroundCheckWindow from '~/components/section/advertisement-invite-page/BackgroundCheckWindow.vue';

const inviteFlowSteps = {
  LOADING: 'LOADING',
  ADVERTISEMENT_INVALID: 'ADVERTISEMENT_INVALID',
  AGREEMENT_INVALID: 'AGREEMENT_INVALID',
  SESSION_FLOW: 'SESSION_FLOW',
  BACKGROUND_CHECK: 'BACKGROUND_CHECK',
  CANDIDACY_FORM: 'CANDIDACY_FORM',
  SHOW_DELETED_CANDIDATE: 'SHOW_DELETED_CANDIDATE',
  SHOW_CANDIDACY: 'SHOW_CANDIDACY',
};

export default {
  name: 'AdvertisementInvitePage',
  components: {
    BackgroundCheckWindow,
    AppliedHomeDetailView,
    RStickyContainer,
    // RendinIntroduction,
    SocialProofWithValuePropsAndTestimonials,
    RAlert,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  async setup() {
    const route = useRoute();
    const shortCode = ref(route.params.code.toString().toUpperCase());
    const inviteSourceType = ref(
      route.path.includes(redirectLinks.INVITE_AGREEMENT)
        ? inviteSourceTypes.AGREEMENT
        : null,
    );
    const invite = ref({});
    const config = useRuntimeConfig();
    const fromSourceOption = inviteSourceType.value
      ? { fromSource: inviteSourceType.value }
      : null;

    console.log('testing with OLiver');

    const fetchInviteData = async () => {
      try {
        const url = getFunctionsURL(_functionsHostRegionEU, config.R_ENV);
        const { data: getPropertyDataResult, error } = await useFetch(
          `${url}/getInvite`,
          {
            method: 'POST',
            body: {
              data: {
                request: {
                  shortCode: shortCode.value,
                  ...fromSourceOption,
                },
              },
            },
          },
        );

        console.log('respons', getPropertyDataResult.value);
        if (error.value) {
          throw error.value;
        }

        invite.value = Object.assign(
          {},
          invite.value,
          getPropertyDataResult.value.result.data,
        );
      } catch (err) {
        console.log('error', err);
        //actionReportErrorToSentry(err);
      }
    };

    // Fetch invite data when the component is mounted
    await fetchInviteData();

    return {
      shortCode,
      inviteSourceType,
      invite,
      getCurrency,
      rendinFeeCalculation,
      parseFullAddressFromAddressParameters,
      constructAgreementName,
      constructPhoneNr,
    };
  },

  data() {
    return {
      contact: null,
      message: '',
      deleteCandidateInProgress: false,
      postCandidateInProgress: false,
      getPropertyDataFailed: false,
      getPropertyDataInProgress: false,
      getContactInformationInProgress: false,
      isPropertyDataNull: false,
      indicatedProfileSource: profileSourceIndications.WEB_ADVERTISEMENT_INVITE,
      showDeletedCandidateView: false,
      isGetPropertyDataDone: false,
      isMobileScreen: false,
      mobileInviteModalOpen: false,
      inviteFlowSteps: inviteFlowSteps,
      componentSizes: ComponentSize,
      currentlyAppliedToInvite: false,
      firstTimeSignUpCompletedScreen: false,
      redirectLinks: redirectLinks,
      workflow: workflow,
      emailLoginStartScreen: true,
      userRole: userRole,
      notDefaultBackButtonTitle: false,
      postCandidateToInviteCalculatedScore: null,
      useGalleryEmitted: false,
      whyDepositModalOpen: false,
      rendinFeeModalOpen: false,
      inviteSourceTypes,
      inviteNotActive: false,
      alertTypes,
      landlordInfoCopiable: false,
    };
  },

  computed: {
    ...mapGetters({
      getInviteInProgress: 'invite/getInviteInProgress',
      hasSessionUser: 'users/hasSessionUser',
      currentStep: 'session/currentStep',
      userProfile: 'users/profile',
      tenantProfileFromFirebase: 'tenants/tenantProfileFromFirebase',
      previousPagePath: 'routerHistory/getPreviousPagePath',
      isCountryIsPoland: 'getCountryIsPoland',
    }),
    currentInviteFlowStep() {
      const dataFetchDone =
        ((this.isGetPropertyDataDone && this.invite) ||
          this.isPropertyDataNull ||
          this.getPropertyDataFailed) &&
        !this.getContactInformationInProgress &&
        !this.getPropertyDataInProgress;
      const loadingDataForCandidacyView =
        this.invite.isRequesterProfileActiveCandidate &&
        (!(this.contact || this.scoreStatusFailed) || !this.userProfile);
      if (!dataFetchDone || loadingDataForCandidacyView) {
        return inviteFlowSteps.LOADING;
      }

      const isAdvertisementInvalid =
        this.isPropertyDataNull || this.getPropertyDataFailed || !this.invite.active;
      if (isAdvertisementInvalid) {
        return inviteFlowSteps.ADVERTISEMENT_INVALID;
      }

      if (this.currentStep !== sessionFlowSteps.COMPLETED || !this.userProfile) {
        this.trackLoginStartEvent({ sessionFlow: this.indicatedProfileSource });
        return inviteFlowSteps.SESSION_FLOW;
      }

      if (!this.userProfile.idCode) {
        return inviteFlowSteps.BACKGROUND_CHECK;
      }

      if (this.showDeletedCandidateView) {
        return inviteFlowSteps.SHOW_DELETED_CANDIDATE;
      }

      if (!this.invite.isRequesterProfileActiveCandidate) {
        return inviteFlowSteps.CANDIDACY_FORM;
      }

      return inviteFlowSteps.SHOW_CANDIDACY;
    },
    redirectLinkToTenantOffer() {
      return redirectLinks.TENANT_PROFILE_OFFERS;
    },

    dataForProfileInfoCard() {
      if (this.userProfile) {
        return {
          fullName: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
          email: this.userProfile.email,
          phoneNumber: this.userProfile.phoneNumberFull,
        };
      }
      return {};
    },

    scoreStatusFailed() {
      const latestScore =
        this.postCandidateToInviteCalculatedScore || this.userProfile?.scoreStatus;
      return latestScore !== BackgroundCheckResult.APPROVED;
    },

    loadingIsNotNeeded() {
      return (
        (this.isGetPropertyDataDone && !!this.invite) ||
        this.isPropertyDataNull ||
        this.getPropertyDataFailed
      );
    },

    needToFillProfile() {
      return this.currentStep === sessionFlowSteps.FILL_PROFILE;
    },
    isAdvertisementInvite() {
      return this.invite.advertisementDocId;
    },
    showMobileApplyButton() {
      return (
        this.isGetPropertyDataDone &&
        this.invite.active &&
        !this.invite.isRequesterProfileActiveCandidate &&
        this.isMobileScreen &&
        !this.mobileInviteModalOpen &&
        !this.currentlyAppliedToInvite
      );
    },
    tenantProfileCompleted() {
      if (!this.tenantProfileFromFirebase || !this.tenantProfileFromFirebase)
        return false;

      /* Personal Information */
      if (!this.userProfile.firstName) return false;
      if (!this.userProfile.lastName) return false;
      if (!this.userProfile.email) return false;
      if (!this.userProfile.phoneNumberFull) return false;

      /* Background check */
      if (!this.userProfile.scoreStatus || !this.userProfile.idCode) return false;

      /* Education & Employment */
      if (
        !(
          this.tenantProfileFromFirebase.salaryMin >= 0 &&
          this.tenantProfileFromFirebase.salaryMax > 0
        )
      )
        return false;
      if (!this.tenantProfileFromFirebase.organization) return false;
      if (!this.tenantProfileFromFirebase.educationLevel) return false;

      /* Profile Picture */
      if (!this.tenantProfileFromFirebase.shareableProfileImage) return false;

      /* About you */
      if (!this.tenantProfileFromFirebase.description) return false;

      /* Co-tenants */
      if (typeof this.tenantProfileFromFirebase.coTenantsAmount !== 'number')
        return false;
      if (
        !(
          this.tenantProfileFromFirebase.hasAPet ||
          this.tenantProfileFromFirebase.hasAPet === false
        )
      )
        return false;

      return true;
    },

    showModal() {
      return this.rendinFeeModalOpen || this.whyDepositModalOpen;
    },

    modalContent() {
      if (
        this.whyDepositModalOpen &&
        this.invite.insuranceInvoiceTo === userRole.LANDLORD &&
        this.blok.whyNoDepositLandlordPaysModal
      ) {
        return this.blok.whyNoDepositLandlordPaysModal;
      } else if (
        this.whyDepositModalOpen &&
        this.invite.insuranceInvoiceTo === userRole.TENANT &&
        this.blok.whyNoDepositTenantPaysModal
      ) {
        return this.blok.whyNoDepositTenantPaysModal;
      } else if (this.rendinFeeModalOpen && this.blok.rendinFeeInfoModalOpen) {
        return this.blok.rendinFeeInfoModalOpen;
      } else {
        return [];
      }
    },

    isAgreementInvite() {
      return this.inviteSourceType === inviteSourceTypes.AGREEMENT;
    },

    socialProofActiveTestimonialId() {
      if (
        this.currentInviteFlowStep === inviteFlowSteps.SESSION_FLOW &&
        this.needToFillProfile
      ) {
        return this.blok.socialProofTestimonialIdForm;
      } else if (this.currentInviteFlowStep === inviteFlowSteps.SESSION_FLOW) {
        return this.blok.socialProofTestimonialIdLogin;
      } else if (this.currentInviteFlowStep === inviteFlowSteps.BACKGROUND_CHECK) {
        return this.blok.socialProofTestimonialIdBackgroundCheck;
      }

      return null;
    },
  },

  watch: {
    invite() {
      if (this.invite && this.invite.isRequesterProfileActiveCandidate) {
        this.getContactInformation();
      }
    },
    currentStep: {
      handler(newValue) {
        if (newValue === sessionFlowSteps.COMPLETED) {
          this.getPropertyData();
        }
      },
    },
  },
  beforeMount() {
    this.onResize();
  },
  mounted() {
    this.declareBackButtonTitleByPreviousPagePath();
    this.getShortCodeFromUrl();
    this.getPropertyData().then(() => {
      this.actionTrackInvitationPageView({
        advertisementId: this.invite.advertisementDocId,
        agreementId: this.invite.agreementDocId,
        invitationShortCode: this.shortCode,
      });
    });
    this.getContactInformation();
    window.addEventListener('resize', this.onResize);
    this.setOverridenSuccessfulRedirect(
      this.$localizedPath(this.$router.currentRoute.path),
    );
    this.setSignInModalDisabledByOtherFlow();
  },
  beforeUnmount() {
    this.resetOverridenSuccesfulRedirect();
    this.resetSignInModalDisabledByOtherFlow();
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    ...mapMutations({
      setOverridenSuccessfulRedirect: 'session/SET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
      resetOverridenSuccesfulRedirect: 'session/RESET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
      setSignInModalDisabledByOtherFlow:
        'session/SET_SIGN_IN_MODAL_DISABLED_BY_OTHER_FLOW',
      resetSignInModalDisabledByOtherFlow:
        'session/RESET_SIGN_IN_MODAL_DISABLED_BY_OTHER_FLOW',
    }),
    ...mapActions({
      actionGetInvite: 'invite/getInvite',
      actionGetContactInformation: 'invite/getContactInformation',
      actionGetTenantProfile: 'tenants/requestTenantProfile',
      actionDeleteCandidacy: 'candidacies/deleteCandidacy',
      actionPostCandidateToInvite: 'invite/postCandidateToInvite',
      actionReportErrorToSentry: 'tracker/reportErrorToSentry',
      actionTrackInvitationStartLoginEvent: 'tracker/trackInvitationStartLoginEvent',
      actionTrackInvitationFillProfileEvent: 'tracker/trackInvitationFillProfileEvent',
      actionTrackInvitationSubmitBackgroundCheckEvent:
        'tracker/trackInvitationSubmitBackgroundCheckEvent',
      actionTrackInvitationApplyAsCandidateEvent:
        'tracker/trackInvitationApplyAsCandidateEvent',
      actionTrackInvitationCancelCandidacyEvent:
        'tracker/trackInvitationCancelCandidacyEvent',
      actionTrackInvitationUseGallery: 'tracker/trackInvitationUseGallery',
      actionTrackInvitationClickWhyNoDeposit:
        'tracker/trackInvitationClickWhyNoDeposit',
      actionTrackInvitationClickRendinFeeInfoModal:
        'tracker/trackInvitationClickRendinFeeInfoModal',
      actionGoToPreviousPage: 'routerHistory/goToPreviousPage',
      actionTrackInvitationPageView: 'tracker/trackInvitationPageView',
      trackLoginStartEvent: 'tracker/trackLoginStartEvent',
    }),

    closeModals() {
      this.whyDepositModalOpen = false;
      this.rendinFeeModalOpen = false;
    },

    declareBackButtonTitleByPreviousPagePath() {
      if (this.previousPagePath) {
        this.notDefaultBackButtonTitle = true;
      }
    },

    findComponentInArray(componentName, componentsArray) {
      for (const component in componentsArray) {
        if (
          componentsArray[component].component &&
          componentsArray[component].component === componentName
        ) {
          return componentsArray[component];
        }
      }
      return false;
    },

    goToPreviousPage(fallbackUrl) {
      this.actionGoToPreviousPage(fallbackUrl);
    },

    onResize() {
      const width = window.innerWidth;

      if (width > 767) {
        this.isMobileScreen = false;
      } else {
        this.isMobileScreen = true;
      }
    },

    getPropertyData() {
      this.getPropertyDataInProgress = true;
      return this.actionGetInvite({
        inviteShortCode: this.shortCode,
        fromSource: this.inviteSourceType,
      })
        .then((result) => {
          this.isGetPropertyDataDone = true;
          if (result?.response?.success && result?.response?.data?.active) {
            this.invite = Object.assign({}, this.invite, result.response.data);
          } else if (result?.response?.success && !result?.response?.data?.active) {
            this.inviteNotActive = true;
          } else {
            this.isPropertyDataNull = true;
          }
        })
        .catch((err) => {
          this.actionReportErrorToSentry(err);
          this.getPropertyDataFailed = true;
        })
        .finally(() => {
          this.getPropertyDataInProgress = false;
        });
    },

    getContactInformation() {
      if (
        this.userProfile &&
        !this.scoreStatusFailed &&
        !this.getContactInformationInProgress
      ) {
        this.getContactInformationInProgress = true;
        return this.actionGetContactInformation({ inviteShortCode: this.shortCode })
          .then((result) => {
            this.contact = result.response;
          })
          .finally(() => {
            this.getContactInformationInProgress = false;
          });
      }

      return Promise.resolve();
    },
    getMessage(message) {
      this.message = message;
    },

    getShortCodeFromUrl() {
      if (this.$route.path.includes(redirectLinks.INVITE_AGREEMENT)) {
        this.inviteSourceType = inviteSourceTypes.AGREEMENT;
      }

      if (this.$route.params.code) {
        this.shortCode = this.$route.params.code.toString().toUpperCase();
      }
    },

    applyEvent() {
      this.postCandidateInProgress = true;
      this.currentlyAppliedToInvite = true;

      this.actionTrackInvitationApplyAsCandidateEvent({
        advertisementId: this.invite.advertisementDocId,
        agreementId: this.invite.agreementDocId,
        code: this.shortCode,
        fromSource: this.inviteSourceType,
      });

      this.actionPostCandidateToInvite({
        inviteShortCode: this.shortCode,
        candidate: {
          idCode: this.userProfile.idCode,
          email: this.userProfile.email,
          phone: this.userProfile.phoneNumber,
          fullName: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
          message: this.message,
        },
      })
        .then((result) => {
          this.postCandidateToInviteCalculatedScore = result.response?.calculatedScore;

          if (result.response === postCandidateResults.DELETED_CANDIDATE) {
            this.showDeletedCandidateView = true;
          } else if (result.response?.calculatedScore) {
            this.$trackEvent(
              'New applicant',
              {
                code: this.shortCode,
              },
              {
                services: ['POSTHOG', 'GTAG', 'FB'],
              },
            );
          }
        })
        .then(() => Promise.all([this.getPropertyData(), this.getContactInformation()]))
        .then(() => {
          this.currentlyAppliedToInvite = true;
          this.mobileInviteModalOpen = false;
          /* Scroll to candidacy detail and contact info */
          this.$nextTick(() => {
            this.$scrollTo(this.$refs.rightSide);
          });
        })
        .catch((err) => {
          this.actionReportErrorToSentry(err);
        })
        .finally(() => {
          this.postCandidateInProgress = false;
        });
    },

    deleteCandidacy(advertisementId, agreementId) {
      this.deleteCandidateInProgress = true;
      this.currentlyAppliedToInvite = false;
      this.actionTrackInvitationCancelCandidacyEvent({
        advertisementId: this.invite.advertisementDocId,
        agreementId: this.invite.agreementDocId,
        code: this.shortCode,
      });
      this.actionDeleteCandidacy({
        profileId: this.userProfile.id,
        agreementId: agreementId,
        advertisementId: advertisementId,
      })
        .then(() => this.getPropertyData())
        .then(() => {
          this.deleteCandidateInProgress = false;
        })
        .catch((err) => {
          this.actionReportErrorToSentry(err);
        });
    },

    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;

      window.scrollTo(0, top);
    },

    onUseGallery() {
      if (!this.useGalleryEmitted) {
        this.actionTrackInvitationUseGallery({
          advertisementId: this.invite.advertisementDocId,
          agreementId: this.invite.agreementDocId,
          code: this.shortCode,
        });

        this.useGalleryEmitted = true;
      }
    },
    onWhyDepositClick() {
      this.whyDepositModalOpen = true;
      this.actionTrackInvitationClickWhyNoDeposit({
        advertisementId: this.invite.advertisementDocId,
        agreementId: this.invite.agreementDocId,
        code: this.shortCode,
      });
    },

    openRendinFeeInfoModal() {
      this.rendinFeeModalOpen = true;

      this.actionTrackInvitationClickRendinFeeInfoModal({
        advertisementId: this.invite.advertisementDocId,
        agreementId: this.invite.agreementDocId,
        code: this.shortCode,
      });
    },

    redirectToTenantProfileSettings() {
      this.$router.push({
        path: this.$localizedPath(redirectLinks.TENANT_PROFILE_SETTINGS),
      });
    },
  },

  head() {
    if (this.invite) {
      return {
        title: `${this.$t('offer.title')}, ${this.$t('offer.rooms')} ${this.invite.numberOfRooms}, ${this.invite.addressCity}, ${this.invite.rentAmount} ${this.invite.currency}`,
        meta: [
          {
            hid: 'og:title',
            property: 'og:title',
            content: `${this.$t('offer.title')}, ${this.$t('offer.rooms')} ${this.invite.numberOfRooms}, ${this.invite.addressCity}, ${this.invite.rentAmount} ${this.invite.currency}`,
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.$t('meta.rendin_description'),
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: `${this.$getHost()}${this.$route.fullPath}`,
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: this.invite.images ? this.invite.images[0] : '',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            hid: 'description',
            name: 'description',
            content: this.invite.description
              ? this.invite.description.substring(0, 155)
              : this.$t('meta.rendin_description'),
          },
        ],
      };
    } else {
      return {
        title: 'DEFAULT',
      };
    }
  },
};
</script>
<style>
@keyframes slideOutDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

/*.slide-bottom-leave-active {
  animation-name: slideOutDown;
  animation-duration: 0.1s;
  animation-timing-function: ease-in;
}*/

@keyframes slideUpFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.slide-bottom-enter-active {
  animation-name: slideUpFromBottom;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}

.slide-right-enter-active {
  animation-name: slideInFromRight;
  animation-delay: 2s;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
  transform: translateX(100vw);
}
</style>
