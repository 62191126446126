<!-- Should be converted into a Composable when we move to Nuxt Bridge / Nuxt 3. Currently did not have access to VueX -->
<script>
import { mapActions } from 'vuex';
import Timer from '~/utils/timer.js';

export default {
  name: 'SessionLengthEvents',

  data() {
    return {
      timers: [],
    };
  },

  mounted() {
    this.initTimers();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },

  beforeUnmount() {
    this.cleanupTimers();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    ...mapActions({
      trackSessionLengthEvent: 'tracker/trackSessionLengthEvent',
    }),
    initTimers() {
      const timers = [
        {
          duration: 30,
          name: 'Session: 30 sec',
        },
        {
          duration: 60,
          name: 'Session: 1 min',
        },
        {
          duration: 3 * 60,
          name: 'Session: 3 min',
        },
      ];

      this.timers = timers.map((timerData) => {
        const eventData = {
          eventName: timerData.name,
          props: {
            durationInSeconds: timerData.duration,
          },
        };

        const timer = new Timer(timerData.duration, () =>
          this.trackSessionLengthEvent(eventData),
        );

        timer.start();
        return timer;
      });
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.timers.forEach((timer) => timer.pause());
      } else {
        this.timers.forEach((timer) => timer.start()); // This resumes timer but might not handle situations where timers should not restart but continue
      }
    },
    cleanupTimers() {
      this.timers.forEach((timer) => timer.stop());
    },
  },
  render() {
    return '';
  },
};
</script>
