import { default as indexOg8s9uI1AGMeta } from "/workspace/pages/examples/index.vue?macro=true";
import { default as r_45interface_45componentsWvBLFR7TqOMeta } from "/workspace/pages/r-interface-components.vue?macro=true";
import { default as _91_46_46_46page_93TnZK5Y8EGAMeta } from "/workspace/pages/[...page].vue?macro=true";
import { default as _91_46_46_46page_93G2N8thPrnQMeta } from "/workspace/pages/non-storybloks/[...page].vue?macro=true";
export default [
  {
    name: "examples",
    path: "/examples",
    component: () => import("/workspace/pages/examples/index.vue").then(m => m.default || m)
  },
  {
    name: "r-interface-components",
    path: "/r-interface-components",
    component: () => import("/workspace/pages/r-interface-components.vue").then(m => m.default || m)
  },
  {
    name: "object_view",
    path: "/:lang?/dashboard/object/:objectid",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "agreement_view",
    path: "/:lang?/dashboard/agreement/:agreementid",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "profile_unique_id",
    path: "/:lang?/tenants/profile/:profileid",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "invite_unique_id",
    path: "/:lang?/invite/form/:code",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "invite_unique_id_agreement",
    path: "/:lang?/invite/agreement/:code",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/:lang?/nstbc/:page",
    component: () => import("/workspace/pages/non-storybloks/[...page].vue").then(m => m.default || m)
  },
  {
    name: "sitemap_generation",
    path: "/sitemap.xml",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:page(.*)*",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "non-storybloks-page",
    path: "/non-storybloks/:page(.*)*",
    component: () => import("/workspace/pages/non-storybloks/[...page].vue").then(m => m.default || m)
  },
  {
    name: "custom",
    path: "/:pathMatch(.*)*",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  }
]