<template>
  <r-button
    :id="blok.id"
    v-editable="blok"
    v-scroll-to="blok.action === 'scroll' ? blok.scroll_target : false"
    :border-color="blok.borderColor"
    :color="blok.color"
    :href="
      (blok.action === 'link' && !isNuxtLink(blok)) || blok.action === 'document'
        ? getExternalLink(blok)
        : ''
    "
    :inverted="blok.style === 'secondary'"
    :no-background="blok.style === 'link'"
    :shape="blok.shape"
    :size="blok.size"
    :target="isNuxtLink(blok) ? '_self' : '_blank'"
    :to="
      !(blok.action === 'logIn' || blok.action === 'agreementLogIn') && isNuxtLink(blok)
        ? $localizedPath(getNuxtLink(blok))
        : ''
    "
    :width="blok.width ? blok.width : 'wide'"
    @click="dispatchButton(blok)"
  >
    <span>
      {{ hasSessionUser && blok.textSession ? blok.textSession : blok.text }}
    </span>
    <IconBlok
      v-if="
        blok.suffix &&
        blok.suffix.length === 1 &&
        blok.suffix[0].component === 'IconBlok'
      "
      :blok="blok.suffix[0]"
      class="px-2"
    />
  </r-button>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ButtonBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  emits: ['closeModal', 'click'],

  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
    }),
  },

  methods: {
    ...mapActions({
      reportErrorToSentry: 'tracker/reportErrorToSentry',
    }),
    ...mapMutations({
      setSignInModal: 'session/SET_SIGN_IN_MODAL',
      setOverridenSuccessfulRedirect: 'session/SET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
      resetOverridenSuccesfulRedirect: 'session/RESET_OVERRIDDEN_SUCCESSFUL_REDIRECT',
    }),
    isNuxtLink(blok) {
      return blok.action === 'link' && blok.link.linktype === 'story';
    },

    getExternalLink(blok) {
      if (blok.action === 'link' && blok.link.url) {
        let url = blok.link.url;

        if (blok.link.url.includes('documentsScreen')) {
          url = `${blok.link.url}&language=${this.$getLocale().toUpperCase()}`;
        }
        if (
          this.blok.typeformHiddenFieldName &&
          this.$attribution?.params()?.query?.gclid
        ) {
          url = `${url}#${this.blok.typeformHiddenFieldName}=${
            this.$attribution?.params()?.query?.gclid
          }`;
        }

        return url;
      } else if (blok.action === 'document' && blok.document.filename) {
        return blok.document.filename;
      } else {
        return '/';
      }
    },

    getNuxtLink(blok) {
      if (blok.action === 'link' && blok.link.linktype === 'story') {
        if (blok.queryParameter) {
          return blok.link.cached_url + blok.queryParameter;
        } else {
          return blok.link.cached_url;
        }
      } else {
        return '/';
      }
    },

    dispatchButton(blok) {
      if (blok.action === 'logIn' || blok.action === 'agreementLogIn') {
        if (blok.link && blok.link.linktype === 'story') {
          this.setOverridenSuccessfulRedirect(
            this.$localizedPath(blok.link.cached_url),
          );
        } else {
          this.setOverridenSuccessfulRedirect(
            this.$localizedPath(this.$router.currentRoute.path),
          );
        }
        if (!this.hasSessionUser) {
          return this.setSignInModal({
            isSignInModalOpen: true,
            isAgreementFlowNeeded: blok.action === 'agreementLogIn',
          });
        } else if (blok.link && blok.link.linktype === 'story') {
          return this.$router.push({ path: this.$localizedPath(blok.link.cached_url) });
        } else {
          return (window.location.href = blok.link.url);
        }
      }
      /* Functionality - open External URL in separate tab and then redirect user to another internal page */
      /* For example Download & Redirect */
      const isInternalRedirectLinkSet =
        blok.redirectLink?.id && blok.redirectLink?.linktype == 'story';

      if (isInternalRedirectLinkSet) {
        /* Sanity checks - this functionality does not make sense for internal buttons and scroll actions */
        if (!this.isNuxtLink(blok) && blok.action !== 'scroll') {
          setTimeout(() => {
            this.$router.push({
              path: this.$localizedPath(blok.redirectLink.cached_url),
            });
          }, 100);
        }
      }

      if (blok.function) {
        if (blok.function === 'closeModal') {
          this.$emit('closeModal');
        } else if (blok.scroll_target) {
          this.$store.dispatch(blok.function, blok.scroll_target);
        } else {
          this.reportErrorToSentry(
            new Error(
              `ERROR: Button Blok tries to call unknown function (pls report to Oliver): ${blok.function}`,
            ),
          );
          // this.$store.dispatch(blok.function);
        }
      }

      this.$emit('click');
    },
  },
};
</script>
