<template>
  <div v-editable="blok" class="digibroker-nav h-20">
    <div class="z-20 md:fixed right-0 left-0">
      <div
        class="digibroker-nav-inner bg-gray-100 mx-auto w-full top-14 lg:top-20 pr-4"
      >
        <div class="py-2 max-w-6xl mx-auto">
          <div class="flex justify-between items-center gap-4">
            <client-only>
              <div class="flex">
                <r-button
                  class="hover:no-underline focus:no-underline"
                  :class="{ invisible: !backButtonActive }"
                  color="gray"
                  no-background
                  @click="onClickBack()"
                >
                  <span class="sr-only">Close</span>
                  <r-icon class="text-3xl" icon-name="chevron-left" />
                </r-button>
              </div>
            </client-only>
            <div class="flex items-center justify-center">
              <img
                :alt="blok.photo.alt"
                class="rounded-full h-16 w-16 mr-4"
                :src="$transformStoryblokImage(blok.photo.filename, '120x120/smart')"
              />
              <div>
                <h3 class="text-rendin-500 font-bold">
                  {{ blok.name }}
                </h3>
                <div class="text-gray-500 text-xs w-2/3 sm:w-full">
                  {{ blok.profession }}
                </div>
              </div>
            </div>
            <div class="whitespace-nowrap flex justify-end">
              <div v-if="getTopNavHidden">
                <r-button
                  border-color="light-gray"
                  class="hidden sm:block"
                  color="black"
                  hover="hover:text-rendin-500 hover:shadow-md"
                  inverted
                  :label="
                    hasSessionUser && saveButtonActive
                      ? blok.saveAndExitButton
                      : blok.exitButton
                  "
                  @click="$emit('clickSaveAndExit')"
                />

                <r-button
                  class="sm:hidden"
                  color="gray"
                  icon="bars"
                  icon-class="text-2xl"
                  no-background
                  no-padding
                  @click="exitMenuMobile = true"
                />
                <!-- Close dropdown for -->
                <div
                  v-if="exitMenuMobile"
                  v-click-outside="closeExitMenuMobile"
                  class="bg-white absolute top-0 right-0 p-6 rounded-lg shadow-lg sm:hidden z-10"
                >
                  <div class="flex justify-end mb-8">
                    <r-button
                      color="gray"
                      icon="xmark"
                      icon-class="text-2xl"
                      no-background
                      no-padding
                      @click="closeExitMenuMobile()"
                    />
                  </div>

                  <r-button
                    class="mr-12 my-4"
                    color="black"
                    :label="
                      hasSessionUser && saveButtonActive
                        ? blok.saveAndExitButton
                        : blok.exitButton
                    "
                    no-background
                    no-padding
                    @click="$emit('clickSaveAndExit')"
                  >
                    <r-icon class="text-2lx ml-2" icon-name="right-from-line" />
                  </r-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <client-only>
          <div class="w-full rounded-full h-1.5">
            <div
              class="bg-rendin-500 h-1.5 transition-width"
              :style="{ width: currentProgressPercentage }"
            ></div>
          </div>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { redirectLinks } from '~/utils/constants';

export default {
  name: 'DigibrokerNav',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    backButtonActive: {
      type: Boolean,
      default: true,
    },
    saveButtonActive: {
      type: Boolean,
      default: true,
    },
    currentProgressPercentage: {
      type: String,
      required: false,
      default: '0%',
    },
  },
  emits: ['clickSaveAndExit', 'clickBack'],

  data() {
    return {
      redirectLinks: redirectLinks,
      exitMenuMobile: false,
    };
  },

  computed: {
    ...mapGetters({
      hasSessionUser: 'users/hasSessionUser',
      getTopNavHidden: 'getTopNavHidden',
    }),
  },

  mounted() {
    if (this.blok.hideTopNav === true) {
      this.actionHideNav(true);
    }
  },

  beforeUnmount() {
    if (this.blok.hideTopNav === true) {
      this.actionHideNav(false);
    }
  },

  methods: {
    ...mapActions({
      actionHideNav: 'hideNav',
    }),

    closeExitMenuMobile() {
      if (this.exitMenuMobile) this.exitMenuMobile = false;
    },

    onClickBack() {
      /**
       * Click even for back button
       *
       * @event clickBack
       */
      this.$emit('clickBack');
    },
  },
};
</script>

<style>
.digibroker-nav {
  z-index: 11;
}

.digibroker-nav-inner {
  max-width: 1920px;
}
</style>
