<template>
  <div class="mt-1 r-input-address">
    <div>
      <label
        v-if="label || $slots.default"
        :class="[
          labelClass,
          {
            'text-error': hasValidationError,
            'font-medium': flavor === 'medium',
          },
        ]"
        :for="id"
      >
        <slot>{{ label }}</slot>
        <span v-if="required">{{ requiredLabel }}</span>
      </label>
      <div v-click-outside="hideDropdown" class="relative">
        <input
          :id="id"
          ref="addressInput"
          v-model="fullSearchString"
          autocomplete="off"
          class="focus:outline-none w-full text-sm leading-4 text-gray-800 placeholder-gray-400 border border-gray-300 rounded-lg shadow-sm px-3 py-3 pr-10 h-12"
          :class="classes"
          :data-testid="dataTestid"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          type="text"
          @focus="onFocus"
          @input="onSearchInput"
        />
        <span
          class="absolute z-10 right-3 top-2 text-sm text-gray-500"
          @click="onSearchInput"
        >
          <template v-if="findAddressInProgress">
            <r-loader size="20" />
          </template>
        </span>

        <div
          v-if="searchDropdownOpen"
          class="absolute z-10 right-0 left-0 top-13 w-full bg-white shadow-md max-w-full max-h-72 overflow-y-auto"
        >
          <p
            v-if="formattedAddressPredictions?.length > 0"
            class="py-2 px-3 text-xxs font-bold"
          >
            {{ $t('form.help.select_address_from_list') }}
          </p>
          <div v-if="findAddressFailed" class="text-error p-4">
            {{ $t('form.validation.address_search_failed') }}
          </div>
          <ul>
            <div v-if="formattedAddressPredictions?.length > 0">
              <li
                v-for="(prediction, index) in formattedAddressPredictions"
                :key="index"
                class="py-2 px-4 hover:bg-gray-50 focus:bg-gray-50 flex gap-4 items-center border-b border-gray-200"
                :data-testid="'component.address-search.option-' + index"
                @click="selectPrediction(prediction)"
              >
                <r-icon class="text-2xl text-gray-500" icon-name="house-chimney-user" />
                <div class="flex flex-col">
                  <span v-html="prediction.descriptionHTML"></span>
                  <span class="text-gray-500 leading-tight">
                    {{ prediction.result.structured_formatting.secondary_text }}
                  </span>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div ref="attribution" class="hidden"></div>
    <div
      v-if="hasValidationError"
      class="text-error text-sm"
      :class="{ 'font-medium': flavor === 'medium' }"
    >
      <div v-for="(rule, index) in failedRules" :key="index">
        {{ rule }}
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { defaultAddressObject } from '~/utils/objectStructures';
import { PredictionProvider } from '~/utils/constants';

function highlightDescriptionInGoogleAutocompletePredictionAsHTML(
  description,
  matchedSubstrings,
) {
  const sortedMatchedSubstrings = [...matchedSubstrings].sort(
    (a, b) => a.offset - b.offset,
  );

  let result = ''; // Variable to hold the new string
  let currentIndex = 0; // Variable to keep track of your position in the original string

  // Iterate through the sorted array
  sortedMatchedSubstrings.forEach((match) => {
    // Append the text before the match, and the highlighted match, to the result
    const start = match.offset;
    const end = match.offset + match.length;
    result += description.slice(currentIndex, start);
    result += `<span class="font-bold">${description.slice(start, end)}</span>`;
    currentIndex = end; // Update the current index to the end of the match
  });

  // Append any remaining text after the last match
  result += description.slice(currentIndex);

  // Return the updated description
  return result;
}

export default {
  name: 'InputGoogleRawAddressSearch',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Flavor for label font weight | [ `default`, `medium`,]
     */
    flavor: {
      type: String,
      default: 'default',
    },
    validationFailed: {
      type: Boolean,
    },
    validationPassed: {
      type: Boolean,
    },
    focusOnLoad: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
    },
    requiredLabel: {
      type: String,
      default: '*',
    },
    openDropdownOnFocus: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    failedRules: {
      type: Object,
      default: null,
    },
    source: {
      type: String,
      required: true,
    },
    dataTestid: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['input'],
  data() {
    return {
      fullSearchString: '',
      autocompleteServiceSessionToken: null,
      PredictionProvider: PredictionProvider,
      currentPredictionProvider: PredictionProvider.GOOGLE,
      address: defaultAddressObject(),
      addressPredictions: [],
      isAddressSearchDone: false,
      searchDropdownOpen: false,
      autocompleteService: null,
      id: uuidv4(),
      actions: {
        trackGoogleAutocompletePredictions: () => undefined,
      },
    };
  },
  computed: {
    ...mapGetters({
      findAddressInProgress: 'address/findAddressInProgress',
      findAddressFailed: 'address/findAddressFailed',
    }),
    minimumLengthToStartSearch() {
      return 1;
    },
    formattedAddressPredictions() {
      if (this.addressPredictions === null) {
        return [];
      }

      return this.addressPredictions.map((prediction) => {
        const descriptionHTML =
          highlightDescriptionInGoogleAutocompletePredictionAsHTML(
            prediction.result.structured_formatting.main_text,
            prediction.result.structured_formatting.main_text_matched_substrings,
          );
        return {
          type: prediction.type,
          descriptionHTML: descriptionHTML,
          result: prediction.result,
        };
      });
    },
    classes() {
      const classes = [];

      if (this.hasValidationError) {
        classes.push('border-error focus:border-error hover:border-error');
      }
      if (this.validationPassed) {
        classes.push(
          'border-success-strong focus:border-success-strong hover:border-success-strong',
        );
      }
      if (!this.disabled) {
        classes.push('focus:border-gray-500 hover:border-gray-500');
      } else {
        classes.push('cursor-not-allowed');
      }

      return classes;
    },
    hasValidationError() {
      const hasFailedRules = this.failedRules?.length > 0;

      return this.validationFailed || hasFailedRules;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.fullSearchString = newValue?.formatted_address;
      },
      deep: true,
    },
  },
  created() {
    this.actions.trackGoogleAutocompletePredictions = debounce(
      (searchString, predictions) => {
        this.trackAddressSearchEvent({
          addressInputValue: searchString,
          results: predictions.map((prediction) => prediction.description),
          predictionProvider: this.currentPredictionProvider,
          source: this.source,
        });
      },
      500,
    );

    if (this.value) {
      this.fullSearchString = this.value?.formatted_address;
    }
  },
  mounted() {
    this.initGoogleAutocomplete();

    if (this.focusOnLoad) {
      this.$nextTick(() => {
        this.$refs.addressInput.focus();
      });
    }
  },
  methods: {
    ...mapActions({
      actionFindAddress: 'address/findAddress',
      actionFindPlaceById: 'address/findPlaceById',
      trackAddressSearchEvent: 'tracker/trackAddressSearchEvent',
      trackAddressSelectClickEvent: 'tracker/trackAddressSelectClickEvent',
    }),
    findAddress() {
      this.fetchPredictionsFromGoogleAutocomplete();
    },
    showDropdown() {
      this.searchDropdownOpen = true;
    },
    hideDropdown() {
      this.searchDropdownOpen = false;

      this.clearPredictions();
    },
    async selectPrediction(prediction) {
      this.searchDropdownOpen = false;
      const { PlacesService } = await google.maps.importLibrary('places');

      const placesService = new PlacesService(this.$refs.attribution);

      const request = {
        placeId: prediction.result.place_id,
        fields: ['geometry', 'formatted_address', 'address_components'],
      };

      placesService.getDetails(request, (result) => {
        this.$emit('input', result);
      });

      // Reset Session ID after successful selection according to Google's guidelines
      const { AutocompleteSessionToken } = await google.maps.importLibrary('places');
      this.autocompleteServiceSessionToken = new AutocompleteSessionToken();
    },
    clearPredictions() {
      this.addressPredictions = [];
    },
    onFocus() {
      if (this.openDropdownOnFocus) {
        this.searchDropdownOpen = true;

        if (this.fullSearchString < this.minimumLengthToStartSearch) {
          this.clearPredictions();
        }

        this.findAddress();
      }
    },
    onSearchInput() {
      this.searchDropdownOpen = true;

      if (this.fullSearchString < this.minimumLengthToStartSearch) {
        this.clearPredictions();
      }

      this.findAddress();
    },
    fetchPredictionsFromGoogleAutocomplete() {
      const language = this.$getLocale(); // et, en, pl

      if (typeof this.fullSearchString !== 'string') {
        return;
      }

      if (!this.autocompleteService) {
        return;
      }

      const request = {
        input: this.fullSearchString,
        language: language,
        componentRestrictions: {
          country: 'pl', // ee
        },
        sessionToken: this.autocompleteServiceSessionToken,
        types: ['address'],
      };

      const predictionsCallback = (predictions) => {
        if (predictions === null) {
          return;
        }

        this.addressPredictions = predictions.map((prediction) => {
          return {
            type: PredictionProvider.GOOGLE,
            result: prediction,
          };
        });

        this.actions.trackGoogleAutocompletePredictions(
          this.fullSearchString,
          predictions,
        );
      };
      this.autocompleteService.getPlacePredictions(request, predictionsCallback);
    },
    async initGoogleAutocomplete() {
      const { AutocompleteService, AutocompleteSessionToken } =
        await google.maps.importLibrary('places');
      this.autocompleteService = new AutocompleteService();
      this.autocompleteServiceSessionToken = new AutocompleteSessionToken();
    },
  },
};
</script>
