<template>
  <div class="sm:grid">
    <div
      class="flex flex-col-reverse sm:grid sm:grid-cols-2 grid-flow-col-dense"
      :class="tabClasses(tabs.START)"
    >
      <!-- Form Column -->
      <div
        class="grid"
        :class="[
          blok.FormColumnDesktopBackground
            ? columnBackgroundColor(blok.FormColumnDesktopBackground)
            : '',
          { 'col-start-2': blok.switchColumnsOrder },
        ]"
      >
        <Form v-slot="{ handleSubmit }">
          <form
            autocomplete="off"
            class="flex flex-col items-stretch gap-4 max-w-2xl justify-center h-full"
            :class="[
              contentBodyClasses,
              blok.switchColumnsOrder ? 'justify-self-start' : 'justify-self-end',
            ]"
            @submit.prevent="
              [
                handleSubmit(() => submit()),
                (validateEventTriggered = true),
                (submitAtleastOnce = true),
              ]
            "
          >
            <slot name="title">
              <p class="text-3xl self-stretch">{{ blok.startTitle }}</p>
              <p class="text-base self-stretch">{{ blok.startDescription }}</p>
            </slot>
            <div class="self-stretch">
              <Field
                v-if="isPhoneEnabled"
                v-slot="{ meta }"
                v-model="phoneNumberData"
                name="phoneNumber"
                :rules="`required|isValidTelInput:${phoneNumberData.isValid}`"
                slim
              >
                <r-input-tel
                  id="phoneNumber"
                  v-model="phoneNumberData"
                  :default-country="$getCountry()"
                  :error-text="$t('form.validation.phone')"
                  for="phoneNumber"
                  name="phoneNumber"
                  :placeholder="$t('form.placeholder.your_phone')"
                  :validation-failed="validateEventTriggered ? !meta.valid : null"
                  :validation-passed="validateEventTriggered ? meta.valid : null"
                  @focus="validateEventTriggered = true"
                />
              </Field>
              <Field
                v-if="isEmailEnabled"
                v-slot="{ meta }"
                name="email"
                rules="required|email"
                slim
              >
                <r-input
                  id="email"
                  v-model="email"
                  class="mt-1 pb-4"
                  :error-text="$t('form.validation.email')"
                  field-type="email"
                  for="email"
                  name="email"
                  :placeholder="
                    blok.emailPlaceholder || $t('form.placeholder.your_email')
                  "
                  :validation-failed="!meta.valid"
                  :validation-passed="meta.valid"
                />
              </Field>
            </div>
            <r-button :is-loading="submitInProgress" type="submit" width="full">
              {{ blok.submitButtonLabel }}
            </r-button>
            <r-button
              v-if="blok.cancelButtonLabel"
              color="orange"
              no-background
              width="full"
              @click="$emit('hide')"
            >
              {{ blok.cancelButtonLabel }}
            </r-button>
            <p class="text-xs" v-html="consentHTML"></p>
          </form>
        </Form>
      </div>

      <!-- Image or Content Column -->
      <div
        :class="[
          blok.ContentColumnDesktopBackground
            ? columnBackgroundColor(blok.ContentColumnDesktopBackground)
            : '',
          { 'col-start-1 justify-self-end': blok.switchColumnsOrder },
        ]"
      >
        <img
          v-if="blok.image?.filename"
          class="w-full h-full object-cover"
          :src="$transformStoryblokImage(blok.image.filename, '/m/')"
        />
        <div
          v-else-if="blok.content"
          class="flex flex-col items-stretch max-w-2xl justify-self-end px-8 lg:px-24 pt-6 lg:py-20"
        >
          <component
            :is="theBlok.component"
            v-for="theBlok in blok.content"
            :key="theBlok._uid"
            :blok="theBlok"
            :class="blok.content.component"
          />
        </div>
      </div>
    </div>

    <!-- Success tab-->
    <div
      class="flex flex-col-reverse sm:grid sm:grid-cols-2 grid-flow-col-dense"
      :class="tabClasses(tabs.SUCCESS)"
    >
      <div
        class="grid"
        :class="[
          blok.FormColumnDesktopBackground
            ? columnBackgroundColor(blok.FormColumnDesktopBackground)
            : '',
          { 'col-start-2': blok.switchColumnsOrder },
        ]"
      >
        <div
          class="flex flex-col items-stretch gap-4 max-w-2xl justify-center h-full w-full"
          :class="[
            contentBodyClasses,
            blok.switchColumnsOrder ? 'justify-self-start' : 'justify-self-end',
          ]"
        >
          <p class="text-3xl self-stretch">{{ blok.successTitle }}</p>
          <p class="text-base self-stretch">{{ blok.successDescription }}</p>
          <r-button
            v-if="blok.successButtonLabel"
            class="pt-4"
            :to="
              blok.successButtonLink?.cached_url
                ? $localizedPath(blok.successButtonLink.cached_url)
                : null
            "
            width="full"
            @click="$emit('hide')"
          >
            {{ blok.successButtonLabel }}
          </r-button>
        </div>
      </div>
      <div
        class="flex items-center justify-center"
        :style="{ backgroundColor: blok?.backgroundColor?.color }"
      >
        <img
          v-if="blok.successImage?.filename"
          class="w-full h-full object-cover"
          :src="$transformStoryblokImage(blok.successImage.filename, '/m/')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { backgroundColors } from '~/utils/constants';
import { replaceWithParameters } from '~/utils/strings';
import { Field, Form } from 'vee-validate';

const tabs = {
  START: 'START',
  SUCCESS: 'SUCCESS',
};

export default {
  name: 'LeadsGeneratorMolecule',
  components: { Field, Form },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    contentBodyClasses: {
      type: [Array, String],
      required: false,
      default: '',
    },
    contentBodyColor: {
      type: [String, Array],
      required: false,
      default: backgroundColors.white,
    },
    isEmailEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPhoneEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    source: {
      type: String,
      required: true,
    },
  },
  emits: ['hide'],
  data() {
    return {
      currentTab: tabs.START,
      tabs: tabs,
      phoneNumberData: {
        nationalNumber: null,
        countryCode: null,
        countryCallingCode: null,
        fullNumber: null,
        isValid: false,
      },
      email: null,
      acceptedTermsAndConditions: false,
      consentMarketingElectronic: false,
      consentSalesPhone: false,
      validateEventTriggered: false,
      submitAtleastOnce: false,
      recordId: null,
      submitInProgress: false,
      backgroundColors,
    };
  },
  computed: {
    ...mapGetters({
      profileId: 'users/profileId',
      country: 'getCountry',
      locale: 'getLocale',
    }),
    consentHTML() {
      const privacyPolicy = `<a class="font-medium transition duration-200 ease-in-out text-rendin-500 hover:text-rendin-300 hover:underline focus:underline" href="${this.$localizedPath('/privacy')}" target="_blank">${this.$t('form.rendin_privacy')}</a>`;
      const parameters = {
        privacy_policy: privacyPolicy,
        submit_button_label: this.blok.submitButtonLabel,
        consent_company_name: this.$t('form.label.consent_company_name')[
          this.$getCountry()
        ],
      };

      if (this.isEmailEnabled && this.isPhoneEnabled) {
        return replaceWithParameters(this.$t('form.label.consent_unified'), parameters);
      } else if (!this.isPhoneEnabled && this.blok.consentDescription) {
        return replaceWithParameters(this.blok.consentDescription, parameters);
      } else {
        return replaceWithParameters(
          this.$t('form.label.consent_sales_marketing_email'),
          parameters,
        );
      }
    },
  },
  methods: {
    ...mapActions({
      reportErrorToSentry: 'tracker/reportErrorToSentry',
      trackLeadGeneratorWidgetSubmit: 'tracker/trackLeadGeneratorWidgetSubmit',
      setLeadGeneratorWidgetShownCookie: 'setLeadGeneratorWidgetShownCookie',
    }),
    submit() {
      this.submitInProgress = true;

      const posthogDistinctId = this.$posthog.get_distinct_id();

      const path = window.location.pathname;

      const fullPath = window.location.href;

      /* LEGAL STUFF - we added information in the modal, which gets multiple consents from the user */
      /* So we're enabling here all consents by default. Might change in the future */
      /* Ref: https://app.clickup.com/t/861n85f0c */
      this.acceptedTermsAndConditions = true;
      if (this.isPhoneEnabled) {
        this.consentSalesPhone = true;
      }
      if (this.isEmailEnabled) {
        this.consentMarketingElectronic = true;
      }

      const data = {
        phone: this.phoneNumberData?.fullNumber,
        email: this.email,
        page: path,
        fullPath: fullPath,
        recordId: this.recordId,
        userFirebaseKey: this.profileId,
        acceptedTermsAndConditions: this.acceptedTermsAndConditions,
        consentSalesPhone: this.consentSalesPhone,
        consentMarketingElectronic: this.consentMarketingElectronic,
        posthogDistinctId: posthogDistinctId,
      };

      return (
        // TODO - migration - verify that this works as $axios did before
        // Especially "host" and base URL
        this.$fetch('/api/leads/upsert', { method: 'POST', body: data })
          .then((data) => {
            this.recordId = data.recordId;
          })
          .then(() => {
            if (this.email || this.phoneNumberData?.fullNumber) {
              return this.$setUserProperties({
                services: ['POSTHOG', 'CIO'],
                identify: !!(this.email || this.phoneNumberData?.fullNumber),
                email: this.email,
                phoneNumberFull: this.phoneNumberData?.fullNumber,
                internalUserProperties: {
                  email: this.email,
                  phoneNumberFull: this.phoneNumberData?.fullNumber,
                  country: this.country,
                  locale: this.locale,
                  source: 'web_lead_gen',
                },
              });
            }
          })
          .then(() =>
            this.trackLeadGeneratorWidgetSubmit({
              phone: this.phoneNumberData?.fullNumber,
              email: this.email,
              source: this.source,
            }),
          )
          // After user has submitted contact details, we disable Lead Gen Modal for 28 days
          .then(() => this.setLeadGeneratorWidgetShownCookie(28))
          .then(() => (this.currentTab = tabs.SUCCESS))
          .catch((err) => {
            this.reportErrorToSentry(err);
          })
          .finally(() => {
            this.submitInProgress = false;
          })
      );
    },
    isActiveTab(tab) {
      return this.currentTab === tab;
    },
    tabClasses(tab) {
      const classes = [];

      classes.push(this.contentBodyColor);

      classes.push(this.isActiveTab(tab) ? 'active-tab' : 'inactive-tab');

      return classes;
    },

    columnBackgroundColor(color) {
      if (color === 'white') {
        return 'sm:bg-white';
      } else if (color === 'gray-light') {
        return 'sm:bg-gray-50';
      } else if (color === 'gray') {
        return 'sm:bg-gray-300';
      } else if (color === 'peach-schnapps') {
        return 'sm:bg-rendin-100';
      } else if (color === 'orange') {
        return 'sm:bg-rendin-500';
      } else if (color === 'peach-extra-light') {
        return 'sm:bg-peach-100';
      } else if (color === 'peach-light') {
        return 'sm:bg-peach-200';
      } else if (color === 'peach') {
        return 'sm:bg-peach-300';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
div.inactive-tab {
  display: none;
  @screen sm {
    display: grid;
    visibility: hidden;
    grid-area: 1 / 1 / 2 / 2;
    z-index: -999;
  }
}

div.active-tab {
  @screen sm {
    visibility: visible;
    grid-area: 1 / 1 / 2 / 2;
    z-index: 0;
  }
}
</style>
