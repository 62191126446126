<template>
  <Form ref="validator" v-slot="{ meta: formMeta }">
    <div
      class="mx-auto text-center px-4 my-6"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <component
        :is="childBlok.component"
        v-for="childBlok in introText.filter((item) =>
          ['RichTextBlok'].includes(item.component),
        )"
        :key="childBlok._uid"
        :blok="childBlok"
        class="text-1.5xl"
      />
    </div>
    <div
      class="r-fade--delay--1 max-w-sm mx-auto px-4"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      <Field
        v-slot="{ meta, setTouched }"
        v-model="rentAmountModel"
        validate-on-mount
        name="rent"
        :rules="{ required: true, isFloat: true }"
      >
        <r-input
          v-model="rentAmountModel"
          :add-on="currencyLabel"
          field-type="tel"
          :focus-on-load="true"
          inputmode="decimal"
          :label="$t('form.label.rentAmount')"
          label-class="text-xs sm:text-base"
          name="rent"
          :placeholder="$t('form.placeholder.rentAmount')"
          required
          :validation-failed="meta.touched && !meta.valid"
          :validation-passed="meta.valid"
          @input="setTouched(true)"
          @blur="setTouched(true)"
        />
      </Field>
    </div>

    <div
      class="r-fade--delay--1 text-center font-medium my-4"
      :class="{ 'r-fade-bottom': initialLoad }"
    >
      {{ rendinFeePrefix }}
      <span class="text-rendin-500">
        {{
          rendinFeeCalculation(rentAmount, country).toLocaleString(getLocale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        }}
      </span>
      {{ rendinFeeSufix }}
    </div>
    <div class="r-fade--delay--1" :class="{ 'r-fade-bottom': initialLoad }">
      <r-inline-help-box
        class="px-4 pb-4 max-w-sm mx-auto"
        icon="circle-question"
        :title="$t('modals.rendin_fee')"
        width="full"
        @click="showRendinFeeModal = !showRendinFeeModal"
      />
    </div>
    <r-overlay
      size="small"
      :visible="
        showAdditionalFeeModal || showRendinFeeModal || showInsuranceInvoiceToModal
      "
      @close="closeAllModals()"
    >
      <div class="pb-4">
        <div class="mt-8 mx-4 sm:mx-8">
          <component
            :is="childBlok.component"
            v-for="childBlok in modalContent.filter((item) =>
              ['RichTextBlok'].includes(item.component),
            )"
            :key="childBlok._uid"
            :blok="childBlok"
          />
        </div>

        <r-button
          display="block"
          :label="$t('buttons.close')"
          no-background
          uppercase
          @click="closeAllModals()"
        />
      </div>
    </r-overlay>
    <div class="r-fade--delay--2" :class="{ 'r-fade-bottom': initialLoad }">
      <div v-if="additionalFeeTitle && additionalFeeTitle.length > 0">
        <component
          :is="childBlok.component"
          v-for="childBlok in additionalFeeTitle.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="text-1.5xl text-center"
        />
      </div>
      <template v-if="enableAdditionalFees">
        <Field
          ref="additionalFeeAmountProvider"
          v-slot="{ meta, setTouched }"
          v-model="additionalFeeModel"
          name="additionalFee"
          :rules="{ isFloat: true }"
        >
          <div class="max-w-sm mx-auto px-4">
            <r-input
              v-model="additionalFeeModel"
              :add-on="currencyLabel"
              field-type="tel"
              inputmode="decimal"
              :label="$t('offer.additional_fee.type.fixed_utilities.pl')"
              label-class="text-xs sm:text-base"
              name="additionalFee"
              :placeholder="$t('service.administration_fee')"
              :validation-failed="meta.touched && !meta.valid"
              :validation-passed="meta.valid"
              @input="setTouched(true)"
              @blur="setTouched(true)"
            />
          </div>
        </Field>
        <r-inline-help-box
          class="p-4 mx-auto max-w-sm"
          icon="circle-question"
          :title="$t('modals.administrative_fee')"
          width="full"
          @click="showAdditionalFeeModal = !showAdditionalFeeModal"
        />
      </template>
      <div
        v-if="additionalFeeContent && additionalFeeContent.length > 0"
        class="max-w-3xl mx-auto text-center px-4 hidden sm:block"
      >
        <component
          :is="childBlok.component"
          v-for="childBlok in additionalFeeContent.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="my-8 text-gray-500 text-center"
        />
      </div>
    </div>
    <div class="r-fade--delay--2" :class="{ 'r-fade-bottom': initialLoad }">
      <div v-if="insuranceInvoiceToTitle?.length > 0">
        <component
          :is="childBlok.component"
          v-for="childBlok in insuranceInvoiceToTitle.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="text-1.5xl text-center"
        />
      </div>
      <div class="w-full flex justify-center max-w-sm mx-auto px-4">
        <r-radio
          v-model="insuranceInvoiceToModel"
          class="w-full"
          flavor="bordered"
          name="insuranceInvoiceTo"
          :options="insuranceInvoiceToOptions"
        />
      </div>
      <r-inline-help-box
        class="p-4 mx-auto max-w-sm"
        icon="circle-question"
        :title="$t('modals.what_does_it_mean')"
        width="full"
        @click="showInsuranceInvoiceToModal = !showInsuranceInvoiceToModal"
      />
      <div
        v-if="insuranceInvoiceToContent?.length > 0"
        class="max-w-3xl mx-auto text-center px-4 hidden sm:block"
      >
        <component
          :is="childBlok.component"
          v-for="childBlok in insuranceInvoiceToContent.filter((item) =>
            ['RichTextBlok'].includes(item.component),
          )"
          :key="childBlok._uid"
          :blok="childBlok"
          class="my-8 text-gray-500 text-center"
        />
      </div>
    </div>
    <div class="h-14 mt-2 mb-4">
      <div
        v-if="formMeta.valid"
        class="flex justify-center items-center"
        :class="{ 'r-fade': initialLoad }"
      >
        <r-button
          :id="submitButtonId"
          color="orange"
          :is-loading="requestInProgress"
          @click="submit"
        >
          <r-icon class="mr-1 text-lg" icon-name="check-circle" />
          <span class="font-medium">{{ nextButtonLabel }}</span>
        </r-button>
        <div class="flex items-center text-sm mx-2">
          <RichText v-if="goToNextStepCta" :blok="goToNextStepCta" />
          <r-icon class="ml-1" icon-name="arrow-turn-down-left" />
        </div>
      </div>
    </div>
  </Form>
</template>
<script>
import { mapGetters } from 'vuex';
import { rendinFeeCalculation } from '~/utils/rendinFeeCalculation.js';
import { userRole } from '~/utils/constants';
import { payerRoleTabsOptions } from '~/utils/objectStructures';
import { Field, Form } from 'vee-validate';

export default {
  name: 'StepSelectRentRate',
  components: { Field, Form },
  props: {
    introText: {
      type: Array,
      required: true,
    },
    initialLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    rentAmount: {
      type: Number,
      required: false,
      default: null,
    },
    goToNextStepCta: {
      type: Object,
      required: true,
    },
    submitButtonId: {
      type: String,
      required: true,
    },
    nextButtonId: {
      type: String,
      required: true,
    },
    nextButtonLabel: {
      type: String,
      required: true,
    },
    previousButtonId: {
      type: String,
      required: true,
    },
    rendinFeePrefix: {
      type: String,
      required: true,
    },
    rendinFeeSufix: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    currencyLabel: {
      type: String,
      required: true,
    },
    rendinFeeModalContents: {
      type: Array,
      required: true,
    },
    additionalFeeModalContents: {
      type: Array,
      required: true,
    },
    requestInProgress: {
      type: Boolean,
      required: true,
    },
    additionalFee: {
      type: Object,
      required: false,
      default: null,
    },
    additionalFeeTitle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    additionalFeeContent: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    multipleAdditionalFees: {
      type: Boolean,
      default: false,
    },
    enableAdditionalFees: {
      type: Boolean,
      default: false,
    },
    insuranceInvoiceTo: {
      type: String,
      default: userRole.TENANT,
    },
    insuranceInvoiceToTitle: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    insuranceInvoiceToContent: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    insuranceInvoiceToModalContents: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  emits: [
    'update:rentAmount',
    'update:additionalFee',
    'update:insuranceInvoiceTo',
    'goToNextStep',
  ],
  setup() {
    const { setupKeyListener } = useKeyboardListener();

    return {
      setupKeyListener,
      rendinFeeCalculation,
    };
  },
  data() {
    return {
      showRendinFeeModal: false,
      showAdditionalFeeModal: false,
      showInsuranceInvoiceToModal: false,
      insuranceInvoiceToOptions: payerRoleTabsOptions(
        this.$t('tenant'),
        this.$t('landlord'),
      ),
    };
  },
  computed: {
    ...mapGetters({
      getLocale: 'getLocale',
    }),

    modalContent() {
      if (this.showRendinFeeModal && this.rendinFeeModalContents) {
        return this.rendinFeeModalContents;
      } else if (this.showAdditionalFeeModal && this.additionalFeeModalContents) {
        return this.additionalFeeModalContents;
      } else if (
        this.showInsuranceInvoiceToModal &&
        this.insuranceInvoiceToModalContents
      ) {
        return this.insuranceInvoiceToModalContents;
      } else {
        return [];
      }
    },

    rentAmountModel: {
      get() {
        return this.rentAmount;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.$emit('update:rentAmount', normalized || null);
      },
    },

    additionalFeeModel: {
      get() {
        return this.additionalFee ? this.additionalFee.amount : null;
      },
      set(newVal) {
        const normalized = Number(newVal.replace(',', '.'));
        this.$emit('update:additionalFee', {
          ...this.additionalFee,
          amount: normalized || null,
        });
      },
    },

    insuranceInvoiceToModel: {
      get() {
        return this.insuranceInvoiceTo;
      },
      set(newVal) {
        this.$emit('update:insuranceInvoiceTo', newVal);
      },
    },
  },
  mounted() {
    this.setupKeyListener('Enter', this.submit);
  },
  methods: {
    async submit() {
      const isValid = await this.validate();
      if (isValid && !this.requestInProgress) {
        this.$emit('goToNextStep');
      }
    },
    async validate() {
      const validationResult = await this.$refs.validator.validate();
      return validationResult.valid;
    },
    closeAllModals() {
      this.showRendinFeeModal = false;
      this.showAdditionalFeeModal = false;
      this.showInsuranceInvoiceToModal = false;
    },
  },
};
</script>
